import React from 'react';
import axios from 'axios';
import styles from './CardCalendar.module.css';
import moment from 'moment';
import LiveIco from '../../img/acciones/live_ico.svg';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';


export default function CardCalendar({claseCalendar}) {
    const backgroundColor = claseCalendar.active ? '#2282f4' : '#5c5c5c';

    const clikUrl = (idClase,tipoClase) => {
        axios.post(process.env.REACT_APP_BACKEND_ENDPOINT+'/api/v1/felicidades/confirm_clic_class',{id_clase:idClase,tipo_clase:tipoClase},
        { headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}})
        .then(res => {

        })
        .catch(error => {
            console.error(error);
        });
    }

    return(
        <div className={styles['container-main']}>
            <div className={styles.container} style={{backgroundColor}}>
                <div className={styles.header}>
                    {claseCalendar.active ? <img src={LiveIco} alt="live" width={70}/> : <></>}
                </div>
                <div className={styles.text}>
                    {claseCalendar.nombre_clase}
                </div>
                <div className={styles.date}>
                    {claseCalendar.fecha_inicio && moment(claseCalendar.fecha_inicio,'DD/MM/YYYY HH:mm:ss').format('MMMM DD, HH')+'hrs.'}
                </div>
            </div>
            <div className={styles.footer}>
                <div>
                    <a href={claseCalendar.link_clase_en_vivo} onClick={()=>{clikUrl(claseCalendar.id_clase,'en_vivo')}} target="_blank" rel="noreferrer"><ChevronRightIcon style={{color:'#2282f4'}}/> ingresar</a>
                </div>
                <div>
                    <a href={claseCalendar.link_clase_grabada} onClick={()=>{clikUrl(claseCalendar.id_clase,'grabado')}} target="_blank" rel="noreferrer">grabación <KeyboardArrowDownIcon style={{color:'#d3d3d3'}}/></a>
                </div>
            </div>
        </div>
    );
};
