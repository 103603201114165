import React from 'react';
import style from './StepFunction.module.css';
import CardColor from '../../components/CardColor/CardColor';
import Ico1 from '../../img/stepsheaders/icono_01.svg';
import Ico2 from '../../img/stepsheaders/icono_02.svg';
import Ico3 from '../../img/stepsheaders/icono_03.svg';
import Ico4 from '../../img/stepsheaders/icono_04.svg';

export default function StepFuntion({main}) {
    return(
        <div>
            { !main ?
            <>
            <div className={style.title + ' margin-top-sm'}>
                ¿Cómo funciona el Programa online? 
            </div>
            <div className={style['container-cards'] + '  margin-top-sm'}>
                <CardColor 
                    backgroundColor={'#fbbd05'}
                    img={Ico1}
                    title={<>
                        01. <br />
                        Postúlate
                    </>}
                >
                   Aplica y completa el <br />
                   formulario si cumples con <br />
                   los requisitos de la <br />
                   convocatoria. Si fuiste <br />
                   seleccionado se te notificará <br />
                   en un plazo de 1 a 8 días. 
                </CardColor>
                <CardColor 
                    backgroundColor={'#cb4538'}
                    img={Ico2}
                    title={<>
                            02. <br />
                            Completa tu curso 
                        </>}
                >
                    Entra a las clases <br />
                    programadas y revisa <br />
                    las clases técnicas <br />
                    con los expertos de <br />
                    Google Cloud.
                </CardColor>
                <CardColor 
                    backgroundColor={'#2282f4'}
                    img={Ico3}
                    title={<>
                            03. <br />
                            Recibe tus insignias
                        </>}
                >
                    Semanalmente termina <br />
                    tus desafíos y obtén tu <br />
                    insignia que tiene <br />
                    validez curricular.
                </CardColor>
                <CardColor 
                    backgroundColor={'#35a753'}
                    img={Ico4}
                    title={<>
                            04. <br />
                            ¡Prepárate para destacar! 
                        </>}
                >
                    Obtendrás un <br />
                    diploma de <br />
                    finalización por parte <br />
                    de un directivo de <br />
                    Google.
                </CardColor>
            </div>
            </> :
            <>
            <div className={style.title + ' margin-top-sm'}>
                Tus compromisos son:
            </div>
            <div className={style['container-cards'] + '  margin-top-sm'}>
                <CardColor 
                    backgroundColor={'#fbbd05'}
                    img={Ico1}
                    title={'01.'}
                >
                    Entrar a las clases <br />
                    programadas y revisar las <br />
                    clases técnicas con los <br />
                    expertos de Google. Si <br />
                    por alguna razón no <br />
                    puedes asistir, deberás <br />
                    ver la clase grabada.
                </CardColor>
                <CardColor 
                    backgroundColor={'#cb4538'}
                    img={Ico2}
                    title={'02.'}
                >
                    Semanalmente terminar <br />
                    tus desafios y obtener la  <br />
                    insignia correspondiente.
                </CardColor>
                <CardColor 
                    backgroundColor={'#2282f4'}
                    img={Ico3}
                    title={'03.'}
                >
                    Si cumples con tus <br />
                    compromisos recibirás <br />
                    un diploma de <br />
                    finalización por <br />
                    parte de Google Cloud.
                </CardColor>
                <CardColor 
                    backgroundColor={'#35a753'}
                    img={Ico4}
                    title={'04.'}
                >
                    Contestar la encuesta <br />
                    de finalización del <br />
                    programa y la de <br />
                    seguimiento que te <br />
                    llegará 3 meses <br />
                    después.
                </CardColor>
            </div>
            </>
            }
        </div>
    );
};
