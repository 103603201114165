import React from 'react';
import style from './espera.module.css';

export default function EsperaContent() {
    return(
        <div className='container-margin'>
            <div className={style.text + ' margin-top-md'}>
                <h2 className={style.title1}>
                    Súbete a la nube con
                </h2>
                <h2 className={style.title2}>
                    Google Cloud
                </h2>
            </div>
            
            <div className={style.text + ' margin-top-md'}>
                <p>
                    Gracias por completar tu solicitud para el programa de <br />
                    Súbete a la Nube con Google Cloud.
                </p>
                <p>
                    Tu solicitud se encuentra en proceso de revisión, en el transcurso de 1 a 8 días podrás consultar el<br />
                    estatus de tu solicitud iniciando sesión en <a href="https://subetealanube.inroads.org.mx/">https://subetealanube.inroads.org.mx/</a>
                </p>
                <p className={style['text-bold']}>
                    ¡Visualiza, avanza, crece y marca la diferencia!
                </p>
                <p >
                    Tu carrera profesional depende de ti.<br />
                    INROADS de México, A.C.
                </p>
            </div>
        </div>
    );
};
