import React, { Component } from "react";
import ReorderIcon from '@material-ui/icons/Reorder';
import { Drawer, Divider, IconButton } from '@material-ui/core';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import AssignmentTurnedInSharpIcon from '@mui/icons-material/AssignmentTurnedInSharp';
import ApprovalIcon from '@mui/icons-material/Approval';
import EventIcon from '@material-ui/icons/Event';
import "./AdminMenu.css";

const styles = {
  loginButton: {
      maxWidth: 100,
      maxHeight: 500,
      marginRight: '10px'
  }
};

class ButtonAppBar extends Component {

  state = {
    isDrawerOpened: false,
    admin: false
  }

  componentDidMount = () => {
    if(sessionStorage.getItem('Admin') === "true" ){
      this.setState({ admin:true})
    }
  }

  logout = (event) => {
    sessionStorage.removeItem("isSigned");
    sessionStorage.clear()
    this.props.history.push('/')
  }

  toggleDrawerStatus = () => {
    this.setState({
      isDrawerOpened: true,
    })
  }

  closeDrawer = () => {
    this.setState({
      isDrawerOpened: false,
      })
  }

  render() {
    const { isDrawerOpened } = this.state;

    return (
      <>
        {this.state.admin  &&
        <div className="appbar-admin-menu">
          <IconButton onClick={this.toggleDrawerStatus} style={styles.loginButton}>
          {!isDrawerOpened && <ReorderIcon />}
          </IconButton>
          <Divider/>
        </div>
        }
        <Drawer
          variant="temporary"
          open={isDrawerOpened}
          onClose={this.closeDrawer}
          >
          <Link to='/asignacion' style={styles.link}>
              <List>
              <ListItem button key='Asignación'>
                  <ListItemIcon><ApprovalIcon/>
                  </ListItemIcon>
                  <ListItemText primary='Asignación' />
              </ListItem>
              </List>
          </Link>
          <Link to='/claves-pr' style={styles.link}>
            <List>
              <ListItem button key='Universidades y Claves de pre registro'>
                  <ListItemIcon><AssignmentTurnedInSharpIcon/>
                  </ListItemIcon>
                  <ListItemText primary='Claves de preregistro'/>
                  </ListItem>
              </List>
          </Link>
          <Link to='/oleadasClases' style={styles.link}>
            <List>
              <ListItem button key='Registro de oleadas y clases'>
                  <ListItemIcon><EventIcon/>
                  </ListItemIcon>
                  <ListItemText primary='Registro de oleadas y clases'/>
                  </ListItem>
              </List>
          </Link>
        </Drawer>
      </>
    )
  }
}

export default withRouter(ButtonAppBar);
