import React, { Component } from 'react';
import axios from 'axios';
import style from './DailogRegister.module.css';
import Button from '../Button/Button';
import ButtonM from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from "@material-ui/core/TextField";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { withRouter } from 'react-router';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import Typography from "@material-ui/core/Typography";
import iconoConfirmacion from '../../img/acciones/icono-conformacion.svg';
// import "./css/landing_body.css";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormHelperText from '@mui/material/FormHelperText';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';

class SignUp extends Component {
  
  state = {
      user: '',
      tel: '',
      checkUser: '',
      pass: '',
      checkPass: '',
      isSignedup: false,
      setOpen:false,
      open:false,
      openModal: false,
      alert: false,
      alertContent: '',
      validateEmail: false,
      validateTel: false,
      validatePass: false,
      uriel: false,
      openModalNested: false,
      correo: 0,
      contraseña:0,
      datosGeneral:0,
      showPassword: false,
      acepto_aviso: false,
      acepto_noticias: false,
      existencia:0,
      emailAgain:'',
      validate: {
        status_user: 0,
        message_user: "",
        status_check_user: 0,
        message_check_user: "",
        status_pass: 0,
        status_check_pass: 0,
        message_check_pass: "",
        status_tel: 0,
        message_tel: "",
        loading: false
      }

  }
  handleClickShowPassword = event => {
    this.setState({ showPassword: !this.state.showPassword })
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  }

  handleClickOpen = event => {
      event.preventDefault()
      this.setState({ setOpen:true})
  }

  handleClose = event => {
      this.setState({ open:false})
  }

  handleUser = event => {
    // eslint-disable-next-line
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(event.target.value) || event.target.value === "")
      this.setState({ user: event.target.value, validate: { ...this.state.validate, message_user: "",status_user: 0 } })
    else{
      this.setState({validate: { ...this.state.validate, message_user: "Por favor ingresa un correo valido",status_user: 1 }});
      this.setState({ user: "" })
    }
  }

  handleUserCheck = event => {
      if(event.target.value !== "" && event.target.value === this.state.user)
        this.setState({checkUser: event.target.value,
          validate: { ...this.state.validate, message_check_user: "",status_check_user: 0 } })
      else
        this.setState({validate: { ...this.state.validate, message_check_user: "El correo no coincide",status_check_user: 1 }});

  }

  handlePass = event => {

    if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(event.target.value) || event.target.value === "")
      this.setState({ pass: event.target.value, validate: { ...this.state.validate, status_pass: 0 } })
    else{
      this.setState({validate: { ...this.state.validate, status_pass: 1 }});
      this.setState({ pass: "" })
    }
  }
  
  handleTel = event => {
    if (/^[0-9]+$/.test(event.target.value) || event.target.value === "")
      if (event.target.value.length === 10)
        this.setState({ tel: event.target.value, validate: { ...this.state.validate, message_tel: "",status_tel: 0 } })
      else{
        this.setState({validate: { ...this.state.validate, message_tel: "El número de teléfonico debe tener minimo 10 digitos",status_tel: 1 }});
        this.setState({ tel: "" })  
      }
    else{
      this.setState({validate: { ...this.state.validate, message_tek: "Por favor ingresa un teléfono valido",status_tel: 1 }});
      this.setState({ tel: "" })
    }
  }
  
  handlePassCheck = event => {
    if(event.target.value !== "" && event.target.value === this.state.pass)
        this.setState({checkPass: event.target.value,
          validate: { ...this.state.validate, message_pass_user: "",status_check_pass: 0 } })
      else
        this.setState({validate: { ...this.state.validate, message_check_pass: "La contraseña no coincide",status_check_pass: 1 }});
  }

  handleAlert = event => {
      this.setState({ alert: true })
      this.setState({ alertContent: 'Registro exitoso'})
  }

  handleCheck = event => {
    this.setState({ acepto_aviso: !this.state.acepto_aviso })
  }
  
  handleNoticiasCheck = event => {
    this.setState({acepto_noticias: !this.state.acepto_noticias })
  }

  login = event => {
      
      var val_temp_email = false;
      var val_temp_pass = false;
      var val_temp_tel = false;
      var validations = this.state.validate;
      
      //Validar no nulo
      if (this.state.user === ""){
        validations.message_user = "Favor de colocar un correo";
        validations.status_user = 1;
      }

      if (this.state.checkUser === ""){
        validations.status_check_user = 1;
      }

      if (this.state.pass === ""){
        validations.status_pass = 1;
      }

      if (this.state.checkPass === ""){
        validations.status_check_pass = 1;
      }

      if (this.state.tel === ""){
        validations.message_tel = "Favor de colocar un teléfono";
        validations.status_tel = 1;
      }
      
      this.setState({validate: validations,acepto_aviso: this.state.acepto_aviso})

      // Revisar que no exista un mensaje de error
      if(  validations.status_user === 1 
        || validations.status_check_user === 1
        || validations.status_pass === 1
        || validations.status_check_pass === 1
        || validations.status_tel === 1){
          return false;
      }else{
        val_temp_email = true;
        val_temp_pass = true;
        val_temp_tel = true;
      }
      
      const headersPost = {
        'content-type': 'text/json'
      }

      const user = {
          email: this.state.user,
          password: this.state.pass,
          acepto_noticias: this.state.acepto_noticias,
          telefono_celular: this.state.tel,
          //signedup: this.state.isSignedup,
      };

      if (val_temp_email && val_temp_pass && val_temp_tel){
        const data = user
        const headers_post = JSON.stringify(headersPost)
        this.setState({loading : true});
        axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/login/register/', data, headers_post)
        .then(res => {
        if (res.request.status === 200){
          //sessionStorage.setItem('isSigned', true)
          //sessionStorage.setItem('email',this.state.user)
          //this.setState({ isSignedup: true })

          if(res.data.error){
            if(res.data.message.includes("ya registrado")){
              validations.message_user = "Correo ya registrado";
              validations.status_user = 1;
              this.setState({validate: validations,acepto_aviso: this.state.acepto_aviso})
            }
          }else{
            this.setState({ openModalNested: true})
          }

        } else {
          this.setState({ isSignedup: false })
        }
        }).finally(()=>this.setState({loading : false}));
      }else{
        this.setState({ datosGeneral: 1})
      }
  }

  launchModal = e => {
      e.preventDefault()
      this.setState({ openModal: true})
  }

  launchModalNested = e => {
    e.preventDefault()
    this.setState({ openModalNested: true})
}

  closeModal = e => {
      this.setState({ 
        openModal: false,
        acepto_aviso: false,
        validate: {
          status_user: 0,
          message_user: "",
          status_check_user: 0,
          message_check_user: "",
          status_pass: 0,
          status_check_pass: 0,
          message_check_pass: "",
        }
      })
  }

  closeModalNested = e => {
    this.setState({ 
      openModal: false,
      openModalNested: false,
      acepto_aviso: false,
      acepto_noticias: false,
      validate: {
        status_user: 0,
        message_user: "",
        status_check_user: 0,
        message_check_user: "",
        status_pass: 0,
        status_check_pass: 0,
        message_check_pass: "",
      }
    })
}

  sendEmailValidation = event => {
    const user = {
        email:this.state.emailAgain
    }

    const headersPost = {
        'content-type': 'text/json'
    }

    const headers_post = JSON.stringify(headersPost)

    const data = user

    axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/login/send_again_email/', data, headers_post)
    .then(res => {
        this.setState({ status_envio: res.request.status});
        this.setState({ status_envio_msg: res.data.message});
      }).catch(res =>{
        this.setState({ status_envio_msg: res.response.data.message || res.response.data.error});
        this.setState({status_envio: res.request.status})
      })
  }

  launchModalEmail = e => {
    e.preventDefault()
    this.setState({ openModalEmail: true,
                    status_envio_msg: '',
                    status_envio: 0})
  }

  handleEmailAgain = event => {
    this.setState({ emailAgain: event.target.value })
  }

  closeModalEmail = e => {
    this.setState({ openModalEmail: false})
  }

  render(){
      return (
          <>

          {
          this.props.liga !== "true" ?
          <Button 
            backGroundColor={'#2282f4'}
            onClick={this.launchModal}
          >
            {this.props.textBoton?
              <span>{this.props.textBoton}</span>: <span>Aplica aquí</span>
            }
          </Button>
          :
          <a href="/" className={style['link']} onClick={this.launchModal}>Aplica aquí</a>
          }

          <Modal 
          open={this.state.openModal}
          onClose={this.closeModal} 
          id="modal-registro"
          >
            <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 1},
              display: 'flex', flexWrap: 'wrap',
            }}
            noValidate
            autoComplete="off"
            style={{width: "85%", margin:"auto"}}
            >
        
              <DialogContent className={style['font-landing']}>
                  <DialogContentText style={{fontSize:'25px'}}>
                    Aplica aquí y comienza tu camino hacia el éxito.
                  </DialogContentText>
                  <br></br>

                  <TextField 
                  helperText={this.state.validate.message_user}
                  error={this.state.validate.status_user === 1}
                  margin="dense" 
                  id="correo" 
                  label="Correo electrónico" 
                  type="email"
                  fullWidth variant="standard"
                  onChange={this.handleUser}/>
                  <p class="MuiFormHelperText-root MuiFormHelperText-sizeMedium css-1d1r5q-MuiFormHelperText-root">
                    Pon el correo que más utilices, este será el que deberás utilizar durante todo el programa
                  </p>
                  
                  <TextField
                  helperText={this.state.validate.message_check_user}
                  error={this.state.validate.status_check_user === 1}
                  margin="dense" 
                  id="checkcorreo" 
                  label="Verifica tu correo" 
                  type="email"
                  fullWidth variant="standard"
                  onChange={this.handleUserCheck}/>
                  
                  <FormControl variant="standard" style={{width: "100%"}} >
                  <InputLabel htmlFor="standard-adornment-password"
                    error={this.state.validate.status_pass === 1}
                  >Contraseña</InputLabel>
                  <Input
                    error={this.state.validate.status_pass === 1}
                    margin="dense"
                    id="contraseña"
                    label="Contraseña"
                    type={this.state.showPassword ? 'text' : 'password'}
                    fullWidth variant="standard" 
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword}
                        >
                          {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    onChange={this.handlePass}
                  />
                    <FormHelperText id="contraseña">
                      Longitud mínima de 8, al menos:<br/>
                      Una mayúscula<br/>
                      Una minúscula<br/>
                      Un dígito<br/>
                      Un símbolo
                    </FormHelperText>
                  </FormControl>
                  
                  <TextField
                  helperText={this.state.validate.message_check_pass}
                  error={this.state.validate.status_check_pass === 1}
                  margin="dense"
                  id="checkcontraseña" 
                  label="Verifica tu contraseña" 
                  type="password" 
                  fullWidth variant="standard" 
                  onChange={this.handlePassCheck}/>
                  
                  <TextField 
                          helperText={this.state.validate.message_tel}
                          error={this.state.validate.status_tel === 1}
                          margin="dense" 
                          id="telefono_celular" 
                          label="Teléfono celular" 
                          type="tel"
                          fullWidth variant="standard"
                          onChange={this.handleTel}
                  />
                    
                  <FormGroup>
                    <br></br>
                    
                    <FormControlLabel control={<Checkbox onChange={this.handleCheck} />}
                      label="Acepto el aviso de privacidad y que sean enviadas notificaciones al correo electrónico que he proporcionado."
                    />
                    <a href="https://inroads.org.mx/aviso-de-privacidad/" className={style['link']}
                    style={{margin:"auto"}}>Ver aviso de privacidad</a> 
                    <br></br>
                    <FormControlLabel control={<Checkbox onChange={this.handleNoticiasCheck} />}
                      label="Quiero recibir noticias de INROADS."
                    />

                    
                    <br></br><br></br>


                    {this.state.acepto_aviso ? 

                      <LoadingButton
                        className={style['button_ovalado' ]}
                        style={{width:'40%', textTransform: 'None',margin: "auto"}}
                        onClick={this.login}
                        loading={this.state.loading}
                        loadingPosition="end"
                        variant="contained">
                            Aplica aquí
                      </LoadingButton>
                      : null }

                    <br />
                  </FormGroup>
                  
                  <Modal
                    open={this.state.openModalNested}
                    onClose={this.closeModalNested}
                    >

                      <Stack spacing={5} alignItems="center" style={{marginTop: "5%"}} id="modal-confirmacion" >
                        <br></br><br></br>
                      <Typography variant="h6" color="text.secondary" style={{marginTop: "2%"}}> Confirma tu correo </Typography>
                      <Typography variant="h7" color="text.secondary" style={{marginTop: "2%"}}> Te enviamos un correo para validar tu cuenta </Typography>
                        <Avatar sx={{ minWidth: 100 , minHeight: 100 }} variant="square" style={{marginTop: "10%"}} src={iconoConfirmacion}>
                          Confirmado
                        </Avatar>
                          <Typography variant="h7" color="text.secondary" style={{textAlign:"center"}}>
                            ¿No lo has recibido? <br />
                            <a href="#"  onClick={this.launchModalEmail}>Reenviar correo</a>
                          </Typography>
                          <Typography variant="h7" color="text.secondary">
                            <i>Te recomendamos revisar tu bandeja <br /> de spam y promociones.</i>
                          </Typography>
                          <br></br>
                      </Stack>
                      <Modal open={this.state.openModalEmail} onClose={this.closeModalEmail}>
                          <DialogContent>
                              <TextField 
                              autoFocus 
                              margin="dense" 
                              id="usuario" 
                              label="Correo" 
                              type="email" 
                              fullWidth variant="standard" 
                              onChange={this.handleEmailAgain}/>
                              <FormGroup>
                              
                              { this.state.status_envio === 200 && this.state.status_envio_msg === 'send email successfully' ? <Alert severity="info">Se envio tu correo de validacion exitosamente</Alert> : null}
                              { this.state.status_envio >= 400 && this.state.status_envio_msg === 'user are confirmed' ? <Alert severity="error">El usuario ya ha sido confirmado</Alert> : null}
                              { this.state.status_envio >= 400 && this.state.status_envio_msg === 'user not found' ? <Alert severity="error">El correo no se ha encontrado</Alert> : null}
                              { this.state.status_envio >= 400 && this.state.status_envio_msg.indexOf("does not match") > 0 ? <Alert severity="error">Formato de correo incorrecto</Alert> : null}
                              { this.state.status_envio >= 500 ? <Alert severity="error">Favor de intentar más tarde</Alert> : null}
                              <ButtonM 
                              className={style['button_ovalado']}
                              style={{width:'60%', textTransform: 'None',margin: "auto",marginTop: "15%"}}
                              variant="contained" onClick={this.sendEmailValidation}>Enviar</ButtonM>
                              </FormGroup>
                          </DialogContent>
                      </Modal>
                  </Modal>
              </DialogContent>
              </Box>
          </Modal>   
          </>
      )
  }
}

export default withRouter(SignUp);