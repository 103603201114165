import React from 'react';
import styles from './ProgressCalendar.module.css';
import Arrow from '../../img/arrows/flecha.svg';
import CardCalendar from '../../components/CardCalendar/CardCalendar';

export default function ProgressCalendar({listCalendar}) {
    return(
        <div className={styles.container + ' container-margin'}>
            <div className={styles.title}>
                <p><img src={Arrow} alt="arrow" width={30}/></p>
                <p>
                    Calendario de clases en vivo online
                </p>
            </div>
            <div className={styles['container-cards']}>
                {listCalendar&& listCalendar.map(classCalendar => (
                    <CardCalendar key={classCalendar.classCalendar} claseCalendar={classCalendar}/>
                ))}
            </div>
        </div>
    );
}
