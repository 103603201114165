import React,{useState, useEffect}from 'react';
import styles from './SeccionRegClass.module.css'
import axios from 'axios';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

export default function SeccionRegClass() {

     // Para control de mensajes de exito y error
     const [mensaje, setMensaje] = useState({
        mostrarMensaje: false,
        textoMensaje:"Se actualizo de manera correcta",
        tipoMensaje:"success"
    });
    // Fin control de mensajes

    const [refrescar, setRefrescar] = useState(true);

    const [listaClases, setListaClases] = useState([]);

    const [clasSelect, setClasSelect] = useState({
        id_clase: null,
        nombre_clase: '',
        tipo_clase: ''
    });

    const [formData, setFormData] = useState({
        nombre_clase:"",
        tipo_clase:"",
    });


    const regexTexto = /^[0-9a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-_]+$/u;

    const mensajeRequerido = "Campo requerido";
    const mensajeDatoCadena = "La cadena no debe de tener carácteres especiales";

    const metaDataForm = {
        nombre_clase:{required:true,regex:regexTexto,messageRequired:mensajeRequerido,messageRegex:mensajeDatoCadena},
        tipo_clase:{required:true, messageRequired:mensajeRequerido}
    };

    const [mensajesForm, setMensajesForm] = useState({
        nombre_clase:"",
        tipo_clase:"",
    });

    useEffect(() => {
        limpiarDatos();
        axios.get(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/admin/clases_read/',
        { headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}})
        .then((response) => {
            setListaClases(response.data.clases);
        })
        .catch((error) => {
            console.error(error);
        });
    }, [refrescar]);

    const seleccionaClase = (clase) => {
        limpiarDatos();

        if(clase) {
            setFormData({
                nombre_clase:clase.nombre_clase,
                tipo_clase:clase.tipo_clase
            });

            setClasSelect(clase);
        }
    }

    const limpiarDatos = () => {

        setFormData({
            nombre_clase:"",
            tipo_clase:"",
        });

        setClasSelect({
            id_clase: null,
            nombre_clase: '',
            tipo_clase: ''
        });
    }

    const handleChangue = (value,nombreParametro) => {

        const valueAux = metaDataForm[nombreParametro].upperCase ? value.toUpperCase():value;

        validaDato(valueAux,nombreParametro);

        setFormData({...formData, [nombreParametro]:valueAux});

    }

    const validaDato = (value,nombreParametro) => {
        //Se valida si es requerido
        if(metaDataForm[nombreParametro].required && !value) {
            setMensajesForm({...mensajesForm, [nombreParametro]:metaDataForm[nombreParametro].messageRequired});
            return false;
        }

        //Se valida si tiene una expresion regular
        if(metaDataForm[nombreParametro].regex &&!metaDataForm[nombreParametro].regex.test(value)&&value) {
            setMensajesForm({...mensajesForm, [nombreParametro]:metaDataForm[nombreParametro].messageRegex});
            return false;
        }

        //Se valida si tiene un minimo de caracteres
        if(metaDataForm[nombreParametro].minLength &&metaDataForm[nombreParametro].minLength > value.length) {
            setMensajesForm({...mensajesForm, [nombreParametro]:metaDataForm[nombreParametro].messageMinLength});
            return false;
        }

        //Se limpia mensaje
        setMensajesForm({...mensajesForm, [nombreParametro]:""});
        return true;
    };

    const validaForm = () => {
        for (const nombreCampo in formData) {
            if(!validaDato(formData[nombreCampo],nombreCampo)) {
                console.log(mensajesForm);
                return false;
            }
        }
        return true;
    }
    
    const guardarInformacion = () => {

        if(validaForm()) {
            const claseFormData = new FormData();

            if(clasSelect&&clasSelect.id_clase) {
                claseFormData.append('id_clase',clasSelect.id_clase);
            }
            
            claseFormData.append('nombre_clase',formData.nombre_clase);
            claseFormData.append('tipo_clase',formData.tipo_clase);
            
            axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/admin/clases_write/',claseFormData,
            { headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}})
            .then((response) => {
                if(response.data.estatus ===1) {
                    setRefrescar(!refrescar);
                    setMensaje({
                        mostrarMensaje:true,
                        textoMensaje:"Se guardo correctamente la información.",
                        tipoMensaje:"success"
                    });
                } else {
                    setMensaje({
                        mostrarMensaje:true,
                        textoMensaje:"Se produjo un error al guardar la información: "+ response.data.mensaje,
                        tipoMensaje:"error"
                    });
                }
            })
            .catch((error) => {
                setMensaje({
                    mostrarMensaje:true,
                    textoMensaje:"Se produjo un error al guardar la información, favor de volver a intentar",
                    tipoMensaje:"error"
                });
            });
        }
     }

    return(
        <>
            <h2>Registrar Clase</h2> <br />
            <Grid container rowSpacing={4} columnSpacing={8}>
                <Grid item xs={12} md={12}>
                    <span className='nota_asterisco'>*</span><span className='nota'> Selecciona la clase:</span>
                    <br />
                    <Select
                        className='select_admin'
                        id="idClase"
                        onChange={(event)=>{seleccionaClase(event.target.value)}}
                        value={clasSelect}
                    >
                        <MenuItem key={0} value={null}>Seleccionar</MenuItem>
                        {
                            listaClases.map(clase => (
                                <MenuItem key={clase.id_clase} value={clase}>{clase.nombre_clase}</MenuItem>
                            ))
                        }
                    </Select>
                </Grid>
                <Grid item lg={6} md={6} xs={6} sm={12}>
                    <TextField 
                            margin="dense"
                            id="nombre_clase"
                            label="Nombre clase"
                            value={formData.nombre_clase}
                            variant="standard"
                            onChange={(event)=>{handleChangue(event.target.value,'nombre_clase');}}
                            error={mensajesForm.nombre_clase}
                            helperText={mensajesForm.nombre_clase}
                            type="text"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                    />
                </Grid>
                <Grid item lg={6} md={6} xs={6} sm={12}>
                    <label className='select_label'>Tipo de clase</label>
                    <Select
                        className={styles['select_full']}
                        id="tipoClase"
                        label="Tipo de clase"
                        onChange={(event)=>{handleChangue(event.target.value,'tipo_clase');}}
                        value={formData.tipo_clase}
                    >
                        <MenuItem key={1} value={'clase_tecnica'}>Clase técnica</MenuItem>
                        <MenuItem key={2} value={'clase_en_vivo'}>Clase en vivo</MenuItem>
                    </Select>
                    { mensajesForm.tipo_clase&&
                        <p className={styles['nota_mensaje_error']}>{mensajesForm.tipo_clase}</p>
                    }
                </Grid>
            </Grid>
            <div className='form-button form-button-background-azul'>
                <Button variant="contained" 
                size="small" 
                onClick={guardarInformacion}
                >Registrar</Button>
            </div>
            <Snackbar
                open={mensaje.mostrarMensaje}
                autoHideDuration={3000}
                onClose={()=>setMensaje({...mensaje,mostrarMensaje:false})}
            >
                <MuiAlert elevation={6} variant="filled" severity={mensaje.tipoMensaje}> {mensaje.textoMensaje} </MuiAlert>
            </Snackbar>
        </>
    );
};
