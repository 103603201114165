import React from 'react';
import styles from './ProgressClass.module.css';
import CardClass from '../../components/CardClass/CardClass';

export default function ProgressClass({listClassTec}) {
    return(
        <div className={styles.container + ' container-margin'}>
            <div className={styles.title}>
                <p>
                    Clases técnicas con los expertos de Google Cloud
                </p>
            </div>
            <div className={styles['container-cards']}>
                {
                   listClassTec&&listClassTec.map(classTec => 
                    (<CardClass key={classTec.id_clase} classTec={classTec} />))
                }
            </div>
        </div>
    );
};
