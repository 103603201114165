import React,{useState, useEffect} from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


export default function SeccionCalificarForm() {

    const [oleada, setOleada] = useState(0);

    const [listOleada, setListOleadas] = useState([
        {
            id_oleadas:1,
            nombre_oleada:"Oleada dummy"
        }
    ]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/admin/oleadas_read/',
        { headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}})
        .then((response) => {
            setListOleadas(response.data.oleadas);
        })
        .catch((error) => {
            console.error(error);
        });
    }, []);

    // Para control de mensajes de exito y error
    const [mensaje, setMensaje] = useState({
        mostrarMensaje: false,
        textoMensaje:"Se actualizo de manera correcta",
        tipoMensaje:"success"
    });

    const calificarForm = () => {
        axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/admin/calificar/',{id_oleada: oleada},
        {
            headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}
        })
        .then(res => {
            setMensaje({
                mostrarMensaje:true,
                tipoMensaje:"success",
                textoMensaje:"Calficación exitosa"
            });
        })
        .catch(error => {
            setMensaje({
                mostrarMensaje:true,
                tipoMensaje:"error",
                textoMensaje:"Error al calificar"
            });
            console.error(error)
        });
    }

    return(
        <>
            <h2>Ejecutar calificaciones de formularios</h2>
            <br />
            <Grid container rowSpacing={4} columnSpacing={8}>
                <Grid item xs={12} md={12}>
                    <span className='nota_asterisco'>*</span><span className='nota'> Selecciona la ola:</span>
                    <br />
                    <Select
                        className='select_admin'
                        id="idOleada"
                        onChange={(event)=>{setOleada(event.target.value)}}
                        value={oleada}
                    >
                        {
                            listOleada && 
                            listOleada.map( oleada => (
                                <MenuItem key={oleada.id_oleada} value={oleada.id_oleada}>{oleada.id_oleada} ( {oleada.nombre} )</MenuItem>
                            ))
                        }
                    </Select>
                </Grid>
            </Grid>
            <div className='form-button form-button-background-azul'>
                <Button variant="contained" 
                disabled={!oleada}
                size="small" 
                onClick={calificarForm}
                >Calificar</Button>
            </div>
            <Snackbar
                open={mensaje.mostrarMensaje}
                autoHideDuration={3000}
                onClose={()=>setMensaje({...mensaje,mostrarMensaje:false})}
            >
                <MuiAlert elevation={6} variant="filled" severity={mensaje.tipoMensaje}> {mensaje.textoMensaje} </MuiAlert>
            </Snackbar>
        </>
    )    
};
