import React, {useState} from 'react';
import styles from './ProgressBar.module.css';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 25,
        borderRadius: 10,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 1,
        backgroundColor: '#fbb602',
    },
}))(LinearProgress);

export default function ProgressBar({progress}) {
    return(
        <div className={styles.container}>
            <div className={styles.title}>
                Progreso <br />
                del programa:
            </div>
            <div className={styles['container-progress_bar']}>
                <BorderLinearProgress variant="determinate" value={progress} />
            </div> 
            <div className={styles['porcent-text']}>
                <div className={styles['porcent-text-move']} style={{width:progress+'%'}}>
                    {progress}%
                </div>
            </div>
        </div>
    );
};
