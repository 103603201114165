import React,{useState,useEffect} from 'react';
import styles from './SeccionRelOleadasClass.module.css';
import axios from 'axios';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment/moment';
import { click } from '@testing-library/user-event/dist/click';

export default function SeccionRelOleadasClass() {

    // Para control de mensajes de exito y error
     const [mensaje, setMensaje] = useState({
        mostrarMensaje: false,
        textoMensaje:"Se actualizo de manera correcta",
        tipoMensaje:"success"
    });
    // Fin control de mensajes

    const [refrescar, setRefrescar] = useState(true);

    const [listaRelOleadaClase, setListaRelOleadaClase] = useState([]);

    const [relOleadaClaseSelect, setRelOleadaClaseSelect] = useState(1);

    const [listaClases, setListaClases] = useState([]);

    const [listaOleada, setListaOleada] = useState([]);

    const [formData, setFormData] = useState({
        id_oleada:null,
        id_clase:null,
        fecha_inicio:"",
        fecha_fin:"",
        link_clase_en_vivo:"",
        link_clase_grabada:""
    });

    const regexURL= /^((?:https?|ftp):\/\/[^\s/$.?#].[^\s]*)$/;
    const regexTexto = /^[0-9a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-_]+$/u;

    const mensajeRequerido = "Campo requerido";
    const mensajeUrlNoValida = "URL no valida";

    const metaDataForm = {
        id_oleada:{required:true,messageRequired:mensajeRequerido},
        id_clase:{required:true,messageRequired:mensajeRequerido},
        fecha_inicio:{required:true, messageRequired:mensajeRequerido},
        fecha_fin:{required:true, messageRequired:mensajeRequerido},
        link_clase_en_vivo:{required:false,regex:regexTexto,messageRegex:mensajeUrlNoValida},
        link_clase_grabada:{required:false,regex:regexTexto,messageRegex:mensajeUrlNoValida}
    };

    const [mensajesForm, setMensajesForm] = useState({
        id_oleada:"",
        id_clase:"",
        fecha_inicio:"",
        fecha_fin:"",
        link_clase_en_vivo:"",
        link_clase_grabada:""
    });

    useEffect(() => {
        limpiarDatos();

        //Se obtiene la lista da las relaciones
        axios.get(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/admin/oleadas_tienen_clases_registradas_read/',
        { headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}})
        .then((response) => {
            const listAux = response.data.lista_de_relaciones&& response.data.lista_de_relaciones.map((listRel,index) => ({idKey:index+1,...listRel}))
           console.log(listAux);
            setListaRelOleadaClase(listAux);
        })
        .catch((error) => {
            console.error(error);
        });

        //Se obtiene la lista de clases
        axios.get(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/admin/clases_read/',
        { headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}})
        .then((response) => {
            setListaClases(response.data.clases);
        })
        .catch((error) => {
            console.error(error);
        });

        //Se obtiene lista de oleadas
        axios.get(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/admin/oleadas_read/',
        { headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}})
        .then((response) => {
            setListaOleada(response.data.oleadas);
        })
        .catch((error) => {
            console.error(error);
        });
    }, [refrescar]);

    const seleccionaRelOleClas = (idRelOleClass) => {
        limpiarDatos();
        setRelOleadaClaseSelect(idRelOleClass);

        if(idRelOleClass) {
            const relOleClass = listaRelOleadaClase.find(rel => rel.idKey === idRelOleClass);
            console.log(relOleClass);
            const relOleClassFormData = new FormData();
            relOleClassFormData.append('id_clase',relOleClass.id_clase);
            relOleClassFormData.append('id_oleada',relOleClass.id_oleada);

            axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/admin/oleadas_tienen_clases_read/',relOleClassFormData,
                { headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}})
                .then((response) => {
                    console.log(relOleClass);
                    setFormData({
                        id_oleada:response.data.id_oleada,
                        id_clase:response.data.id_clase,
                        fecha_inicio:moment(response.data.fecha_inicio, "DD-MM-yyyy hh:mm:ss").format("yyyy-MM-DDTHH:mm"),
                        fecha_fin:moment(response.data.fecha_fin, "DD-MM-yyyy hh:mm:ss").format("yyyy-MM-DDTHH:mm"),
                        link_clase_en_vivo:response.data.link_clase_en_vivo,
                        link_clase_grabada:response.data.link_clase_grabada
                    });
                })
                .catch((error) => {
                    setMensaje({
                        mostrarMensaje:true,
                        textoMensaje:"Se produjo un error al intentar obtener la información, favor de intentar nuevamente.",
                        tipoMensaje:"error"
                    });
                });
            }
    }

    const handleChangue = (value,nombreParametro) => {

        const prueba = moment(value, "yyyy-MM-DD hh:mm").format("yyyy-MM-DD hh:mm");
        const valueAux = metaDataForm[nombreParametro].upperCase ? value.toUpperCase():value;

        validaDato(valueAux,nombreParametro);

        setFormData({...formData, [nombreParametro]:valueAux});

    }

    const validaDato = (value,nombreParametro) => {
        //Se valida si es requerido
        if(metaDataForm[nombreParametro].required && !value) {
            setMensajesForm({...mensajesForm, [nombreParametro]:metaDataForm[nombreParametro].messageRequired});
            return false;
        }

        //Se valida si tiene una expresion regular
        if(metaDataForm[nombreParametro].regex &&!metaDataForm[nombreParametro].regex.test(value)&&value) {
            setMensajesForm({...mensajesForm, [nombreParametro]:metaDataForm[nombreParametro].messageRegex});
            return false;
        }

        //Se valida si tiene un minimo de caracteres
        if(metaDataForm[nombreParametro].minLength &&metaDataForm[nombreParametro].minLength > value.length) {
            setMensajesForm({...mensajesForm, [nombreParametro]:metaDataForm[nombreParametro].messageMinLength});
            return false;
        }

        //Se limpia mensaje
        setMensajesForm({...mensajesForm, [nombreParametro]:""});
        return true;
    };

    const limpiarDatos = () => {

        setFormData({
            id_oleada:null,
            id_clase:null,
            fecha_inicio:"",
            fecha_fin:"",
            link_clase_en_vivo:"",
            link_clase_grabada:""
        });

        setMensajesForm({
            id_oleada:"",
            id_clase:"",
            fecha_inicio:"",
            fecha_fin:"",
            link_clase_en_vivo:"",
            link_clase_grabada:""
        });

        setRelOleadaClaseSelect(null);
    }

    const validaForm = () => {
        for (const nombreCampo in formData) {
            if(!validaDato(formData[nombreCampo],nombreCampo)) {
                return false;
            }
        }
        return true;
    }

    const guardarInformacion = () => {

        if(validaForm()) {
            const relOleClassFormData = new FormData();

            relOleClassFormData.append('id_clase',formData.id_clase);
            relOleClassFormData.append('id_oleada',formData.id_oleada);
            relOleClassFormData.append('fecha_inicio', moment(formData.fecha_inicio,"yyyy-MM-DD HH:mm:ss").format("yyyy-MM-DD HH:mm:ss"));
            relOleClassFormData.append('fecha_fin', moment(formData.fecha_fin,"yyyy-MM-DD HH:mm:ss").format("yyyy-MM-DD HH:mm:ss"));
            relOleClassFormData.append('link_clase_en_vivo',formData.link_clase_en_vivo);
            relOleClassFormData.append('link_clase_grabada',formData.link_clase_grabada);

            axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/admin/oleadas_tienen_clases_write/',relOleClassFormData,
            { headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}})
            .then((response) => {
                if(response.data.estatus ===1) {
                    setRefrescar(!refrescar);
                    setMensaje({
                        mostrarMensaje:true,
                        textoMensaje:"Se guardo correctamente la información.",
                        tipoMensaje:"success"
                    });
                } else {
                    setMensaje({
                        mostrarMensaje:true,
                        textoMensaje:"Se produjo un error al guardar la información: "+ response.data.mensaje,
                        tipoMensaje:"error"
                    });
                }
            })
            .catch((error) => {
                setMensaje({
                    mostrarMensaje:true,
                    textoMensaje:"Se produjo un error al guardar la información, favor de volver a intentar",
                    tipoMensaje:"error"
                });
            });
        }
     }
    
    const limpiarClase = (idOleada) => {
        setFormData({
            ...formData,
            id_oleada:idOleada,
            id_clase:null,
            fecha_inicio:"",
            fecha_fin:"",
            link_clase_en_vivo:"",
            link_clase_grabada:""
        });

        setRelOleadaClaseSelect(0);
    }

    const obtenerInfoRelOleadaClase = (idClase) => {
        const relOleClassFormData = new FormData();
        console.log(formData.id_oleada, idClase);
        relOleClassFormData.append('id_clase',idClase);
        relOleClassFormData.append('id_oleada',formData.id_oleada);

        axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/admin/oleadas_tienen_clases_read/',relOleClassFormData,
            { headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}})
            .then((response) => {
                console.log(response);
                if(Object.keys(response.data).length > 0) {
                    setRelOleadaClaseSelect({
                        ...response.data
                    });

                    setFormData({
                        ...formData,
                        id_clase:idClase,
                        fecha_inicio:moment(response.data.fecha_inicio, "DD-MM-yyyy hh:mm:ss").format("yyyy-MM-DDTHH:mm"),
                        fecha_fin:moment(response.data.fecha_fin, "DD-MM-yyyy hh:mm:ss").format("yyyy-MM-DDTHH:mm"),
                        link_clase_en_vivo:response.data.link_clase_en_vivo,
                        link_clase_grabada:response.data.link_clase_grabada
                    });
                }
            })
            .catch((error) => {
                setMensaje({
                    mostrarMensaje:true,
                    textoMensaje:"Se produjo un error al intentar obtener la información, favor de intentar nuevamente.",
                    tipoMensaje:"error"
                });
            });
    }

    return(
        <>
            <h2>Registrar relación oleada - clase</h2> <br />
            <Grid container rowSpacing={4} columnSpacing={8}>
                <Grid item xs={12} md={12}>
                    <span className='nota_asterisco'>*</span><span className='nota'> Selecciona relación oleada-clase:</span>
                    <br />
                    <Select
                        className='select_admin'
                        id="idRelOleadaClase"
                        onChange={(event)=>{seleccionaRelOleClas(event.target.value)}}
                        value={relOleadaClaseSelect}
                    >
                        <MenuItem key={0} value={0}>Seleccionar</MenuItem>
                        {
                            listaRelOleadaClase.map(relOleClas => (
                                <MenuItem key={relOleClas.idKey} value={relOleClas.idKey}>{relOleClas.relacion}</MenuItem>
                            ))
                        }
                    </Select>
                </Grid>
                <Grid item lg={6} md={6} xs={6} sm={12} justifyContent="center">
                    <span className='nota'> Selecciona oleada:</span>
                    <Select
                        label="Selecciona oleada:"
                        id="idOleada"
                        fullWidth
                        onChange={(event)=>{limpiarDatos(); handleChangue(event.target.value,'id_oleada'); limpiarClase(event.target.value);}}
                        value={formData.id_oleada}
                    >
                        {   
                            listaOleada.map(oleada => (
                                <MenuItem key={oleada.id_oleada} value={oleada.id_oleada}>{oleada.nombre}</MenuItem>
                            ))
                        }
                    </Select>
                    { mensajesForm.id_oleada&&
                        <p className={styles['nota_mensaje_error']}>{mensajesForm.id_oleada}</p>
                    }
                </Grid>
                <Grid item lg={6} md={6} xs={6} sm={12} justifyContent="center">
                    <span className='nota'> Selecciona clase:</span>
                    <Select
                        id="idClase"
                        fullWidth
                        disabled ={!formData.id_oleada}
                        onChange={(event)=>{handleChangue(event.target.value,'id_clase'); obtenerInfoRelOleadaClase(event.target.value);}}
                        value={formData.id_clase}
                    >
                        {
                            listaClases.map(clase => (
                                <MenuItem key={clase.id_clase} value={clase.id_clase}>{clase.nombre_clase}</MenuItem>
                            ))
                        }
                    </Select>
                    { mensajesForm.id_clase&&
                        <p className={styles['nota_mensaje_error']}>{mensajesForm.id_clase}</p>
                    }
                </Grid>
                <Grid item lg={6} md={6} xs={6} sm={12} justifyContent="center">
                    <TextField 
                            margin="dense"
                            variant="standard"
                            id="fecha_inicio"
                            label="Fecha inicio"
                            value={formData.fecha_inicio}
                            onChange={(event)=>{handleChangue(event.target.value,'fecha_inicio');}}
                            error={mensajesForm.fecha_inicio}
                            helperText={mensajesForm.fecha_inicio}
                            type="datetime-local"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                                
                            }}
                        />
                </Grid>
                <Grid item lg={6} md={6} xs={6} sm={12} justifyContent="center">
                    <TextField 
                            margin="dense"
                            variant="standard"
                            id="fecha_fin"
                            label="Fecha fin"
                            value={formData.fecha_fin}
                            onChange={(event)=>{handleChangue(event.target.value,'fecha_fin');}}
                            error={mensajesForm.fecha_fin}
                            helperText={mensajesForm.fecha_fin}
                            type="datetime-local"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                </Grid>
                <Grid item lg={6} md={6} xs={6} sm={12} justifyContent="center">
                    <TextField 
                            margin="dense"
                            variant="standard"
                            id="linkClaseVivo"
                            label="Link clase en vivo"
                            value={formData.link_clase_en_vivo}
                            onChange={(event)=>{handleChangue(event.target.value,'link_clase_en_vivo');}}
                            error={mensajesForm.link_clase_en_vivo}
                            helperText={mensajesForm.link_clase_en_vivo}
                            type="text"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                </Grid>
                <Grid item lg={6} md={6} xs={6} sm={12} justifyContent="center">
                    <TextField 
                            margin="dense"
                            variant="standard"
                            id="linkClaseGrabada"
                            label="Link clase grabada"
                            value={formData.link_clase_grabada}
                            onChange={(event)=>{handleChangue(event.target.value,'link_clase_grabada');}}
                            error={mensajesForm.link_clase_grabada}
                            helperText={mensajesForm.link_clase_grabada}
                            type="text"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                </Grid>
            </Grid>
            <div className='form-button form-button-background-azul'>
                <Button variant="contained" 
                size="small" 
                onClick={guardarInformacion}
                >Registrar</Button>
            </div>
            <Snackbar
                open={mensaje.mostrarMensaje}
                autoHideDuration={3000}
                onClose={()=>setMensaje({...mensaje,mostrarMensaje:false})}
            >
                <MuiAlert elevation={6} variant="filled" severity={mensaje.tipoMensaje}> {mensaje.textoMensaje} </MuiAlert>
            </Snackbar>
        </>
    );
};
