import React from 'react';
import styles from './ProgressIntro.module.css';
import Button from '../../components/Button/Button';

export default function ProgressIntro() {
    return(
        <div className= {styles.container + ' container-margin'}>
            <p className={styles.title}>
                Ya formas parte del programa Súbete a la Nube con Google Cloud
            </p>
            <p>
                Plataforma innovadora de entrenamiento en la que los usuarios pueden acceder a certificados y cursos con la opción de seguir
                diferentes caminos de carrera que le ayudarán a construir un perfil profesional en Cloud Computing. <br />
                Descarga tus créditos para que puedas realizar tus laboratorios de forma gratuita en el siguiente link:
            </p>
            <div>
                <a className={styles.link} href="https://www.cloudskillsboost.google/catalog_lab/1281?qlcampaign=4p-EDUCR-IADB-SEPT24-MX-90" target="_blank" rel="noreferrer">
                    <Button backGroundColor={'#2282f4'}>descargar créditos</Button>
                </a>
            </div>
        </div>
    );
};
