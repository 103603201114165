import React,{useState,useEffect} from 'react';
import style from './HeaderImg.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from 'react-bootstrap/Carousel';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function HeaderImg({headerEstatus=0}) {

    const [imgUrl, setImgUrl] = useState([{text:''}]);
    const imgdefault = useMediaQuery('screen and (min-width: 860px)');
    const arrayHeadersImg = [
        {header:'banner_landing.jpg', headerMovil:'banner_landing_movil.jpg'},
        {header:'banner_validacion.png', headerMovil:'banner_validacion_movil.png'},
        {header:'banner_progreso.png', headerMovil:'banner_progreso_movil.png'},
        {header:'banner_finalizacion.png', headerMovil:'banner_finalizacion_movil.png'},
    ]

    useEffect(() => {
        //Aqui se integrara la consulta para los banner
        const listImg = [];
        const auxImg = {texto_img:''};
        listImg.push(auxImg);
        // listImg.push(auxImg);
        setImgUrl(listImg);

        // axios.get('https://dev-backend-dot-driven-presence-373215.uc.r.appspot.com/api/v1/frontend/noticias/?despliegue='+despliegue)
        // .then((response) => {
        //     setImgUrl(response.data.conf_noticias);
        // })
        // .catch((error) => {
        //     console.error("Error en la petición ",error);
        // });
    }, []);

    const Banner = ({url= imgdefault ?'banner_landing.png':'banner_landing_movil.png',text}) => {
        return(
            <div className={style.container} style={{backgroundImage:'url("'+url+'")'}}>
                <div className={style.text + ' container-margin'}>
                    <span>
                       <div dangerouslySetInnerHTML={{ __html: text}} />
                    </span>
                </div>
            </div>
        );
    };

    return(
        <>
        { imgUrl.length > 1 ?
        <Carousel>
        {
          imgUrl.map( img => (
            <Carousel.Item> 
              {
                <Banner url={img.url_link} text={img.texto_img} />
              }
            </Carousel.Item>
          ))
        }
        </Carousel> :
        <Banner url={imgdefault ? arrayHeadersImg[headerEstatus].header: arrayHeadersImg[headerEstatus].headerMovil} text={imgUrl[0].texto_img} />
        }
        </>
    );
};
