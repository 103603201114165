import React from 'react';
import style from './Header.module.css';
import SessionContex from '../../provider/SessionContex';
import SignIn from '../../components/Signin/SignIn';
import DailogRegister from '../../components/DialogRegister/DailogRegister';
import Inroads from '../../img/logos/inroads_logo.svg';
import Google from '../../img/logos/google_logo.svg';
import BID from '../../img/logos/BID_logo.svg';
import useMediaQuery from '@mui/material/useMediaQuery';
import AdminMenu from '../../components/AdminMenu/AdminMenu';
import { useHistory } from "react-router-dom";


export default function Header() {

    const history = useHistory();
    const containerMargin = useMediaQuery('screen and (min-width: 1058px)');
    const email = sessionStorage.getItem('email');

    const login = () => {
        sessionStorage.clear();
        window.location.replace('/');
    }

    return(
        <SessionContex.Consumer>
        {(context) => (
        <div className={style.container} style={{padding:email?'0px':'25px 0px'}}>
            { containerMargin ?
            <>
            <AdminMenu/>
            <div className={style['container-logos']} onClick={() => history.push("/")}>
                <div>
                    <img src={Inroads} alt="Inroads" width={150} height={'auto'}/>
                </div>
                <div>
                    <img src={Google} alt="google cloud" width={180} height={'auto'} />
                </div>
                <div>
                    <img src={BID} alt="BID" width={120} height={'auto'}/>
                </div>
            </div>
            {!email ?
            <div className={style['container-butons']}>
                <SignIn context={context} />
                <DailogRegister/>
            </div> :
            <div className={style['container-user']}>
                <div className={style['box-info'] + ' ' + style['box-nombre_usuario']}>
                    <span>Bienvenido {email}</span>
                </div>
                <div className={style['box-info']+' '+style['box-cerrar_sesion']} onClick={login}>
                    <span>Cerrar sesión</span>
                </div>
            </div>
            }
            </> :
            <>
            <div className={style['container-logos']}>
                <div>
                    <img src={Inroads} alt="Inroads" width={100} height={'auto'}/>
                </div>
                <div>
                    <img src={Google} alt="google cloud" width={130} height={'auto'} />
                </div>
                <div>
                    <img src={BID} alt="BID" width={70} height={'auto'}/>
                </div>
            </div>
            {!email ?
            <div className={style['container-butons']}>
                <SignIn context={context} />
                <DailogRegister/>
            </div> :
            <div className={style['container-user']}>
                <div className={style['box-info'] + ' ' + style['box-nombre_usuario']}>
                    <span>Bienvenido {email}</span>
                </div>
                <div className={style['box-info']+' '+style['box-cerrar_sesion']} onClick={login}>
                    <span>Cerrar sesión</span>
                </div>
            </div>
            }
            </>
            }
            
        </div>
        )
        }
        </SessionContex.Consumer>
    );
};
