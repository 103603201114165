import React, {useState} from 'react';
import axios from 'axios';
import styles from './Congratulations.module.css';
import RequiredInfoIns from '../RequiredInfoIns/RequiredInfoIns';
import TextField from "@mui/material/TextField";
import Button from '../../components/Button/Button';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

export default function Congratulations({updateEstatusModulo}) {

    const [url, setUrl] = useState('');
    const [urlInvalido, setUrlInvalido] = useState('');
    const [checkAviso, setCheckAviso] = useState(false);

    const [mensaje, setMensaje] = useState({
        mostrarMensaje: false,
        textoMensaje:"Se actualizo de manera correcta",
        tipoMensaje:"success"
    });

    const validaURL = () => {

        const formDataAux = new FormData();
        formDataAux.append("url",url);

        axios.post(process.env.REACT_APP_BACKEND_ENDPOINT+'/api/v1/form/valid_url',formDataAux,
        { headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}})
        .then(res => {
            if(res.data.estatus === 1) {
                console.log("Se guarda URL");
                guardarUrl();
            } else {
                setUrlInvalido(res.data.respuesta);
            }
        })
        .catch(error => {
            setUrlInvalido('No se pudo validar URL, favor de intentarlo más tarde');
            console.error(error);
        });
    }

    const guardarUrl = () => {
        const formDataAux = new FormData();
        formDataAux.append("url",url);

        axios.post(process.env.REACT_APP_BACKEND_ENDPOINT+'/api/v1/form/save_url',formDataAux,
        { headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}})
        .then(res => {
            setMensaje({mostrarMensaje:true,textoMensaje:'Se valido y guardo de manera correcta', tipoMensaje:'success'});
            updateEstatusModulo(8);
            sessionStorage.setItem('estatusModulo','8');
        })
        .catch(error => {
            setUrlInvalido('No se pudo validar URL, favor de intentarlo más tarde');
            console.error(error);
        });
    }

    return(
        <>
        <div className = {styles['container'] + ' container-margin'}>
            <p style={{fontSize:'18px'}}><b>¡Felicidades! Has completado el primer paso, ahora debes realizar los siguientes:</b></p>
            <p>Para poder participar en este programa, necesitarás una cuenta en la plataforma Google Cloud.<br />
            Sigue las instrucciones para crear una cuenta  compartir tu pefil público. Con esto podrás <br />
            compartir tus logros en Linkedln y nosotros podremos dar seguimiento de tu avance.</p>
            <p>
                <b>1.Ingresa a la siguiente dirección:</b> <a href="https://www.cloudskillsboost.google/" target="_blank" rel="noopener noreferrer">https://www.cloudskillsboost.google/</a> <br />
                <b>2. Da clic en "Unirse"</b> y crea una cuenta usando el e-mail registrado en este formulario (crear una cuenta siempre es gratis). <br />
                <b>3. Si tienes dudas, ve el siguiente video</b> y sigue las instrucciones.
            </p>
        </div>
        <RequiredInfoIns/>
        <div className = {styles['container'] + ' container-margin'}>
            <p>
                <b>
                    4. Pega aquí tu URL pública, para poder continuar tu proceso.
                </b>
            </p>
            <div className={styles['container-url']}>
                <TextField 
                    margin="dense"
                    id="url"
                    p
                    value={url}
                    onChange={(event)=>{setUrl(event.target.value);}}
                    error={urlInvalido}
                    helperText={urlInvalido}
                    type="text"
                    fullWidth
                    variant="standard"
                    inputProps={{placeholder:'Pega aquí tu URL'}}
                />
                <FormGroup className='form-cheks'>
                    <FormControlLabel control={<Checkbox />} checked={checkAviso} onChange={(event)=>{setCheckAviso(event.target.checked); setUrlInvalido('')}} label={<i>He leído y acepto los Terminos y Condiciones del uso de los créditos destinados a este programa.</i>} />
                </FormGroup>
            </div>
            <div className={styles['container-button']}>
                <Button disabled={!(url&&checkAviso)} backGroundColor={!(url&&checkAviso)?'#d3d3d3':'#2282f4'} onClick={validaURL}> Validar URL</Button>
            </div>
        </div>
        <Snackbar
            open={mensaje.mostrarMensaje}
            autoHideDuration={3000}
            onClose={()=>setMensaje({...mensaje,mostrarMensaje:false})}
        >
            <MuiAlert elevation={6} variant="filled" severity={mensaje.tipoMensaje}> {mensaje.textoMensaje} </MuiAlert>
        </Snackbar>
        </>
    );
};
