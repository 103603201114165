import React,{useState} from 'react';
import Header from '../Header/Header';
import HeaderImg from '../HeaderImg/HeaderImg';
import Foooter from '../Footer/Footer';
import FormOne from '../FormOne/FormOne';
import FormTwo from '../FormTwo/FormTwo';
import FormThree from '../FormThree/FormThree';
import SeccionRechazo from '../SeccioinRechazo/SeccionRechazo';
import SeccionEspera from '../SeccioinEspera/SeccionEspera';
import HomeAccept from '../HomeAccept/HomeAccept';
import HomeMain from '../HomeMain/HomeMain.js';
import HomeFinish from '../HomeFinish/HomeFinish'

export default function HomeIns() {
    const [esatusModulo, setEsatusModulo] = useState(parseInt(sessionStorage.getItem('estatusModulo')));

    // const [esatusModulo, setEsatusModulo] = useState(1);

    const updateEstatusModulo = (idEstatus) => {
        setEsatusModulo(idEstatus)
    };

    return(
        <>
            <Header/>
            { (esatusModulo === 1 || esatusModulo === 3 || esatusModulo === 4) &&
            <HeaderImg headerEstatus={0}/>
            }
            
            { esatusModulo === 1 &&
            <FormOne updateEstatusModulo={updateEstatusModulo}/>
            }
            { esatusModulo === 3 &&
            <FormTwo updateEstatusModulo={updateEstatusModulo}/>
            }
            { esatusModulo === 4 &&
            <FormThree updateEstatusModulo={updateEstatusModulo}/>
            }
            { esatusModulo === 5 &&
            <SeccionEspera />
            }
            { esatusModulo === 6 &&
            <SeccionRechazo />
            }
            { esatusModulo === 7 &&
            <HomeAccept  updateEstatusModulo={updateEstatusModulo}/>
            }
            { esatusModulo === 8 &&
            <HomeMain />
            }
            { esatusModulo === 9 &&
            <HomeFinish />
            }



            <Foooter/>
        </>
    );
};
