import React from 'react';
import style from './CardGrey.module.css';

export default function CardGrey({img,name,children}) {
    return(
        <div className={style.container}>
            <div className={style.img}>
                <img src={img} alt="foto" style={{ width: "135px"}} />
            </div>
            <div className={style['container-text']}>
                <p className={style.name}>{name}</p>
                <p className={style.children_text}>
                    {children}
                </p>
            </div>
        </div>
    );
};
