import React,{useState,useEffect} from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment/moment';

export default function SeccionRegOleada() {

    // Para control de mensajes de exito y error
    const [mensaje, setMensaje] = useState({
        mostrarMensaje: false,
        textoMensaje:"Se actualizo de manera correcta",
        tipoMensaje:"success"
    });
    // Fin control de mensajes

    const [refrescar, setRefrescar] = useState(true);

    const [listaOleada, setlistaOleada] = useState([]);

    const [oleadaSelect, setOleadaSelect] = useState({
        id_oleada:null,
        nombre:"",
        fecha_inicio:"",
        fecha_fin:"",
        liga_facebook:"",
    });

    const [formData, setFormData] = useState({
        nombre:"",
        fecha_inicio:"",
        fecha_fin:"",
        liga_facebook:"",
    });

    const regexTexto = /^[0-9a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-_]+$/u;

    const mensajeUrlNoValida = "URL no valida";
    const mensajeRequerido = "Campo requerido";

    const metaDataForm = {
        nombre:{required:true, messageRequired:mensajeRequerido},
        fecha_inicio:{required:true, messageRequired:mensajeRequerido},
        fecha_fin:{required:true, messageRequired:mensajeRequerido},
        liga_facebook:{required:true, regex:regexTexto,  messageRequired:mensajeRequerido, messageRegex:mensajeUrlNoValida},
    };

    const [mensajesForm, setMensajesForm] = useState({
        nombre:"",
        fecha_inicio:"",
        fecha_fin:"",
        liga_facebook:"",
    });

    useEffect(() => {
        limpiarDatos();

        axios.get(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/admin/oleadas_read/',
        { headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}})
        .then((response) => {
            setlistaOleada(response.data.oleadas);
        })
        .catch((error) => {
            console.error(error);
        });
    }, [refrescar]);

    const handleChangue = (value,nombreParametro) => {

        const valueAux = metaDataForm[nombreParametro].upperCase ? value.toUpperCase():value;

        validaDato(valueAux,nombreParametro);

        setFormData({...formData, [nombreParametro]:valueAux});

    }

    const validaDato = (value,nombreParametro) => {

        //Se valida si es requerido
        if(metaDataForm[nombreParametro].required && !value) {
            setMensajesForm({...mensajesForm, [nombreParametro]:metaDataForm[nombreParametro].messageRequired});
            return false;
        }

        //Se valida si tiene una expresion regular
        if(metaDataForm[nombreParametro].regex &&!metaDataForm[nombreParametro].regex.test(value)&&value) {
            setMensajesForm({...mensajesForm, [nombreParametro]:metaDataForm[nombreParametro].messageRegex});
            return false;
        }

        //Se valida si tiene un minimo de caracteres
        if(metaDataForm[nombreParametro].minLength &&metaDataForm[nombreParametro].minLength > value.length) {
            setMensajesForm({...mensajesForm, [nombreParametro]:metaDataForm[nombreParametro].messageMinLength});
            return false;
        }

        //Se limpia mensaje
        setMensajesForm({...mensajesForm, [nombreParametro]:""});
        return true;
    };

    const seleccionaOleada = (oleada) => {
        limpiarDatos();
        if(oleada) {
            setFormData({
                nombre:oleada.nombre,
                liga_facebook:oleada.liga_facebook,
                fecha_inicio:moment(oleada.fecha_inicio, "DD-MM-yyyy hh:mm:ss").format("yyyy-MM-DD"),
                fecha_fin:moment(oleada.fecha_fin, "DD-MM-yyyy hh:mm:ss").format("yyyy-MM-DD"),
            });
            setOleadaSelect(oleada);
        }
    }

    const limpiarDatos = () => {

        setFormData({
            nombre:'',
            fecha_inicio:'',
            fecha_fin:'',
            liga_facebook:"",
        });

        setOleadaSelect({
            id_oleada:null,
            nombre:"",
            fecha_inicio:"",
            fecha_fin:"",
        });
    }

    const validaForm = () => {
        for (const nombreCampo in formData) {
            if(!validaDato(formData[nombreCampo],nombreCampo)) {
                return false;
            }
        }
        return true;
    }

    const guardarInformacion = () => {

        if(validaForm()) {
            const oleadaFormData = new FormData();

            if(oleadaSelect&&oleadaSelect.id_oleada) {
                oleadaFormData.append('id_oleada',oleadaSelect.id_oleada);
            }
            
            oleadaFormData.append('nombre',formData.nombre);
            oleadaFormData.append('fecha_inicio',moment(formData.fecha_inicio,"yyyy-MM-DD").format("yyyy-MM-DD HH:mm:ss"));
            oleadaFormData.append('fecha_fin', moment(formData.fecha_fin,"yyyy-MM-DD").format("yyyy-MM-DD HH:mm:ss"));
            oleadaFormData.append('liga_facebook',formData.liga_facebook);
            
            axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/admin/oleadas_write/',oleadaFormData,
            { headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}})
            .then((response) => {
                if(response.data.estatus ===1) {
                    setRefrescar(!refrescar);
                    setMensaje({
                        mostrarMensaje:true,
                        textoMensaje:"Se guardo correctamente la información.",
                        tipoMensaje:"success"
                    });
                } else {
                    setMensaje({
                        mostrarMensaje:true,
                        textoMensaje:"Se produjo un error al guardar la información: "+ response.data.mensaje,
                        tipoMensaje:"error"
                    });
                }
            })
            .catch((error) => {
                setMensaje({
                    mostrarMensaje:true,
                    textoMensaje:"Se produjo un error al guardar la información, favor de volver a intentar",
                    tipoMensaje:"error"
                });
            });
        }
     }

    return (
        <>
            <h2>Registrar Oleada</h2> <br />
            <Grid container rowSpacing={4} columnSpacing={8}>
                <Grid item xs={12} md={12}>
                    <span className='nota_asterisco'>*</span><span className='nota'> Selecciona la ola:</span>
                    <br />
                    <Select
                        className='select_admin'
                        id="idOleada"
                        onChange={(event)=>{seleccionaOleada(event.target.value);}}
                        value={oleadaSelect}
                    >
                         <MenuItem key={0} value={null}>Seleccionar</MenuItem>
                        {   
                            listaOleada.map(oleada => (
                                <MenuItem key={oleada.id_oleada} value={oleada}>{oleada.nombre}</MenuItem>
                            ))
                        }
                    </Select>
                </Grid>
                <Grid item lg={6} md={6} xs={6} sm={12} >
                    <TextField 
                            margin="dense"
                            id="nombre"
                            label="Nombre oleada"
                            value={formData.nombre}
                            variant="standard"
                            onChange={(event)=>{handleChangue(event.target.value,'nombre');}}
                            error={mensajesForm.nombre}
                            helperText={mensajesForm.nombre}
                            type="text"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                    />
                </Grid>
                <Grid item lg={6} md={6} xs={6} sm={12} >
                    <TextField 
                                margin="dense"
                                variant="standard"
                                id="ligaFacebook"
                                label="Registra liga de facebook"
                                value={formData.liga_facebook}
                                onChange={(event)=>{handleChangue(event.target.value,'liga_facebook');}}
                                error={mensajesForm.liga_facebook}
                                helperText={mensajesForm.liga_facebook}
                                type="text"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                </Grid>
                <Grid item lg={6} md={6} xs={6} sm={12} justifyContent="center">
                    <TextField 
                            margin="dense"
                            variant="standard"
                            id="fecha_inicio"
                            label="Fecha inicio"
                            value={formData.fecha_inicio}
                            onChange={(event)=>{handleChangue(event.target.value,'fecha_inicio');}}
                            error={mensajesForm.fecha_inicio}
                            helperText={mensajesForm.fecha_inicio}
                            type="date"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                </Grid>
                <Grid item lg={6} md={6} xs={6} sm={12} justifyContent="center">
                    <TextField 
                            margin="dense"
                            variant="standard"
                            id="fecha_fin"
                            label="Fecha fin"
                            value={formData.fecha_fin}
                            onChange={(event)=>{handleChangue(event.target.value,'fecha_fin');}}
                            error={mensajesForm.fecha_fin}
                            helperText={mensajesForm.fecha_fin}
                            type="date"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                </Grid>
            </Grid>
            <div className='form-button form-button-background-azul'>
                <Button variant="contained" 
                size="small" 
                onClick={guardarInformacion}
                >Registrar</Button>
            </div>
            <Snackbar
                open={mensaje.mostrarMensaje}
                autoHideDuration={3000}
                onClose={()=>setMensaje({...mensaje,mostrarMensaje:false})}
            >
                <MuiAlert elevation={6} variant="filled" severity={mensaje.tipoMensaje}> {mensaje.textoMensaje} </MuiAlert>
            </Snackbar>
        </>
    )
};
