import React,{useEffect,useState} from 'react';
import axios from 'axios';
import ProgressIntro from '../ProgressIntro/ProgressIntro';
import ProgressVideo from '../ProgressVideo/ProgressVideo';
import ProgressBar from '../ProgressBar/ProgressBar';
import ProgressInsign from '../ProgressInsign/ProgressInsign';
import ProgressFaceGroup from '../ProgressFaceGroup/ProgressFaceGroup';
import ProgressCalendar from '../ProgressCalendar/ProgressCalendar';
import ProgressClass from '../ProgressClass/ProgressClass';

export default function Progress() {

    const [progress, setProgress] = useState(0);
    const [listInsign, setListInsign] = useState([]);
    const [listCalendar, setListCalendar] = useState([]);
    const [listClassTec, setListClassTec] = useState([]);
    const [ligaFacebook, setLigaFacebook] = useState('');
   
    useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND_ENDPOINT+'/api/v1/felicidades/get_badges',
        { headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}})
        .then((response) => {
            setListInsign(response.data.badges);
            setProgress(response.data.progress);
        })
        .catch((error) => {
            console.error(error);
        });

        axios.get(process.env.REACT_APP_BACKEND_ENDPOINT+'/api/v1/felicidades/get_clases',
        { headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}})
        .then((response) => {
            setListCalendar(response.data.clases_en_vivo);
            setListClassTec(response.data.clase_tecnica);
            const ligaFace = response.data.clase_tecnica[0]?response.data.clase_tecnica[0].liga_facebook:'';
            setLigaFacebook(ligaFace);
        })
        .catch((error) => {
            console.error(error);
        });
    }, []);
    

    return(
        <>
        <ProgressIntro/>
        <ProgressVideo/>
        <ProgressBar progress={progress} />
        <ProgressInsign listInsign={listInsign} />
        <ProgressFaceGroup ligaFace={ligaFacebook}/>
        <ProgressCalendar listCalendar={listCalendar} />
        <ProgressClass listClassTec={listClassTec} />
        </>
    );
};
