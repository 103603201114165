import React from 'react';
import styles from './ProgresFinish.module.css';
import Button from '../../components/Button/Button';

export default function ProgresFinish(params) {
    return(
        <div className= {styles.container + ' container-margin'}>
            <p className={styles.title}>
                ¡Felicidades!, has finalizado tu progreso satisfactoriamente.
            </p>
            <p className={styles.mensaje}>
                Te invitamos a que realices las siguientes encuestas para que nosotros podamos seguir mejorando
            </p>
            <div className={styles.encuestas}>
                <a className={styles.link} href="https://www.youtube.com/watch?v=BLQuoVrMiO8" target="_blank" rel="noreferrer">
                        <Button backGroundColor={'#2282f4'}>Certificado</Button>
                </a>
                <a className={styles.link} href="https://www.cloudskillsboost.google/journeys/36" target="_blank" rel="noreferrer">
                        <Button backGroundColor={'#2282f4'}>GCCF</Button>
                </a>
                <a className={styles.link} href="https://www.tfaforms.com/5120380" target="_blank" rel="noreferrer">
                        <Button backGroundColor={'#2282f4'}>Encuesta</Button>
                </a>
            </div>
        </div>
    )
};
