import React from 'react';
import styles from './CardInsign.module.css';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveIcon from '@material-ui/icons/Remove';
import LogoGoogle from '../../img/logos/google_logo.svg';
import moment from 'moment';


export default function CardInsign({children, date, finish}) {
    return(
        <div className={styles['container-main']}>
            <div className={styles.container}>
                {children}
                {/* <div className={styles.header}>
                    <img src={LogoGoogle} alt="logo" />
                </div>
                <div className={styles.text}>
                    {children}
                </div> */}
                {/* <div className={styles.text}>
                    -----/------ <br />
                    COMPLETION BADGE
                </div> */}
                {/* { finish ?
                <div className={styles.complete}>
                    <div className={styles['complete-logo']}>
                        <RemoveIcon  style={{color:'#c3c3c3'}}/><CheckCircleIcon style={{color:'#2282f4', fontSize:'16px'}}/> <RemoveIcon style={{color:'#c3c3c3'}}/> <br />
                    </div>
                    <div>
                        COMPLETION BADGE
                    </div>
                </div> : <></>
                } */}
            </div>
            <div className={styles.footer}>
                <p>{date ? 'Fecha máxima para completar este laboratorio':'Laboratorio opcional'}</p>
                <p>{date}</p>
            </div>
        </div>
    );
};
