import React from 'react';
import style from './Intro.module.css';
import CardWhite from '../../components/CardWhite/CardWhite';
import BID1 from '../../img/introheaders/BID_02.svg';
import BID2 from '../../img/introheaders/BID_03.svg';
import BID3 from '../../img/introheaders/BID_04.svg';

export default function Intro() {
    return(
        <div className='container-margin'>
            <div className={style.text + ' margin-top-md'}>
                <p>
                    Aprende a usar Google Cloud Platform y perfecciona tus <b>soft skills</b> con este Programa educativo, <b>¡sin costo para ti!</b> <br /> <br />
                    Implementa soluciones de cómputo de manera sencilla y desarrolla habilidades imprescindibles para el mundo digital: <br />
                    <b>Infraestructura, Redes, Seguridad, Inteligencia Artificial, Data & Machine Learning. </b><br /> <br />
                    El Programa educativo <b>Súbete a la Nube con Google Cloud,</b> está compuesto de varios cursos en línea donde obtendrás <br />
                    Insignias con valor curricular. <b>¡No esperes más e impulsa tu carrera profesional!</b>
                </p>
                <p className={style['text-bold']}>
                    ¡Visualiza, avanza, crece y marca la diferencia!
                </p>
            </div>
            <div className={style['container-cards'] + ' margin-top-md'}>
                <CardWhite 
                    img={BID1} 
                    title={"1. Beneficios "}
                    text={<>
                            <ul>
                            <li>Aprendizaje <b>sin costo</b> sobre tecnología de vanguardia</li>
                            <li>Fortalecimiento de <b>soft skills</b></li>
                            <li>Comunidad y networking</li>
                            <li>Acceso a más y mejores <b>oportunidades laborales</b></li>
                            </ul>
                        </>}
                    nota={"(5 clases, 1 por semana de 1.5 hrs.)"}
                />
                <CardWhite 
                    img={BID2}
                    title={"2. Programa de estudio"}
                    text={<>
                        <ul>
                            <li>3 <b>Clases de soft skills</b> en vivo online</li>
                            <li><b>Masterclass con experto</b> de Google Cloud en vivo online</li>
                            <li>4 <b>Laboratorios en línea</b> para poner en práctica tus habilidades (1 por semana)</li>
                        </ul>
                        </>}
                    nota={"(1 por semana, dedicándole de 10 a 15 horas de autoestudio)"}
                />
                <CardWhite
                     img={BID3}
                     title={"3. Contenido del curso"}
                     text={<>
                        <ul>
                            <li>Infraestructura</li>
                            <li>Redes</li>
                            <li>Seguridad</li>
                            <li>Inteligencia Artificial</li>
                            <li>Data & Machine Learning</li>
                        </ul>
                     </>}
                />
            </div>
        </div>
    );
};
