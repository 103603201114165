import React from 'react';
import style from './CardWhite.module.css';

export default function CardWhite({img,title,text,nota}) {
    return(
        <div className={style['container']}>
            <div className={style['container-img']}>
                <img src={img} alt="header" width={'80%'}/>
            </div>
            <div className={style['container-text']}>
                <p className={style.title}>{title}</p>
                <p className={style.text}>{text} <br /> <span className={style.nota}>{nota}</span></p>
            </div>
        </div>
    );
};
