import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import "./FormThree.css";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { Radio } from '@mui/material';
import { Modal } from 'react-responsive-modal';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function FormThree({updateEstatusModulo}) {

    const numGridColumn = 4;
    const numGridOffset = 2;

    const classes = useStyles();

    const [formData, setFormData] = useState({
        eval_id_catalogo_uso_cloud: "",
        eval_id_catalogo_crecimiento_area:"",
        eval_id_catalogo_comandos_operativos:"",
        eval_id_catalogo_uso_tecnologia:"",
        eval_id_catalogo_conceptos_cloud:"",
        id_catalogo_likert_distraccion:"",
        id_catalogo_likert_contratiempo:"",
        id_catalogo_likert_obsesion:"",
        id_catalogo_likert_trabajadora:"",
        id_catalogo_likert_meta:"",
        id_catalogo_likert_concentracion:"",
        id_catalogo_likert_finalizacion:"",
        id_catalogo_likert_esfuerzo:"",
        id_catalogo_likert_reservado:"",
        id_catalogo_likert_confiable:"",
        id_catalogo_likert_flojo:"",
        id_catalogo_likert_relajado:"",
        id_catalogo_likert_artisticos:"",
        id_catalogo_likert_extrovertido:"",
        id_catalogo_likert_defectos:"",
        id_catalogo_likert_detallista:"",
        id_catalogo_likert_nervioso:"",
        id_catalogo_likert_activo:"",
        de_acuerdo: "",
        declaracion_jurada: "",
        autoriza_uso_curp: ""
    });

    const [mensajesForm, setMensajesForm] = useState({
        eval_id_catalogo_uso_cloud: "",
        eval_id_catalogo_crecimiento_area:"",
        eval_id_catalogo_comandos_operativos:"",
        eval_id_catalogo_uso_tecnologia:"",
        eval_id_catalogo_conceptos_cloud:"",
        id_catalogo_likert_distraccion:"",
        id_catalogo_likert_contratiempo:"",
        id_catalogo_likert_obsesion:"",
        id_catalogo_likert_trabajadora:"",
        id_catalogo_likert_meta:"",
        id_catalogo_likert_concentracion:"",
        id_catalogo_likert_finalizacion:"",
        id_catalogo_likert_esfuerzo:"",
        id_catalogo_likert_reservado:"",
        id_catalogo_likert_confiable:"",
        id_catalogo_likert_flojo:"",
        id_catalogo_likert_relajado:"",
        id_catalogo_likert_artisticos:"",
        id_catalogo_likert_extrovertido:"",
        id_catalogo_likert_defectos:"",
        id_catalogo_likert_detallista:"",
        id_catalogo_likert_nervioso:"",
        id_catalogo_likert_activo:"",
        de_acuerdo: "",
        declaracion_jurada: "",
        autoriza_uso_curp: ""
    });

    let messagesValidation = {
        eval_id_catalogo_uso_cloud: "",
        eval_id_catalogo_crecimiento_area:"",
        eval_id_catalogo_comandos_operativos:"",
        eval_id_catalogo_uso_tecnologia:"",
        eval_id_catalogo_conceptos_cloud:"",
        id_catalogo_likert_distraccion:"",
        id_catalogo_likert_contratiempo:"",
        id_catalogo_likert_obsesion:"",
        id_catalogo_likert_trabajadora:"",
        id_catalogo_likert_meta:"",
        id_catalogo_likert_concentracion:"",
        id_catalogo_likert_finalizacion:"",
        id_catalogo_likert_esfuerzo:"",
        id_catalogo_likert_reservado:"",
        id_catalogo_likert_confiable:"",
        id_catalogo_likert_flojo:"",
        id_catalogo_likert_relajado:"",
        id_catalogo_likert_artisticos:"",
        id_catalogo_likert_extrovertido:"",
        id_catalogo_likert_defectos:"",
        id_catalogo_likert_detallista:"",
        id_catalogo_likert_nervioso:"",
        id_catalogo_likert_activo:""
    };

    const [listaCatalogos, setListaCatalogos] = useState({
        comandos_operativos: [],
        conceptos_cloud: [],
        crecimiento_area: [],
        likert: [],
        uso_cloud: [],
        uso_tecnologia: [],
        de_acuerdo:[],
        declaracion_jurada:[],
        autoriza_uso_curp:[]
    });

    const [fomularioInvalido, setFomularioInvalido] = useState(false);
    const [modalConfirmacion, setModalConfirmacion] = useState(false);

    const [bloqueo, setBloqueo] = useState(false);

    const mensajeRequerido = "Campo requerido";

    const validaDato = (value,nombreParametro) => {
        //Depende del tipo de validación que tenga es el que se va a realizar
        //Si es requerido
        if(!value) {
            setMensajesForm({...mensajesForm, [nombreParametro]:mensajeRequerido});
            setFomularioInvalido(true);
            return false;
        }

        //Se limpia mensaje
        setMensajesForm({...mensajesForm, [nombreParametro]:""});
        setFomularioInvalido(false);
        return true;
    };

    const validaDato2 = (value,nombreParametro) => {
        //Depende del tipo de validación que tenga es el que se va a realizar
        //Si es requerido
        let formValido = true;
        if(!value) {
            messagesValidation = {...messagesValidation, [nombreParametro]:mensajeRequerido};
            formValido = false;
        }
        //Se limpia mensaje
        setMensajesForm({...mensajesForm, [nombreParametro]:""});
        return formValido;
    };
    

    const handleSelect = (value,nombreParametro) => {
        console.log(value);
        validaDato(value,nombreParametro);
        setFormData({...formData,[nombreParametro]:value});
    };

    const handleRadioSelect = (value,nombreParametro) => {
        validaDato(value.value,nombreParametro);
        setFormData({...formData,[nombreParametro]: value.value});
    };
    useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND_ENDPOINT +'api/v1/catalogos_formulario_read/3',{
            headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}
        })
        .then((response) => {
            setListaCatalogos({...response.data});
        })
        .catch((error) => {
            console.error(error);
        });
    }, []);

    const validaForm = () => {
        let formValid = true;
        for(const elmentForm in formData) {
            if(!validaDato2(formData[elmentForm],elmentForm)) {
               formValid = false;
            }
        }

        if(!formValid) {
            setFomularioInvalido(true);
            setMensajesForm(messagesValidation);
            return false;
        }else{
            setFomularioInvalido(false);
            setModalConfirmacion(true);
        }
    };

    const enviaForm = () => {

        setModalConfirmacion(false);

        setBloqueo(true);
        //Si todo esta correcto se raliza el envio de la información
        const formDataAux = new FormData();
        for(const elmentForm in formData) {
            formDataAux.append(elmentForm,formData[elmentForm]);
        }

        axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/form/form_datos_usuario_write_3',formDataAux,
        {
            headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}
        })
        .then(res => {
            sessionStorage.setItem('estatusModulo','5'); // 5 pantalla de descanso
            updateEstatusModulo(5); //pantalla descanso
            this.props.history.push('/se');
            setBloqueo(false);
        })
        .catch(error => {
            console.error(error);
            setBloqueo(false);
        });
    };

    return(
    <div className='content content-formulario'>
        <Grid container rowSpacing={6} columnSpacing={8}>
            <Grid item md={numGridOffset} display={{ sm: "none", md: "block"}}/>
            <Grid className="lar-top-spacing" item xs={12} md={10}>
            <p> 
                <b>Responde las siguientes preguntas</b> <br />
            </p>
            </Grid>
            {/* 1-START */}
            <Grid item md={numGridOffset} display={{ sm: "none", md: "block"}}/>
            <Grid item xs={12} md={numGridColumn}>
                <span  className='label-styling'>¿Actualmente estudias o trabajas en un área que requiera el uso de tecnología Cloud?</span>
            </Grid>
            <Grid item xs={12} md={numGridColumn} >
                <FormControl error={mensajesForm.eval_id_catalogo_uso_cloud? true : false} fullWidth >
                    <Select id="genero"
                        value={formData.eval_id_catalogo_uso_cloud}
                        error={mensajesForm.eval_id_catalogo_uso_cloud? true : false}
                        onChange={(event)=>handleSelect(event.target.value,'eval_id_catalogo_uso_cloud')}>
                        { 
                            listaCatalogos.uso_cloud.map(data => (
                                <MenuItem className='menu-item-form' key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                            ))
                        }
                    </Select>
                    { mensajesForm.eval_id_catalogo_uso_cloud&&
                    <p className='nota_mensaje_error'>{mensajesForm.eval_id_catalogo_uso_cloud}</p>
                    }
                </FormControl>
            </Grid>
            <Grid item md={numGridOffset} display={{ sm: "none", md: "block"}}/>
            {/* 1-END */}
            {/* 2-START */}
            <Grid item md={numGridOffset} display={{ sm: "none", md: "block"}}/>
            <Grid item xs={12} md={numGridColumn}>
                <span  className='label-styling'>¿Tienes interés en incrementar tu conocimiento en alguna de las siguientes áreas?</span>
            </Grid>
            <Grid item xs={12} md={numGridColumn} >
                <FormControl error={mensajesForm.eval_id_catalogo_crecimiento_area? true : false} fullWidth >
                    <Select id="genero"
                        value={formData.eval_id_catalogo_crecimiento_area}
                        error={mensajesForm.eval_id_catalogo_crecimiento_area ? true : false}
                        onChange={(event)=>handleSelect(event.target.value,'eval_id_catalogo_crecimiento_area')}>
                        { 
                            listaCatalogos.crecimiento_area.map(data => (
                                <MenuItem className='menu-item-form' key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                            ))
                        }
                    </Select>
                    { mensajesForm.eval_id_catalogo_crecimiento_area&&
                    <p className='nota_mensaje_error'>{mensajesForm.eval_id_catalogo_crecimiento_area}</p>
                    }
                </FormControl>
            </Grid>
            <Grid item md={numGridOffset} display={{ sm: "none", md: "block"}}/>
            {/* 2-END */}
            {/* 3-START */}
            <Grid item md={numGridOffset} display={{ sm: "none", md: "block"}}/>
            <Grid item xs={12} md={numGridColumn}>
                <span  className='label-styling'>¿Has hecho uso de la línea de comandos para interactuar con un sistema operativo?</span>
            </Grid>
            <Grid item xs={12} md={numGridColumn} >
                <FormControl error={mensajesForm.eval_id_catalogo_comandos_operativos? true : false} fullWidth >
                    <Select id="genero"
                        value={formData.eval_id_catalogo_comandos_operativos}
                        error={mensajesForm.eval_id_catalogo_comandos_operativos? true : false}
                        onChange={(event)=>handleSelect(event.target.value,'eval_id_catalogo_comandos_operativos')}>
                        { 
                            listaCatalogos.comandos_operativos.map(data => (
                                <MenuItem className='menu-item-form' key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                            ))
                        }
                    </Select>
                    { mensajesForm.eval_id_catalogo_comandos_operativos&&
                    <p className='nota_mensaje_error'>{mensajesForm.eval_id_catalogo_comandos_operativos}</p>
                    }
                </FormControl>
            </Grid>
            <Grid item md={numGridOffset} display={{ sm: "none", md: "block"}}/>
            {/* 3-END */}
            {/* 4-START */}
            <Grid item md={numGridOffset} display={{ sm: "none", md: "block"}}/>
            <Grid item xs={12} md={numGridColumn}>
                <span  className='label-styling'>¿Cómo describirías tu uso de tecnología?</span>
            </Grid>
            <Grid item xs={12} md={numGridColumn} >
                <FormControl error={mensajesForm.eval_id_catalogo_uso_tecnologia? true : false} fullWidth >
                    <Select id="genero"
                        value={formData.eval_id_catalogo_uso_tecnologia}
                        error={mensajesForm.eval_id_catalogo_uso_tecnologia? true : false}
                        onChange={(event)=>handleSelect(event.target.value,'eval_id_catalogo_uso_tecnologia')}>
                        { 
                            listaCatalogos.uso_tecnologia.map(data => (
                                <MenuItem className='menu-item-form' key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                            ))
                        }
                    </Select>
                    { mensajesForm.eval_id_catalogo_uso_tecnologia&&
                    <p className='nota_mensaje_error'>{mensajesForm.eval_id_catalogo_uso_tecnologia}</p>
                    }
                </FormControl>
            </Grid>
            <Grid item md={numGridOffset} display={{ sm: "none", md: "block"}}/>
            {/* 4-END */}
            {/* 5-START */}
            <Grid item md={numGridOffset} display={{ sm: "none", md: "block"}}/>
            <Grid item xs={12} md={numGridColumn}>
                <span  className='label-styling'>¿Tienes familiaridad con conceptos de Cloud?</span>
            </Grid>
            <Grid item xs={12} md={numGridColumn} >
                <FormControl error={mensajesForm.eval_id_catalogo_conceptos_cloud? true : false} fullWidth >
                    <Select id="genero"
                        value={formData.eval_id_catalogo_conceptos_cloud}
                        error={mensajesForm.eval_id_catalogo_conceptos_cloud? true : false}
                        onChange={(event)=>handleSelect(event.target.value,'eval_id_catalogo_conceptos_cloud')}>
                        { 
                            listaCatalogos.conceptos_cloud.map(data => (
                                <MenuItem className='menu-item-form' key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                            ))
                        }
                    </Select>
                    { mensajesForm.eval_id_catalogo_conceptos_cloud&&
                    <p className='nota_mensaje_error'>{mensajesForm.eval_id_catalogo_conceptos_cloud}</p>
                    }
                </FormControl>
            </Grid>
            <Grid item md={numGridOffset} display={{ sm: "none", md: "block"}}/>
            {/* 5-END */}
        </Grid>
        <Grid container rowSpacing={6} columnSpacing={8} className="adjust-top-padding">
            <Grid className="mid-top-spacing" item xs={12}>
                <p> 
                    <b>¿Qué tanto te describe cada una de las expresiones mencionadas a continuación?</b> <br />
                </p>
            </Grid>
            <Grid item lg={6} display={{ xs: "none", lg: "block"}}/>
            <Grid item lg={6} display={{ xs: "none", lg: "block"}}>
                <div className="radio-container">
                    <span className='label-radio-styling'>Completamente en desacuerdo</span>
                    <span className='label-radio-styling'>En desacuerdo</span>
                    <span className='label-radio-styling'>Ni acuerdo ni en desacuerdo</span>
                    <span className='label-radio-styling'>De acuerdo</span>
                    <span className='label-radio-styling'>Completamente de acuerdo</span>
                </div>
            </Grid>
            {/* 6-START */}
            <Grid item xs={12} lg={6}>
                <span  className='label-styling'>Algunas veces nuevas ideas o proyectos me distraen de mis proyectos e ideas anteriores</span>
                { mensajesForm.id_catalogo_likert_distraccion&&
                    <p className='nota_mensaje_error'>{mensajesForm.id_catalogo_likert_distraccion}</p>
                    }
            </Grid>
            <Grid item xs={12} display={{ lg: "none", xs: "block"}}>
                <div className="radio-container">
                    <span className='smaller-label-radio-styling'>Completamente en desacuerdo</span>
                    <span className='smaller-label-radio-styling'>En desacuerdo</span>
                    <span className='smaller-label-radio-styling'>Ni acuerdo ni en desacuerdo</span>
                    <span className='smaller-label-radio-styling'>De acuerdo</span>
                    <span className='smaller-label-radio-styling'>Completamente de acuerdo</span>
                </div>
            </Grid>
            <Grid item xs={12} lg={6}>
                <div className="radio-container">
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_distraccion === '1'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_distraccion')}
                        value="1"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_distraccion === '2'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_distraccion')}
                        value="2"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'B' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_distraccion === '3'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_distraccion')}
                        value="3"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'C' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_distraccion === '4'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_distraccion')}
                        value="4"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'D' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_distraccion === '5'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_distraccion')}
                        value="5"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'E' }} />
                    </span>
                </div>
            </Grid>
            {/* 6-END */}
            {/* 7-START */}
            <Grid item xs={12} lg={6}>
                <span  className='label-styling'>Los contratiempos no me desaniman</span>
                { mensajesForm.id_catalogo_likert_contratiempo&&
                    <p className='nota_mensaje_error'>{mensajesForm.id_catalogo_likert_contratiempo}</p>
                    }
            </Grid>
            <Grid item xs={12} display={{ lg: "none", xs: "block"}}>
                <div className="radio-container">
                    <span className='smaller-label-radio-styling'>Completamente en desacuerdo</span>
                    <span className='smaller-label-radio-styling'>En desacuerdo</span>
                    <span className='smaller-label-radio-styling'>Ni acuerdo ni en desacuerdo</span>
                    <span className='smaller-label-radio-styling'>De acuerdo</span>
                    <span className='smaller-label-radio-styling'>Completamente de acuerdo</span>
                </div>
            </Grid>
            <Grid item xs={12} lg={6}>
                <div className="radio-container">
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_contratiempo === '1'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_contratiempo')}
                        value="1"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_contratiempo === '2'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_contratiempo')}
                        value="2"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'B' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_contratiempo === '3'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_contratiempo')}
                        value="3"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'C' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_contratiempo === '4'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_contratiempo')}
                        value="4"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'D' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_contratiempo === '5'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_contratiempo')}
                        value="5"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'E' }} />
                    </span>
                </div>
            </Grid>
            {/* 7-END */}
            {/* 8-START */}
            <Grid item xs={12} lg={6}>
                <span  className='label-styling'>Me obsesiono con ideas o proyectos por un tiempo, pero pierdo el interés rápidamente</span>
                { mensajesForm.id_catalogo_likert_obsesion&&
                    <p className='nota_mensaje_error'>{mensajesForm.id_catalogo_likert_obsesion}</p>
                    }
            </Grid>
            <Grid item xs={12} display={{ lg: "none", xs: "block"}}>
                <div className="radio-container">
                    <span className='smaller-label-radio-styling'>Completamente en desacuerdo</span>
                    <span className='smaller-label-radio-styling'>En desacuerdo</span>
                    <span className='smaller-label-radio-styling'>Ni acuerdo ni en desacuerdo</span>
                    <span className='smaller-label-radio-styling'>De acuerdo</span>
                    <span className='smaller-label-radio-styling'>Completamente de acuerdo</span>
                </div>
            </Grid>
            <Grid item xs={12} lg={6}>
                <div className="radio-container">
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_obsesion === '1'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_obsesion')}
                        value="1"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_obsesion === '2'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_obsesion')}
                        value="2"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'B' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_obsesion === '3'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_obsesion')}
                        value="3"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'C' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_obsesion === '4'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_obsesion')}
                        value="4"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'D' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_obsesion === '5'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_obsesion')}
                        value="5"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'E' }} />
                    </span>
                </div>
            </Grid>
            {/* 8-END */}
            {/* 9-START */}
            <Grid item xs={12} lg={6}>
                <span  className='label-styling'>Soy una persona muy trabajadora</span>
                { mensajesForm.id_catalogo_likert_trabajadora&&
                    <p className='nota_mensaje_error'>{mensajesForm.id_catalogo_likert_trabajadora}</p>
                    }
            </Grid>
            <Grid item xs={12} display={{ lg: "none", xs: "block"}}>
                <div className="radio-container">
                    <span className='smaller-label-radio-styling'>Completamente en desacuerdo</span>
                    <span className='smaller-label-radio-styling'>En desacuerdo</span>
                    <span className='smaller-label-radio-styling'>Ni acuerdo ni en desacuerdo</span>
                    <span className='smaller-label-radio-styling'>De acuerdo</span>
                    <span className='smaller-label-radio-styling'>Completamente de acuerdo</span>
                </div>
            </Grid>
            <Grid item xs={12} lg={6}>
                <div className="radio-container">
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_trabajadora === '1'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_trabajadora')}
                        value="1"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_trabajadora === '2'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_trabajadora')}
                        value="2"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'B' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_trabajadora === '3'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_trabajadora')}
                        value="3"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'C' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_trabajadora === '4'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_trabajadora')}
                        value="4"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'D' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_trabajadora === '5'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_trabajadora')}
                        value="5"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'E' }} />
                    </span>
                </div>
            </Grid>
            {/* 9-END */}
            {/* 10-START */}
            <Grid item xs={12} lg={6}>
                <span  className='label-styling'>Frecuentemente me pongo una meta, pero más tarde persigo una diferente</span>
                { mensajesForm.id_catalogo_likert_meta&&
                    <p className='nota_mensaje_error'>{mensajesForm.id_catalogo_likert_meta}</p>
                    }
            </Grid>
            <Grid item xs={12} display={{ lg: "none", xs: "block"}}>
                <div className="radio-container">
                    <span className='smaller-label-radio-styling'>Completamente en desacuerdo</span>
                    <span className='smaller-label-radio-styling'>En desacuerdo</span>
                    <span className='smaller-label-radio-styling'>Ni acuerdo ni en desacuerdo</span>
                    <span className='smaller-label-radio-styling'>De acuerdo</span>
                    <span className='smaller-label-radio-styling'>Completamente de acuerdo</span>
                </div>
            </Grid>
            <Grid item xs={12} lg={6}>
                <div className="radio-container">
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_meta === '1'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_meta')}
                        value="1"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_meta === '2'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_meta')}
                        value="2"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'B' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_meta === '3'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_meta')}
                        value="3"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'C' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_meta === '4'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_meta')}
                        value="4"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'D' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_meta === '5'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_meta')}
                        value="5"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'E' }} />
                    </span>
                </div>
            </Grid>
            {/* 10-END */}
            {/* 11-START */}
            <Grid item xs={12} lg={6}>
                <span  className='label-styling'>Tengo dificultades para mantener mi concentración en proyectos que toman más de unos cuentos meses en completarse</span>
                { mensajesForm.id_catalogo_likert_concentracion&&
                    <p className='nota_mensaje_error'>{mensajesForm.id_catalogo_likert_concentracion}</p>
                    }
            </Grid>
            <Grid item xs={12} display={{ lg: "none", xs: "block"}}>
                <div className="radio-container">
                    <span className='smaller-label-radio-styling'>Completamente en desacuerdo</span>
                    <span className='smaller-label-radio-styling'>En desacuerdo</span>
                    <span className='smaller-label-radio-styling'>Ni acuerdo ni en desacuerdo</span>
                    <span className='smaller-label-radio-styling'>De acuerdo</span>
                    <span className='smaller-label-radio-styling'>Completamente de acuerdo</span>
                </div>
            </Grid>
            <Grid item xs={12} lg={6}>
                <div className="radio-container">
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_concentracion === '1'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_concentracion')}
                        value="1"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_concentracion === '2'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_concentracion')}
                        value="2"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'B' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_concentracion === '3'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_concentracion')}
                        value="3"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'C' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_concentracion === '4'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_concentracion')}
                        value="4"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'D' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_concentracion === '5'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_concentracion')}
                        value="5"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'E' }} />
                    </span>
                </div>
            </Grid>
            {/* 11-END */}
            {/* 12-START */}
            <Grid item xs={12} lg={6}>
                <span  className='label-styling'>Termino todo lo que comienzo</span>
                { mensajesForm.id_catalogo_likert_finalizacion&&
                    <p className='nota_mensaje_error'>{mensajesForm.id_catalogo_likert_finalizacion}</p>
                    }
            </Grid>
            <Grid item xs={12} display={{ lg: "none", xs: "block"}}>
                <div className="radio-container">
                    <span className='smaller-label-radio-styling'>Completamente en desacuerdo</span>
                    <span className='smaller-label-radio-styling'>En desacuerdo</span>
                    <span className='smaller-label-radio-styling'>Ni acuerdo ni en desacuerdo</span>
                    <span className='smaller-label-radio-styling'>De acuerdo</span>
                    <span className='smaller-label-radio-styling'>Completamente de acuerdo</span>
                </div>
            </Grid>
            <Grid item xs={12} lg={6}>
                <div className="radio-container">
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_finalizacion === '1'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_finalizacion')}
                        value="1"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_finalizacion === '2'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_finalizacion')}
                        value="2"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'B' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_finalizacion === '3'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_finalizacion')}
                        value="3"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'C' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_finalizacion === '4'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_finalizacion')}
                        value="4"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'D' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_finalizacion === '5'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_finalizacion')}
                        value="5"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'E' }} />
                    </span>
                </div>
            </Grid>
            {/* 12-END */}
            {/* 13-START */}
            <Grid item xs={12} lg={6}>
                <span  className='label-styling'>Pongo mucho esfuerzo en los trabajos que realizo mucho más de lo normal</span>
                { mensajesForm.id_catalogo_likert_esfuerzo&&
                    <p className='nota_mensaje_error'>{mensajesForm.id_catalogo_likert_esfuerzo}</p>
                    }
            </Grid>
            <Grid item xs={12} display={{ lg: "none", xs: "block"}}>
                <div className="radio-container">
                    <span className='smaller-label-radio-styling'>Completamente en desacuerdo</span>
                    <span className='smaller-label-radio-styling'>En desacuerdo</span>
                    <span className='smaller-label-radio-styling'>Ni acuerdo ni en desacuerdo</span>
                    <span className='smaller-label-radio-styling'>De acuerdo</span>
                    <span className='smaller-label-radio-styling'>Completamente de acuerdo</span>
                </div>
            </Grid>
            <Grid item xs={12} lg={6}>
                <div className="radio-container">
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_esfuerzo === '1'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_esfuerzo')}
                        value="1"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_esfuerzo === '2'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_esfuerzo')}
                        value="2"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'B' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_esfuerzo === '3'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_esfuerzo')}
                        value="3"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'C' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_esfuerzo === '4'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_esfuerzo')}
                        value="4"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'D' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_esfuerzo === '5'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_esfuerzo')}
                        value="5"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'E' }} />
                    </span>
                </div>
            </Grid>
            {/* 13-END */}
            <Grid className="mid-top-spacing" item xs={12}>
                <p> 
                    <b>¿Qué tan bien describen tu personalidad los siguientes enunciados?</b> <br />
                </p>
                <p> 
                    <b>Me veo a mi mismo como alguien que...</b> <br />
                </p>
            </Grid>

            <Grid item lg={6} display={{ xs: "none", lg: "block"}}/>
            <Grid item lg={6} display={{ xs: "none", lg: "block"}}>
                <div className="radio-container">
                    <span className='label-radio-styling'>Completamente en desacuerdo</span>
                    <span className='label-radio-styling'>En desacuerdo</span>
                    <span className='label-radio-styling'>Ni acuerdo ni en desacuerdo</span>
                    <span className='label-radio-styling'>De acuerdo</span>
                    <span className='label-radio-styling'>Completamente de acuerdo</span>
                </div>
            </Grid>
            {/* 14-START */}
            <Grid item xs={12} lg={6}>
                <span  className='label-styling'>...es reservado(a)</span>
                { mensajesForm.id_catalogo_likert_reservado&&
                    <p className='nota_mensaje_error'>{mensajesForm.id_catalogo_likert_reservado}</p>
                    }
            </Grid>
            <Grid item xs={12} display={{ lg: "none", xs: "block"}}>
                <div className="radio-container">
                    <span className='smaller-label-radio-styling'>Completamente en desacuerdo</span>
                    <span className='smaller-label-radio-styling'>En desacuerdo</span>
                    <span className='smaller-label-radio-styling'>Ni acuerdo ni en desacuerdo</span>
                    <span className='smaller-label-radio-styling'>De acuerdo</span>
                    <span className='smaller-label-radio-styling'>Completamente de acuerdo</span>
                </div>
            </Grid>
            <Grid item xs={12} lg={6}>
                <div className="radio-container">
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_reservado === '1'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_reservado')}
                        value="1"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_reservado === '2'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_reservado')}
                        value="2"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'B' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_reservado === '3'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_reservado')}
                        value="3"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'C' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_reservado === '4'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_reservado')}
                        value="4"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'D' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_reservado === '5'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_reservado')}
                        value="5"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'E' }} />
                    </span>
                </div>
            </Grid>
            {/* 14-END */}
            {/* 15-START */}
            <Grid item xs={12} lg={6}>
                <span  className='label-styling'>...generalmente es confiable</span>
                { mensajesForm.id_catalogo_likert_confiable&&
                    <p className='nota_mensaje_error'>{mensajesForm.id_catalogo_likert_confiable}</p>
                    }
            </Grid>
            <Grid item xs={12} display={{ lg: "none", xs: "block"}}>
                <div className="radio-container">
                    <span className='smaller-label-radio-styling'>Completamente en desacuerdo</span>
                    <span className='smaller-label-radio-styling'>En desacuerdo</span>
                    <span className='smaller-label-radio-styling'>Ni acuerdo ni en desacuerdo</span>
                    <span className='smaller-label-radio-styling'>De acuerdo</span>
                    <span className='smaller-label-radio-styling'>Completamente de acuerdo</span>
                </div>
            </Grid>
            <Grid item xs={12} lg={6}>
                <div className="radio-container">
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_confiable === '1'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_confiable')}
                        value="1"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_confiable === '2'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_confiable')}
                        value="2"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'B' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_confiable === '3'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_confiable')}
                        value="3"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'C' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_confiable === '4'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_confiable')}
                        value="4"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'D' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_confiable === '5'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_confiable')}
                        value="5"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'E' }} />
                    </span>
                </div>
            </Grid>
            {/* 15-END */}
            {/* 16-START */}
            <Grid item xs={12} lg={6}>
                <span  className='label-styling'>...que tiende a ser flojo(a)</span>
                { mensajesForm.id_catalogo_likert_flojo&&
                    <p className='nota_mensaje_error'>{mensajesForm.id_catalogo_likert_flojo}</p>
                    }
            </Grid>
            <Grid item xs={12} display={{ lg: "none", xs: "block"}}>
                <div className="radio-container">
                    <span className='smaller-label-radio-styling'>Completamente en desacuerdo</span>
                    <span className='smaller-label-radio-styling'>En desacuerdo</span>
                    <span className='smaller-label-radio-styling'>Ni acuerdo ni en desacuerdo</span>
                    <span className='smaller-label-radio-styling'>De acuerdo</span>
                    <span className='smaller-label-radio-styling'>Completamente de acuerdo</span>
                </div>
            </Grid>
            <Grid item xs={12} lg={6}>
                <div className="radio-container">
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_flojo === '1'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_flojo')}
                        value="1"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_flojo === '2'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_flojo')}
                        value="2"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'B' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_flojo === '3'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_flojo')}
                        value="3"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'C' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_flojo === '4'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_flojo')}
                        value="4"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'D' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_flojo === '5'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_flojo')}
                        value="5"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'E' }} />
                    </span>
                </div>
            </Grid>
            {/* 16-END */}
            {/* 17-START */}
            <Grid item xs={12} lg={6}>
                <span  className='label-styling'>...es relajado(a), que maneja bien el estrés</span>
                { mensajesForm.id_catalogo_likert_relajado&&
                    <p className='nota_mensaje_error'>{mensajesForm.id_catalogo_likert_relajado}</p>
                    }
            </Grid>
            <Grid item xs={12} display={{ lg: "none", xs: "block"}}>
                <div className="radio-container">
                    <span className='smaller-label-radio-styling'>Completamente en desacuerdo</span>
                    <span className='smaller-label-radio-styling'>En desacuerdo</span>
                    <span className='smaller-label-radio-styling'>Ni acuerdo ni en desacuerdo</span>
                    <span className='smaller-label-radio-styling'>De acuerdo</span>
                    <span className='smaller-label-radio-styling'>Completamente de acuerdo</span>
                </div>
            </Grid>
            <Grid item xs={12} lg={6}>
                <div className="radio-container">
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_relajado === '1'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_relajado')}
                        value="1"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_relajado === '2'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_relajado')}
                        value="2"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'B' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_relajado === '3'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_relajado')}
                        value="3"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'C' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_relajado === '4'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_relajado')}
                        value="4"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'D' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_relajado === '5'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_relajado')}
                        value="5"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'E' }} />
                    </span>
                </div>
            </Grid>
            {/* 17-END */}
            {/* 18-START */}
            <Grid item xs={12} lg={6}>
                <span  className='label-styling'>...tiene pocos intereses artísticos</span>
                { mensajesForm.id_catalogo_likert_artisticos&&
                    <p className='nota_mensaje_error'>{mensajesForm.id_catalogo_likert_artisticos}</p>
                    }
            </Grid>
            <Grid item xs={12} display={{ lg: "none", xs: "block"}}>
                <div className="radio-container">
                    <span className='smaller-label-radio-styling'>Completamente en desacuerdo</span>
                    <span className='smaller-label-radio-styling'>En desacuerdo</span>
                    <span className='smaller-label-radio-styling'>Ni acuerdo ni en desacuerdo</span>
                    <span className='smaller-label-radio-styling'>De acuerdo</span>
                    <span className='smaller-label-radio-styling'>Completamente de acuerdo</span>
                </div>
            </Grid>
            <Grid item xs={12} lg={6}>
                <div className="radio-container">
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_artisticos === '1'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_artisticos')}
                        value="1"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_artisticos === '2'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_artisticos')}
                        value="2"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'B' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_artisticos === '3'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_artisticos')}
                        value="3"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'C' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_artisticos === '4'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_artisticos')}
                        value="4"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'D' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_artisticos === '5'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_artisticos')}
                        value="5"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'E' }} />
                    </span>
                </div>
            </Grid>
            {/* 18-END */}
            {/* 18.5-START */}
            <Grid item xs={12} lg={6}>
                <span  className='label-styling'>...es extrovertido(a), sociable</span>
                { mensajesForm.id_catalogo_likert_extrovertido&&
                    <p className='nota_mensaje_error'>{mensajesForm.id_catalogo_likert_extrovertido}</p>
                    }
            </Grid>
            <Grid item xs={12} display={{ lg: "none", xs: "block"}}>
                <div className="radio-container">
                    <span className='smaller-label-radio-styling'>Completamente en desacuerdo</span>
                    <span className='smaller-label-radio-styling'>En desacuerdo</span>
                    <span className='smaller-label-radio-styling'>Ni acuerdo ni en desacuerdo</span>
                    <span className='smaller-label-radio-styling'>De acuerdo</span>
                    <span className='smaller-label-radio-styling'>Completamente de acuerdo</span>
                </div>
            </Grid>
            <Grid item xs={12} lg={6}>
                <div className="radio-container">
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_extrovertido === '1'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_extrovertido')}
                        value="1"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_extrovertido === '2'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_extrovertido')}
                        value="2"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'B' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_extrovertido === '3'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_extrovertido')}
                        value="3"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'C' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_extrovertido === '4'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_extrovertido')}
                        value="4"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'D' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_extrovertido === '5'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_extrovertido')}
                        value="5"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'E' }} />
                    </span>
                </div>
            </Grid>
            {/* 18.5-END */}

            {/* 19-START */}
            <Grid item xs={12} lg={6}>
                <span  className='label-styling'>...que tiende a encontrar defectos en los demás</span>
                { mensajesForm.id_catalogo_likert_defectos&&
                    <p className='nota_mensaje_error'>{mensajesForm.id_catalogo_likert_defectos}</p>
                    }
            </Grid>
            <Grid item xs={12} display={{ lg: "none", xs: "block"}}>
                <div className="radio-container">
                    <span className='smaller-label-radio-styling'>Completamente en desacuerdo</span>
                    <span className='smaller-label-radio-styling'>En desacuerdo</span>
                    <span className='smaller-label-radio-styling'>Ni acuerdo ni en desacuerdo</span>
                    <span className='smaller-label-radio-styling'>De acuerdo</span>
                    <span className='smaller-label-radio-styling'>Completamente de acuerdo</span>
                </div>
            </Grid>
            <Grid item xs={12} lg={6}>
                <div className="radio-container">
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_defectos === '1'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_defectos')}
                        value="1"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_defectos === '2'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_defectos')}
                        value="2"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'B' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_defectos === '3'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_defectos')}
                        value="3"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'C' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_defectos === '4'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_defectos')}
                        value="4"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'D' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_defectos === '5'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_defectos')}
                        value="5"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'E' }} />
                    </span>
                </div>
            </Grid>
            {/* 19-END */}
            {/* 20-START */}
            <Grid item xs={12} lg={6}>
                <span  className='label-styling'>...es detallista en su trabajo</span>
                { mensajesForm.id_catalogo_likert_detallista&&
                    <p className='nota_mensaje_error'>{mensajesForm.id_catalogo_likert_detallista}</p>
                    }
            </Grid>
            <Grid item xs={12} display={{ lg: "none", xs: "block"}}>
                <div className="radio-container">
                    <span className='smaller-label-radio-styling'>Completamente en desacuerdo</span>
                    <span className='smaller-label-radio-styling'>En desacuerdo</span>
                    <span className='smaller-label-radio-styling'>Ni acuerdo ni en desacuerdo</span>
                    <span className='smaller-label-radio-styling'>De acuerdo</span>
                    <span className='smaller-label-radio-styling'>Completamente de acuerdo</span>
                </div>
            </Grid>
            <Grid item xs={12} lg={6}>
                <div className="radio-container">
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_detallista === '1'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_detallista')}
                        value="1"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_detallista === '2'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_detallista')}
                        value="2"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'B' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_detallista === '3'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_detallista')}
                        value="3"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'C' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_detallista === '4'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_detallista')}
                        value="4"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'D' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_detallista === '5'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_detallista')}
                        value="5"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'E' }} />
                    </span>
                </div>
            </Grid>
            {/* 20-END */}
            {/* 21-START */}
            <Grid item xs={12} lg={6}>
                <span  className='label-styling'>...se pone nervioso(a) fácilmente</span>
                { mensajesForm.id_catalogo_likert_nervioso&&
                    <p className='nota_mensaje_error'>{mensajesForm.id_catalogo_likert_nervioso}</p>
                    }
            </Grid>
            <Grid item xs={12} display={{ lg: "none", xs: "block"}}>
                <div className="radio-container">
                    <span className='smaller-label-radio-styling'>Completamente en desacuerdo</span>
                    <span className='smaller-label-radio-styling'>En desacuerdo</span>
                    <span className='smaller-label-radio-styling'>Ni acuerdo ni en desacuerdo</span>
                    <span className='smaller-label-radio-styling'>De acuerdo</span>
                    <span className='smaller-label-radio-styling'>Completamente de acuerdo</span>
                </div>
            </Grid>
            <Grid item xs={12} lg={6}>
                <div className="radio-container">
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_nervioso === '1'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_nervioso')}
                        value="1"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_nervioso === '2'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_nervioso')}
                        value="2"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'B' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_nervioso === '3'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_nervioso')}
                        value="3"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'C' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_nervioso === '4'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_nervioso')}
                        value="4"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'D' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_nervioso === '5'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_nervioso')}
                        value="5"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'E' }} />
                    </span>
                </div>
            </Grid>
            {/* 21-END */}
            {/* 22-START */}
            <Grid item xs={12} lg={6}>
                <span  className='label-styling'>...tiene una imaginación activa</span>
                { mensajesForm.id_catalogo_likert_activo&&
                    <p className='nota_mensaje_error'>{mensajesForm.id_catalogo_likert_activo}</p>
                    }
            </Grid>
            <Grid item xs={12} display={{ lg: "none", xs: "block"}}>
                <div className="radio-container">
                    <span className='smaller-label-radio-styling'>Completamente en desacuerdo</span>
                    <span className='smaller-label-radio-styling'>En desacuerdo</span>
                    <span className='smaller-label-radio-styling'>Ni acuerdo ni en desacuerdo</span>
                    <span className='smaller-label-radio-styling'>De acuerdo</span>
                    <span className='smaller-label-radio-styling'>Completamente de acuerdo</span>
                </div>
            </Grid>
            <Grid item xs={12} lg={6}>
                <div className="radio-container">
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_activo === '1'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_activo')}
                        value="1"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_activo === '2'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_activo')}
                        value="2"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'B' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_activo === '3'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_activo')}
                        value="3"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'C' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_activo === '4'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_activo')}
                        value="4"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'D' }} />
                    </span>
                    <span className='radio-styling'>
                    <Radio
                        className='label-radio-styling'
                        checked={formData.id_catalogo_likert_activo === '5'}
                        onChange={(event)=>handleRadioSelect(event.target,'id_catalogo_likert_activo')}
                        value="5"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'E' }} />
                    </span>
                </div>
            </Grid>
            
            <Grid className="mid-top-spacing" item xs={12}>
                
            </Grid>
            <Grid item xs={12} md={12}>
                    <p>
                        Tu información será utilizada exclusivamente dentro de los fines del programa Súbete a la Nube 
                        con Google y para futuras oportunidades dirigidas al desarrollo de habilidades. <br />
                        Será tratada confidencialmente y tus datos personales no se harán públicos. <b>¿Estás de acuerdo?</b>
                    </p>
                    <Grid item xs={12} md={6}>
                        <FormControl error={mensajesForm.de_acuerdo} fullWidth>
                                <Select id="de_acuerdo"
                                    value={formData.de_acuerdo}
                                    error={mensajesForm.de_acuerdo}
                                    onChange={(event)=>{handleSelect(event.target.value,'de_acuerdo');}}
                                >
                                    { 
                                        listaCatalogos.de_acuerdo.map(data => (
                                            <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                        ))
                                    }
                                </Select>
                                { mensajesForm.de_acuerdo&&
                                    <p className='nota_mensaje_error'>{mensajesForm.de_acuerdo}</p>
                                }
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                    <p>
                        La información que nos compartas en este formulario tiene calidad de Declaración Jurada. Revísala bien
                        antes de enviarla, pues no podrás corregirla posteriormente. <b>¿Declaras bajo juramento que la 
                        información que suministrarás es veraz, y que asumes las responsabilidades y consecuencias legales
                        que correspondan en caso de falsedad, inexactitud y omisión durante el trámite de postulación al
                        programa Súbete a la Nube con Google Cloud?</b>
                    </p>
                    <Grid item xs={12} md={6}>
                        <FormControl error={mensajesForm.declaracion_jurada} fullWidth>
                                <Select id="declaracion_jurada"
                                    value={formData.declaracion_jurada}
                                    error={mensajesForm.declaracion_jurada}
                                    onChange={(event)=>{handleSelect(event.target.value,'declaracion_jurada');}}
                                >
                                    { 
                                        listaCatalogos.declaracion_jurada.map(data => (
                                            <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                        ))
                                    }
                                </Select>
                                { mensajesForm.declaracion_jurada&&
                                    <p className='nota_mensaje_error'>{mensajesForm.declaracion_jurada}</p>
                                }
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                    <p>
                        Adicionalmente, queremos entender los resultados finales del programa Súbete a la Nube con Google Cloud 
                        y si este mejoró la situación laboral de las personas. Para esto, nos gustaría poder agregar
                        registros administrativos en poder del IMSS que contengan información sobre tu historial de empleo y
                        trabajo por cuenta propia y tus ingresos. Toda la información será utilizada solamente para fines de este
                        estudio por investigadores académicos, quiénes tendrán acceso restringido que aseguren que la 
                        información se utilizará de manera responsable y segura. <b>¿Nos autoriza a pasar sus datos (CURP) para 
                        este propósito?</b>
                    </p>
                    <Grid item xs={12} md={6}>
                        <FormControl error={mensajesForm.autoriza_uso_curp} fullWidth>
                                <Select id="autoriza_uso_curp"
                                    value={formData.autoriza_uso_curp}
                                    error={mensajesForm.autoriza_uso_curp}
                                    onChange={(event)=>{handleSelect(event.target.value,'autoriza_uso_curp');}}
                                >
                                    { 
                                        listaCatalogos.autoriza_uso_curp.map(data => (
                                            <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                        ))
                                    }
                                </Select> 
                                { mensajesForm.autoriza_uso_curp&&
                                    <p className='nota_mensaje_error'>{mensajesForm.autoriza_uso_curp}</p>
                                }
                        </FormControl>
                    </Grid>
            </Grid>
            
            <br></br>
            {/* 22-END */}
            <Grid className={'text-right'} item xs={12} md={12}>
                    <a href="#">3/3</a>
            </Grid>
            {fomularioInvalido &&
            <Grid item xs={12} md={12}>
                <Alert severity="warning">Favor de revisar el formulario, existen campos vacios o invalidos.</Alert>
            </Grid>
            }
            <Grid item xs={12} md={12} className="grid-boton">
                <Button 
                    variant="contained"
                    fullWidth
                    onClick={validaForm}
                    className={"button_ovalado boton-form"}
                    style={{textTransform: 'None', margin: '0 auto', display: "flex"}}>
                        Guardar y enviar
                </Button>
            </Grid>
            <Grid item xs={12} md={12} className="grid-boton-2">
            </Grid>

        </Grid>

          <Backdrop className={classes.backdrop} open={bloqueo}>
            <CircularProgress color="inherit" />
          </Backdrop>
        
          <Modal 
                open={modalConfirmacion}
                onClose={()=>{setModalConfirmacion(false);}} 
                id="modal-form">
                <div className='form-modal'>
                    <div className='form-modal_titulo'>
                        <p>¿Todos tus datos son correctos?</p>
                    </div>
                    <div className='form-modal_texto'>
                        <p>
                            Recuerda que una vez enviado el formulario las respuestas se guardarán y no se podrá modificar
                        </p>
                    </div>
                    <div>
                        <Button     variant="contained" 
                                    className={"button_ovalado boton-form form-modal_button"} 
                                    onClick={enviaForm} 
                                    backGroundColor={'#011d60'}
                                    style={{margin: '0 auto', display: "flex"}}>Aceptar</Button>
                    </div>
                </div>
            </Modal> 
    </div>
    )
}