import React from 'react';
import RechazoContent from './RechazoContent';

export default function SeccionRechazo() {
    return(
        <>
            <RechazoContent/>
        </>
    )
};
