import React, {useEffect,useState} from 'react';
import {useLocation} from 'react-router';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import Typography from "@material-ui/core/Typography";
import iconoConfirmacion from '../../img/acciones/icono-conformacion.svg';
import iconoError from '../../img/acciones/icono-error.png'
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import { useHistory } from "react-router-dom";
import style from './CambioContra.module.css';
import Footer from '../../pages/Footer/Footer';
import Header from '../../pages/Header/Header';
import ButtonInicio from '../Button/Button';

export default function CambioContrasenia() {
    // Se obtiene valor de la url
    const {search} =  useLocation();
    const query = new URLSearchParams(search);
    const token = query.get("t");

    //Se crea history para cambiar de pagina
    const history = useHistory();

    // Se crean variables de estado
    const [cambioContra, setCambioContra] = useState(false);
    const [correo, setCorreo] = useState("");
    const [validaCorreo, setValidaCorreo] = useState("");
    const [contrasenia, setContrasenia] = useState({contra:"",confirmacion:""});
    const [validaContra, setValidaContra] = useState({contra:"",confirmacion:""});
    const [modalConfirmacion, setModalConfirmacion] = useState(false);
    const [modalError, setModalError] = useState(false);
    const [mensajeEnvioCorreo, setMensajeEnvioCorreo] = useState(true);
    
    useEffect(() => {
        if(token) {
            setCambioContra(true);
        } else {
            setCambioContra(false);
        }
    }, [token]);

    const handleContra = (event,nombreCampo) => {
        const value = event.target.value;

        if(nombreCampo === 'contra') {
            //Se evalua que los campos cumpla con caracterisca de contrasenia
            if(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(value)) {
                setValidaContra({...validaContra,contra:""});
            } else {
                setValidaContra({...validaContra,contra:"Tu contraseña no coincide con las características"});
            }
        }

        if(nombreCampo === 'confirmacion') {
            if(contrasenia.contra === value) {
                setValidaContra({...validaContra,confirmacion:""});
            } else {
                setValidaContra({...validaContra,confirmacion:"La contraseña no coincide"});
            }
        }

        setContrasenia({...contrasenia,[nombreCampo]:value});
    };

    const handleCorreo = (event) => {
        const value = event.target.value;
        if(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
            setValidaCorreo("");
        } else {
            setValidaCorreo("Por favor ingresa un correo valido");
        }

        setCorreo(value);
    };
    
    const handleEnviarCorreo = () => {

        if(!validaDatos(validaCorreo)) {
            return false;
        }

        const email = correo;
        setMensajeEnvioCorreo(true);

        axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/login/request_change_password/', {email})
        .then((response) => {
            setModalConfirmacion(true);
        })
        .catch((error) => {
            setModalError(true);
            console.error(error);
        });
    };

    const handleCambiarContra= () => {

        if(!validaDatos(validaContra)) {
            return false;
        }
        
        const password = contrasenia.contra;

        setMensajeEnvioCorreo(false);

        axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/login/change_password/'+token, {password})
        .then((response) => {
           
            setModalConfirmacion(true);
        })
        .catch((error) => {
            setModalError(true);
            console.error(error);
        });
    };

    const handleCierraModal = () => {
        setModalConfirmacion(false);
        history.push('/');
    };

    const handleCierraModalError  = () => {
        setModalError(false);
        if(!mensajeEnvioCorreo){
            history.push('/');
        }
    };

    const validaDatos = (datos) => {
        if(!datos){
            return true;
        }
        for (const nombreCampo in datos) {
            if(datos[nombreCampo]){
                return false;
            }
        }
        return true;
    }

    return(
        <>
        <Header/>
        {!cambioContra ? 
        <>
        <div className={style['card-main']}>
            <h2>¿Olvidaste tu contraseña?</h2>
            <hr />
            <div className={style['text-field']}>
                <TextField 
                    fullWidth variant="standard"
                    label="Ingresa el correo que registraste para recuperarla"
                    error={validaCorreo}
                    helperText={validaCorreo}
                    onChange={handleCorreo}
                    value={correo}
                    />
            </div>
            <div className={style['button']}>
                <ButtonInicio variant="contained" 
                    size="large" onClick={handleEnviarCorreo} backGroundColor={'#011d60'}>
                    Enviar
                </ButtonInicio>
            </div>
        </div>
        </> : 
        <>
        <div className={style['card-main']}>
            <div className={style['form-component']}>
                <h2>Cambia tu contraseña</h2>
                <hr />
                <h3>Ingresa nueva contraseña:</h3>
                <div className={style['text-field']}>
                    <TextField 
                        error={validaContra.contra ? true : false}
                        helperText={validaContra.contra}
                        onChange={(event) => handleContra(event,'contra')}
                        variant="outlined"
                        type={'password'}
                        value={contrasenia.contra}
                        />
                </div>
            </div>
            <div className={style['form-component']}>
                <h3>Confirma nueva contreseña:</h3>
                <div className={style['text-field']}>
                    <TextField  
                        error={validaContra.confirmacion ? true : false}
                        helperText={validaContra.confirmacion}
                        onChange={(event) => handleContra(event,'confirmacion')}
                        variant="outlined"
                        type={'password'}
                        value={contrasenia.confirmacion}
                        />
                </div>
            </div>
            <div className={style['form-component']}>
                <h4>Recuerda, tu contraseña debe de tener las siguientes características:</h4>
                <p>
                    Longitud mínima de 8, al menos:<br/>
                    Una mayúscula<br/>
                    Una minúscula<br/>
                    Un dígito<br/>
                    Un símbolo
                </p>
            </div>
            <div className={style['button']}>
                <Button 
                    onClick={handleCambiarContra}
                    variant="contained" 
                    size="large"
                >Confirmar</Button>
            </div>
        </div>
        </> }
        <Footer/>
        <Modal
        open={modalConfirmacion}
        onClose={handleCierraModal}
        >

            <Stack spacing={5} alignItems="center" style={{marginTop: "5%"}} id="modal-confirmacion" >
            {mensajeEnvioCorreo ?
            <>
             <Typography variant="h5" color="text.secondary">
                Enviamos un correo para realizar tu cambio de contraseña
                </Typography>
                <Typography variant="h6" color="text.secondary">
                            (sistemas@inroads.org.mx)
                </Typography>
                <Avatar sx={{ minWidth: 100 , minHeight: 100 }} variant="square" src={iconoConfirmacion}>
                Confirmado
                </Avatar>
                <Typography variant="h6" color="text.secondary">
                Te recomendamos revisar tu bandeja de spam
                </Typography>
                <br></br>
            </> : 
            <>
                <Typography variant="h5" color="text.secondary">
                    Se ha actualizado tu contraseña
                </Typography>
                <Avatar sx={{ minWidth: 100 , minHeight: 100 }} variant="square" src={iconoConfirmacion}>
                    Confirmado
                </Avatar>
                <br></br> 
            </>
            }
           
            </Stack>
            
        </Modal>
        <Modal
        open={modalError}
        onClose={handleCierraModalError}
        >

            <Stack spacing={5} alignItems="center" style={{marginTop: "5%"}} id="modal-confirmacion" >
            {mensajeEnvioCorreo ?
            <>
             <Typography variant="h5" color="text.secondary">
                No se encontró el correo registrado.
                </Typography>
                <Avatar sx={{ minWidth: 100 , minHeight: 100 }} variant="square" src={iconoError}>
                Confirmado
                </Avatar>
                <br></br>
            </> : 
            <>
                <Typography variant="h5" color="text.secondary">
                    Token invaldo. Solicita cambio de contraseña nuevamente.
                </Typography>
                <Avatar sx={{ minWidth: 100 , minHeight: 100 }} variant="square" src={iconoError}>
                    Confirmado
                </Avatar>
                <br></br> 
            </>
            }
            </Stack>
        </Modal>
        </>
    );
}