import React from 'react';
import styles from './CardClass.module.css';
import VideoIco from '../../img/acciones/video_boton.svg';
import moment from 'moment';

export default function CardClass({classTec}) {
    return(
        <div className={styles['container-main']}>
            <div className={styles.container}>
                <div >
                    <p className={styles.text}>
                        {classTec.nombre_clase}
                    </p>
                </div>
            </div>
            <div className={styles.footer}>
                <a href={classTec.link_clase_grabada} target="_blank" rel="noreferrer"><img src={VideoIco} alt="play" width={30}/></a>
            </div>
        </div>
    );
};
