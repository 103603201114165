import React from 'react';
import style from './rechazo.module.css';

export default function RechazoContent() {
    return(
        <div className='container-margin'>
            <div className={style.text + ' margin-top-md'}>
                <h2 className={style.title1}>
                    Súbete a la nube con
                </h2>
                <h2 className={style.title2}>
                    Google Cloud
                </h2>
            </div>
            
            <div className={style.text + ' margin-top-md'}>
                <p>
                    Sentimos comunicarte que en esta ocasión no es posible que participes en esta convocatoria, debido a que no cumples  <br />
                    con alguno de los siguientes requisitos: <br /><br />
                    
                        • Estudiar bachillerato tecnológico o universidad <br />
                        • Ser mayor de edad <br />
                        • Disponibilidad de tiempo para tomar las clases online <br />
                        • 5 a 10 horas de autoestudio a la semana en  <br />
                        • Acceso a Internet y a computadora (PC/Laptop) <br /><br />
                   
                    ¡Vamos, no decistas! <br /> <br />

                    Si tienes alguna duda o necesitas que revisemos tu caso  escríbenos a soporte y ayuda: <br />
                    WhatsApp: 8134715109 o correo electrónico: subete.nube.google@inroads.org.mx <br />
                </p>

                <p className={style['text-bold']}>
                    ¡Visualiza, avanza, crece y marca la diferencia!
                </p>
                <p >
                    INROADS de México, A.C.
                </p>
            </div>
        </div>
    );
};
