import React from 'react';
import style from './CardColor.module.css';

export default function CardColor({backgroundColor, img, title, children}) {
    return(
        <div className={style.container} style={{backgroundColor}}>
            <div className={style.img}>
                <img src={img} alt="" />
            </div>
            <div className={style.title}>
                {title}
            </div>
            <div className={style.text}>
                {children}
            </div>
        </div>
    );
};
