import React, {useEffect} from 'react';
import Header from '../Header/Header';
import HeaderImg from '../HeaderImg/HeaderImg';
import Intro from '../Intro/Intro';
import RequiredInfo from '../RequiredInfo/RequiredInfo';
import StepFuntion from '../StepFunction/StepFuntion';
import ApplyHere from '../ApplyHere/ApplyHere';
import Testimonials from '../Testimonials/Testimonials';
import AskFreq from '../AskFreqOld/AskFreq';
import Associations from '../Associations/Associations';
import Footer from '../Footer/Footer';
import { useHistory } from "react-router-dom";

export default function Home() {

    const history = useHistory();

    useEffect(() => {
        let isSigned = sessionStorage.getItem('isSigned');
        let adminSession = sessionStorage.getItem('Admin');
        if(isSigned && !adminSession){
            history.push('/homeIns');
        }

    }, []);

    return(
        <>
            <Header/>
            <HeaderImg headerEstatus={0}/>
            <Intro/>
            <RequiredInfo/>
            <StepFuntion/>
            <ApplyHere/>
            <Testimonials/>
            <AskFreq/>
            <Associations/>
            <Footer/>
        </>
    )
};
