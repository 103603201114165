import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal } from 'react-responsive-modal';
import "./adminStyle.css";
import Grid from '@mui/material/Grid';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function SeccionAssignBeca() {

    const [oleada, setOleada] = useState(0);

    const [listOleada, setlistOleada] = useState([]);

    const [activaBoton, setActivaBoton] = useState(false);

    const [filters, setFilters] = useState({
        filter_mujer:false,
        filter_hombre:false,
        filter_no_binario:false,
        filter_prioridad_1:false,
        filter_prioridad_2:false,
        filter_prioridad_3:false,
        filter_umbral_calificacion:null,
        filter_total_aprobar:null
    });

    const [filtersMessage, setFiltersMessage] = useState({
        filter_umbral_calificacion:"",
        filter_total_aprobar:""
    });

    const [listaPreAprobacion, setListaPreAprobacion] = useState([]);
    const [listEspera, setListEspera] = useState([]);

    const [versionNumber, setVersionNumber] = useState(0);

    const [cifras, setCifras] = useState({
        total_lista_espera:0,
        total_pre_aprobados:0
    });

    const [refrescar, setRefrescar] = useState(false);

    const [cargando, setCargando] = useState(false);

    const regexEntero = /^[0-9]+$/;

    const mensajeRequerido = "Campo requerido";
    const mesajeDatoEntero = "El dato debe de ser númerico";
    const mensajeUmbralMaximo = "El umbral debe tener un valor máximo de 100";
    const mensajeTotalMinimo = "El valor del total debe ser mayor a 0 o en su defecto dejarlo vacío para obtener todos los postulantes para aprobar"

    const filtersMetaData = {
        filter_umbral_calificacion:{regex:regexEntero, requerido:true, mensajeRegex:mesajeDatoEntero, maxValue:100},
        filter_total_aprobar:{regex:regexEntero, requerido:false, mensajeRegex:mesajeDatoEntero, minValue:0}
    };

    const [modal, setModal] = useState(false);
    const [mensaje, setMensaje] = useState({
        mostrarMensaje: false,
        textoMensaje:"Se actualizo de manera correcta",
        tipoMensaje:"success"
    });



    useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/admin/oleadas_read/',
        { headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}})
        .then((response) => {
            setlistOleada(response.data.oleadas);
        })
        .catch((error) => {
            console.error(error);
        });
    }, [refrescar]);

    // Estilos para tabla
    const StyledTableCell = styled(TableCell)(({backgroundColor }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor,
            fontSize: 17,
            color: '#fff',
            padding: '25px 0px',
            border: 'solid 1px black',
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 16,
            padding: '25px 0px',
            border: 'solid 1px black',
        },
        [`&.${tableCellClasses.footer}`]: {
            fontSize: 17,
            padding: '25px 0px',
            backgroundColor:'#ccc',
        },
    }));
    
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        
    }));

    const TablaCalificaciones = ({colorHead, data, dataFooter}) => (
        <>
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 600 }}>
                <TableHead>
                    <TableRow>
                        <StyledTableCell backgroundColor={colorHead} align="center">CALIFICACIÓN</StyledTableCell>
                        <StyledTableCell backgroundColor={colorHead} align="center">Total de participantes</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {data && data.map((row) => (
                    <StyledTableRow key={row.calificacion} >
                        <StyledTableCell align="center">{row.calificacion}</StyledTableCell>
                        <StyledTableCell align="center">{row.personas}</StyledTableCell>
                    </StyledTableRow>
                ))}
                </TableBody>
                <TableFooter>
                    <StyledTableRow>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell align="left">Total: {dataFooter}</StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                    </StyledTableRow>
                </TableFooter>
            </Table>
        </TableContainer>
        </>
    );
    // -- Fin de estilos para tabla


    const handleChangueMensajeValidacion = (event) => {
        const textoValida = event.target.value;
        if(textoValida === "Si, acepto") {
            setActivaBoton(true);
        } else {
            setActivaBoton(false);
        }
    }

    const ModalConfirmacion = () => (
        <Modal open={modal} onClose={()=>setModal(false)} >
            <DialogContent>
                <DialogContentText >
                    <div className='dialog'>
                        <h2>¡Alerta!</h2>
                        <div>
                            <p>¿Estás seguro en APROBAR a este bloque de personas? <br />
                            Dicha acción no séra reversible.</p>
                        </div>
                        <div>
                            Escribe el mensaje de confirmación <p></p>
                            <TextField id="mensajeValidacion" 
                                variant="outlined"
                                placeholder="Si, acepto"
                                onChange={handleChangueMensajeValidacion}
                            />
                            <div className='dialog-button form-button-background-morado'>
                                <Button 
                                    variant="contained" 
                                    size="large"
                                    disabled={!activaBoton}
                                    onClick={aprobarBecas}
                                >Aceptar</Button>
                            </div>
                        </div>
                    </div>
                </DialogContentText>
            </DialogContent>
        </Modal>
    );

    const limpiarDatosTabla = () => {
        setListaPreAprobacion([]);
        setListEspera([]);
        setCifras({
            total_lista_espera:0,
            total_pre_aprobados:0
        });
    }

    const handleChangeFilter = (event) => {
        setFilters({ ...filters, [event.target.name]: event.target.checked });
        limpiarDatosTabla();
    };

    const handleChangue = (event,nombreParametro) => {
        const value = event.target.value;
        limpiarDatosTabla();
        setFilters({...filters,[nombreParametro]:value});
        validaDato(value,nombreParametro);
    };

    const validaDato = (value,nombreParametro) => {
        //Depende del tipo de validación que tenga es el que se va a realizar
        //Si es requerido
        if(filtersMetaData[nombreParametro].requerido&&!value) {
            setFiltersMessage({...filtersMessage, [nombreParametro]:mensajeRequerido});
            return false;
        }

        //Si tiene un regex para validar tipo de estructura de dato
        if(filtersMetaData[nombreParametro].regex&&!filtersMetaData[nombreParametro].regex.test(value)&&value) {
            setFiltersMessage({...filtersMessage,[nombreParametro]:filtersMetaData[nombreParametro].mensajeRegex});
          
            return false;
        }

        //Se valida el maximo 
        if(filtersMetaData[nombreParametro].maxValue && value > filtersMetaData[nombreParametro].maxValue) {
            setFiltersMessage({...filtersMessage,[nombreParametro]:mensajeUmbralMaximo});
            return false;
        }

        //Se valida el minimo
        if(filtersMetaData[nombreParametro].minValue === 0 && parseInt(value) === filtersMetaData[nombreParametro].minValue) {
            setFiltersMessage({...filtersMessage,[nombreParametro]:mensajeTotalMinimo});
            return false;
        }

        //Se limpia mensaje
        setFiltersMessage({...filtersMessage, [nombreParametro]:""});
        return true;
    };

    const generarPreAprobacion = () => {

        //Se valida los datos 
        if(!validaDato(filters['filter_umbral_calificacion'],'filter_umbral_calificacion') ||
            !validaDato(filters['filter_total_aprobar'],'filter_total_aprobar')) {
            return false;
        }

        //Se convierten a string los filtros de checks
        let paramString= {};
        for(const element in filters) {
            paramString = {...paramString,[element]:filters[element].toString()}
        }

        //Se acompleta información para obtener 
        const params = {id_oleada:oleada,...paramString,
            filter_umbral_calificacion:parseInt(filters.filter_umbral_calificacion),
            filter_total_aprobar:parseInt(filters.filter_total_aprobar)
        };

        //en caso de que no se emvie el total se remueve
        if(!filters.filter_total_aprobar) {
            delete params.filter_total_aprobar;
        }

        setCargando(true);
        axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/admin/pre-aprobar/',params,
        {
            headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}
        })
        .then(res => {
            setListaPreAprobacion(res.data.summary.pre_aprobados);
            setListEspera(res.data.summary.lista_espera);
            setVersionNumber(res.data.summary.number_version);
            setCifras(res.data.summary.cifras);
            setCargando(false);
        })
        .catch(error => {
            console.error(error)
            setCargando(false);
        });
    };

    const limpiarDatos = () => {
        setFilters({
            filter_mujer:false,
            filter_hombre:false,
            filter_no_binario:false,
            filter_prioridad_1:false,
            filter_prioridad_2:false,
            filter_prioridad_3:false,
            filter_umbral_calificacion:0,
            filter_total_aprobar:0
        });
        setOleada(0);
        setlistOleada([]);
        setListaPreAprobacion([]);
        setListEspera([]);
        setCifras({
            total_lista_espera:0,
            total_pre_aprobados:0
        });
        setVersionNumber(0);
        setActivaBoton(false);
        setModal(false);
        setRefrescar(!refrescar);
    }

    const aprobarBecas = () => {

        const params = {id_oleada:oleada,
            filter_total_aprobar:parseInt(filters.filter_total_aprobar),
            filter_umbral_calificacion:parseInt(filters.filter_umbral_calificacion),
            number_version: parseInt(versionNumber)};

        axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/admin/aprobar/',params,
        {
            headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}
        })
        .then(res => {
            setMensaje({
                mostrarMensaje:true,
                textoMensaje:"Se aprobó correctamente",
                tipoMensaje:"success"
            });
            limpiarDatos();
        })
        .catch(error => {
            setMensaje({
                mostrarMensaje:true,
                tipoMensaje:"error",
                textoMensaje:"Error al aprobar"
            });
            console.error(error)
        });
    };

    return(
        <>
        <ModalConfirmacion />     
        <div>
            <h2>Aprobación de becas</h2>
            <br />
            <Grid container rowSpacing={4} columnSpacing={8}>
                <Grid item xs={12} md={12}>
                    <span className='nota_asterisco'>*</span><span className='nota'> Selecciona la ola:</span>
                    <br />
                    <Select
                        className='select_admin'
                        id="idOleada"
                        onChange={(event)=>{setOleada(event.target.value)}}
                        value={oleada}
                    >
                        {
                            listOleada && 
                            listOleada.map( oleada => (
                                <MenuItem key={oleada.id_oleada} value={oleada.id_oleada}>{oleada.id_oleada} ( {oleada.nombre} )</MenuItem>
                            ))
                        }
                    </Select>
                </Grid>
                <Grid item xs={12} md={12}>
                    <label>Género:</label>
                    <FormGroup row>
                        <FormControlLabel
                                control={
                                <Checkbox
                                    checked={filters.filter_mujer}
                                    onChange={handleChangeFilter}
                                    name="filter_mujer"
                                    color="primary"
                                />
                                }
                                label="Femenino"
                        />
                        <FormControlLabel
                                control={
                                <Checkbox
                                    checked={filters.filter_hombre}
                                    onChange={handleChangeFilter}
                                    name="filter_hombre"
                                    color="primary"
                                />
                                }
                                label="Masculino"
                        />
                        <FormControlLabel
                                control={
                                <Checkbox
                                    checked={filters.filter_no_binario}
                                    onChange={handleChangeFilter}
                                    name="filter_no_binario"
                                    color="primary"
                                />
                                }
                                label="No binario"
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={12}>
                    <label>Estados de prioridad:</label>
                    <FormGroup row>
                        <FormControlLabel
                                control={
                                <Checkbox
                                    checked={filters.filter_prioridad_1}
                                    onChange={handleChangeFilter}
                                    name="filter_prioridad_1"
                                    color="primary"
                                />
                                }
                                label="Prioridad 1"
                        />
                        <FormControlLabel
                                control={
                                <Checkbox
                                    checked={filters.filter_prioridad_2}
                                    onChange={handleChangeFilter}
                                    name="filter_prioridad_2"
                                    color="primary"
                                />
                                }
                                label="Prioridad 2"
                        />
                        <FormControlLabel
                                control={
                                <Checkbox
                                    checked={filters.filter_prioridad_3}
                                    onChange={handleChangeFilter}
                                    name="filter_prioridad_3"
                                    color="primary"
                                />
                                }
                                label="Prioridad 3"
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField 
                    variant="outlined"
                    error={filtersMessage.filter_umbral_calificacion}
                    label="Umbral de calificación:"
                    helperText={filtersMessage.filter_umbral_calificacion}
                    inputProps={{ maxLength: 3 }}
                    onChange={(event)=>handleChangue(event,'filter_umbral_calificacion')}
                    value={filters.filter_umbral_calificacion}/>
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField 
                    variant="outlined"
                    error={filtersMessage.filter_total_aprobar}
                    label="Total por aprobar:"
                    helperText={filtersMessage.filter_total_aprobar}
                    inputProps={{ maxLength: 5 }}
                    onChange={(event)=>handleChangue(event,'filter_total_aprobar')}
                    value={filters.filter_total_aprobar}/>
                </Grid>
             </Grid>
             <div className='form-button form-button-background-azul'>
                <Button variant="contained" 
                size="small" 
                onClick={generarPreAprobacion}
                disabled={!oleada}
                >Generar {cargando && <>&nbsp;&nbsp;<CircularProgress/></>}</Button>
            </div>
            <div className="form-table">
                <h3>Por abrobar:</h3>
                <TablaCalificaciones colorHead={'#4e2378'} data={listaPreAprobacion} dataFooter={cifras.total_pre_aprobados}/>
                <div className='form-button form-button-background-morado'>
                    <Button variant="contained" size="large" disabled={!(listaPreAprobacion.length > 0)}
                    onClick={()=>setModal(true)}>Aprobar</Button>
                </div>
            </div>
            <div className="form-table">
                <h3>LISTA DE ESPERA:</h3>
                <TablaCalificaciones colorHead={'#d37913'} data={listEspera} dataFooter={cifras.total_lista_espera}/>
            </div>
        </div>
        <Snackbar
            open={mensaje.mostrarMensaje}
            autoHideDuration={3000}
            onClose={()=>setMensaje({...mensaje,mostrarMensaje:false})}
        >
            <MuiAlert elevation={6} variant="filled" severity={mensaje.tipoMensaje}> {mensaje.textoMensaje} </MuiAlert>
        </Snackbar>
        </>
    )
};
