import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import SeccionCalificarForm from './SeccionCalificarForm';
import SeccionAssignBeca from './SeccionAssignBeca';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    
    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`nav-tabpanel-${index}`}
        aria-labelledby={`nav-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box p={3}>
            <Typography>{children}</Typography>
            </Box>
        )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `nav-tab-${index}`,
        'aria-controls': `nav-tabpanel-${index}`,
    };
}

function LinkTab(props) {
    return (
        <Tab
        component="a"
        onClick={(event) => {
            event.preventDefault();
        }}
        {...props}
        />
    );
}

export default function AssignBeca() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

  return (
    <>
        <Header/>

        <div className='card-main'>
            <Tabs
            variant="fullWidth"
            value={value}
            indicatorColor='primary'
            onChange={handleChange}
            aria-label="nav tabs example"
            >
                <LinkTab label="Calificar"  {...a11yProps(0)} />
                <LinkTab label="Aprobación de becas"  {...a11yProps(1)} />
            </Tabs>

            <TabPanel value={value} index={0}>
                <SeccionCalificarForm/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <SeccionAssignBeca/>
            </TabPanel>
        </div>

        <Footer/>
    </>
);

}
