import Header from '../Header/Header';
import Footer from '../Footer/Footer';

export default function Admin() {
  
  return (
    <>
        <Header/>
        <div className='content'>
          Página de inicio de administración.
        </div>
        <Footer/>
    </>
);

}

