import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import SeccionRegOleada from '../SeccionRegOleada/SeccionRegOleada';
import SeccionRegClass from '../SeccionRegClass/SeccionRegClass';
import SeccionRelOleadasClass from '../SeccionRelOleadasClass/SeccionRelOleadasClass';
import SeccionModInsign from '../SeccionModInsign/SeccionModInsign';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    
    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`nav-tabpanel-${index}`}
        aria-labelledby={`nav-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box p={3}>
            <Typography>{children}</Typography>
            </Box>
        )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `nav-tab-${index}`,
        'aria-controls': `nav-tabpanel-${index}`,
    };
}
  
function LinkTab(props) {
    return (
        <Tab
        component="a"
        onClick={(event) => {
            event.preventDefault();
        }}
        {...props}
        />
    );
}


export default function RegClassOleadas() {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
  
    return(
        <>
            <Header/>
            <div id="seccion-completa" className='card-main'>
                <Tabs
                variant="fullWidth"
                value={value}
                indicatorColor='primary'
                onChange={handleChange}
                aria-label="nav tabs example"
                >
                    <LinkTab label="Registro Oleada"  {...a11yProps(0)} />
                    <LinkTab label="Registro Clases"  {...a11yProps(1)} />
                    <LinkTab label="Relación oleadas y clases"  {...a11yProps(2)} />
                    <LinkTab label="Modificación insignias"  {...a11yProps(3)} />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <SeccionRegOleada/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <SeccionRegClass/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <SeccionRelOleadasClass/>
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <SeccionModInsign/>
                </TabPanel>
            </div>
            <Footer/>
        </>
    );
};

