import React from 'react';
import '../../css/accordion.css'
import style from './AskFreq.module.css';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TreeView from '@mui/lab/TreeView';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      marginTop:'15px',
    },
    heading: {
      fontSize: theme.typography.pxToRem(17),
      fontWeight: theme.typography.fontWeightMedium,
    },
}));

export default function AskFreq() {
  const classes = useStyles();

  return(
      <div className='margin-top-md'>
        <div className={style.title + ' container-margin'}>
          Preguntas Frecuentes
        </div>
        <div className={classes.root+' acordion-root'}>
          <Accordion className='acordion-container'>
            <AccordionSummary
              className='acordion-item-ask'
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
                <Typography className={classes.heading}>
                    ¿En qué consiste el programa?
                </Typography>
            </AccordionSummary>
            <AccordionDetails className='acordion-item-detail'>
              <Typography>
              <TreeView className="seccion_preguntas"
                aria-label="file system navigator" defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />} sx={{ flexGrow: 1 }}>
                  <TreeItem nodeId="3" label="¿El programa de Súbete a la Nube con Google Cloud?">
                    <p>
                    Es un programa de aprendizaje que te permitirá adentrarte y conocer el mundo de la Nube de Google, realizando laboratorios prácticos para obtener insignias y demostrar tus habilidades adquiridas. En una modalidad online que te permitirá avanzar y obtener estas competencias profesionales.
                    Adicionalmente, tendrás la oportunidad de accesar a clases sincrónicas con los expertos de Google Cloud.
                    </p>
                  </TreeItem>
                  <TreeItem nodeId="4" label="¿En que consiste el programa de Súbete a la Nube con Google Cloud ?">
                    <p>
                    En el Programa Google Cloud 2023, se ofrecerán una serie de recursos para mejorar 
                    tus habilidades personales y profesionales como:
                    <br />
                    Acceso a 3 contenidos exclusivos con expertos de Google Cloud Computing Foundations en 
                    (Infraestructura, Networking & Security, Data, ML y AI). <br />
                    3 clases en vivo online de habilidades blandas: Un día trabajando en la nube, 
                    Administración del tiempo y planificación, Creación de un C.V. exitoso y Entrevistas exitosas.<br />
                    1 Masterclass en vivo online con Googlers.<br />
                    Los alumnos deben obtener 4 insignias:<br />
                    Google Cloud Computing Foundations en: Cloud Computing Fundamentals, 
                    Infraestructura en Google Cloud, Networking & Security en Google Cloud, Data, ML y AI en Google Cloud.<br /><br />
                    Los alumnos recibirán un link para canjear 200 créditos (equivalentes a $200 dólares) 
                    para realizar las insignias sin costo para los participantes.
                    </p>
                  </TreeItem>
              </TreeView>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className='acordion-container'>
            <AccordionSummary
              className='acordion-item-ask'
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
                <Typography className={classes.heading}>
                    ¿Cómo me registro?
                </Typography>
            </AccordionSummary>
            <AccordionDetails className='acordion-item-detail'>
              <Typography>
              <TreeView className="seccion_preguntas"
                aria-label="file system navigator" defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />} sx={{ flexGrow: 1 }}>
                  <TreeItem nodeId="3" style={{color:'#000'}} label="¿Quién puede participar en el programa de Súbete a la Nube con Google Cloud?">
                    <p>
                    - Estudiantes de bachillerato tecnológico o universidad.<br />
                    - Ser mayor de edad.<br />
                    - Disponibilidad de tiempo para tomar clases online.<br />
                    - 10 a 15 horas de autoestudio a la semana en plataforma.<br />
                    - Acceso a Internet y a computadora (PC/Laptop).<br />
                    - Con ganas de crecer, comprometidos con su desarrollo personal y profesional.<br />
                    </p>
                  </TreeItem>
                  <TreeItem nodeId="4" label="¿Cómo me puedo inscribir al programa de Súbete a la Nube con Google Cloud?">
                    <p>
                    Deberás consultar los requisitos en la convocatoria (<a href="https://subetealanube.inroads.org.mx/">https://subetealanube.inroads.org.mx/</a>). 
                    Si cumples con los requisitos, regístrate y llena el formulario. Finalmente, si fuiste aceptado, 
                    te llegará un correo de confirmación en un plazo de 1 a 8 días.
                    </p>
                  </TreeItem>
                  <TreeItem nodeId="5" label="¿Qué pasa una vez que me inscribo?">
                    <p>
                    Te llegará un correo, en donde se te indicará si fuiste seleccionado o no. Si fuiste seleccionadodeberás ingresar a la página de INROADS e iniciar sesión con el usuario y contraseña que registraste dentro de la plataforma.
                    </p>
                  </TreeItem>
                  <TreeItem nodeId="6" label="No me llega la confirmación para la participación al programa, ¿Me han admitido?">
                    <p>
                      Si no recibiste el correo electrónico con tu confirmación o, por el contrario, lo recibiste pero no lo encuentras, comprueba en la pestaña "Promociones" de tu cuenta de Gmail si tienes un correo electrónico de Google. Si no lo encuentras ahí, revisa la pestaña de "Spam".
                    </p>
                  </TreeItem>
              </TreeView>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className='acordion-container'>
            <AccordionSummary
              className='acordion-item-ask'
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
                <Typography className={classes.heading}>
                    ¿Qué beneficios obtengo?
                </Typography>
            </AccordionSummary>
            <AccordionDetails className='acordion-item-detail'>
              <Typography>
              <TreeView className="seccion_preguntas"
              aria-label="file system navigator" defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />} sx={{ flexGrow: 1 }}>
                <TreeItem nodeId="3" label="Beneficios:">
                    <p>
                    - Obtendrás <b>insignias</b> que tienen validez curricular, un <b>diploma</b> de finalización de Google con el 
                      cuál podrás actualizar tu perfil en LinkedIn y tu C.V.<br />
                    - Conocimientos de cómo crear un C.V. atractivo y cómo tener una entrevista exitosa, 
                      administración efectiva del tiempo y planeación.<br />
                    - <b>Masterclass en vivo online por un experto de Google</b>, acceso a contenido de Google Cloud<br />
                    - Los participantes que obtengan las 4 insignias y concluyan el programa de manera éxitosa podrán aspirar a:<br />
                    - realizar el Associate Cloud Engineer Learning Path ( beca de acceso por 6 meses y posibilidad de aplicar a un voucher del 50% descuento para realizar el examen de certificación)<br />
                    - Posibilidad de <b>aplicar</b> al <b>Pool de talento de INROADS</b><br />
                    </p>
                </TreeItem>
                <TreeItem nodeId="4" label="¿Qué es una insignia?">
                    <p>
                      Una insignia es un <b>reconocimiento</b> digital que recibirás cuando completes correctamente un labs de Google. La cuál podrás incluir a tu C.V. y perfil de LinkedIn. Volviéndolo <b>más atractivo.</b>
                    </p>
                </TreeItem>
                <TreeItem nodeId="5" label="¿Cómo se consiguen?">
                    <p>
                      Obtendrás una insignia cuando completes todas las lecciones de un tema y apruebes la evaluación final del tema en cuestión.
                    </p>
                </TreeItem>
                <TreeItem nodeId="6" label="¿Qué hago si estoy realizando el lab como debería, pero no se registra que logré la insignia?">
                    <p>
                      En la parte superior derecha da click sobre el símbolo de “?” y se te deplegará el chat con Soporte Técnico; Coméntales esta situación y ellos te ayudarán a resolverla.
                    </p>
                </TreeItem>
                <TreeItem nodeId="7" label="Más dudas…">
                    <p>
                    Si tienes alguna duda sobre esta formación que no se haya resuelto aquí, escríbenos un correo electrónico a subete.nube.google@inroads.org.mx indicando en el asunto la formación a la que se refiere, la cuenta de
                    correo electrónico con la que te has registrado (incluyendo mayúsculas y minúsculas), tu consulta en el cuerpo del correo electrónico y capturas de pantalla con el problema.
                    </p>
                </TreeItem>
              </TreeView>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
  )
};