import React from 'react';
import EsperaContent from './EsperaContent';

export default function SeccionEspera() {
    return(
        <>
            <EsperaContent/>
        </>
    )
};
