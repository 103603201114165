import React from 'react';
import styles from './ProgressInsign.module.css';
import CardInsign from '../../components/CardInsign/CardInsign';

export default function ProgressInsign({listInsign}) {
    return(
        <div className={styles.container+' container-margin'}>
            <div className={styles.title}>
                <p>Recuerda que debes obtener las siguientes insignias:</p>
            </div>
            <div className={styles['container-cards']}>
                {
                    listInsign&&listInsign.map( insign => (
                        <CardInsign key={insign.id}
                            finish={insign.complete}
                            date={insign.fecha_maxima}
                        >
                            <img src={insign.url} alt={insign.nombre_badge} width={186} height={190}/>
                        </CardInsign>
                    ))
                }
            </div>
        </div>
    );
};
