import React from 'react';
import Header from '../Header/Header';
import HeaderImg from '../HeaderImg/HeaderImg';
import Testimonials from '../Testimonials/Testimonials';
import AskFreq from '../AskFreq/AskFreq';
import Associations from '../Associations/Associations';
import Footer from '../Footer/Footer';
import StepFuntion from '../StepFunction/StepFuntion';
import Progress from '../Progress/Progress';

export default function HomeMain() {
    return(
        <>
            <HeaderImg headerEstatus={2}/>
            <Progress/>
            <StepFuntion main={true}/>
            <Testimonials/>
            <AskFreq/>
            <Associations/>
        </>
    );
};
