import React, { useState, useEffect } from 'react';
import styles from './FormITwo.module.css';
import axios from 'axios';
import {DropzoneDialog} from 'material-ui-dropzone'
import Grid from '@mui/material/Grid';
import TextField from "@mui/material/TextField";
import Slider from "@mui/material/Slider";
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { Modal } from 'react-responsive-modal';
import MenuItem from '@mui/material/MenuItem';
import Button from '../../components/Button/Button';
import Alert from '@mui/material/Alert';
import { FormHelperText } from '@mui/material';

export default function FormTwo({updateEstatusModulo}) {

    //Valor que toma la opción de otro en todos los combos
    
    const [formData, setFormData] = useState({
        id_catalogo_nivel_ingeles:50,
        id_catalogo_grupo_poblacional:null,
        otra_poblacion:"",
        id_catalogo_razon_para_tomar_programa:null,
        otra_razon_para_tomar_programa: "",
        id_catalogo_tiempo_a_dedicar_diez_horas:null,
        id_catalogo_ingreso_mensual_familia:null,
        id_catalogo_ingreso_familiar_gobierno:null,
        id_catalogo_hogar_propio:null,
        id_catalogo_difusion:null,
        otra_difusion:"",
        id_catalogo_acceso_computadora:null,
        id_catalogo_computadora_exclusiva:null,
        id_catalogo_acceso_internet:null,
        id_catalogo_internet_casa:null,
        id_catalogo_con_smartphone:null,
        id_catalogo_espacio_exclusivo:null,
        id_catalogo_cursos_en_linea_anteriores:null,
        id_catalogo_ultimo_resultado_en_linea:null,
        id_catalogo_conoce_skill_badges:null,
        id_catalogo_conoce_google_cloud_skill:null,
        id_catalogo_cuenta_con_google_skill:null,
        id_catalogo_probabilidad_empleo:50,
        id_catalogo_probabilidad_ingreso_mayor:50
    });

    const [mensajesForm, setMensajesForm] = useState({
        id_catalogo_nivel_ingeles:"",
        id_catalogo_grupo_poblacional:"",
        otra_poblacion:"",
        id_catalogo_razon_para_tomar_programa:"",
        otra_razon_para_tomar_programa: "",
        id_catalogo_tiempo_a_dedicar_diez_horas:"",
        id_catalogo_ingreso_mensual_familia:"",
        id_catalogo_ingreso_familiar_gobierno:"",
        id_catalogo_hogar_propio:"",
        id_catalogo_difusion:"",
        otra_difusion:"",
        id_catalogo_acceso_computadora:"",
        id_catalogo_computadora_exclusiva:"",
        id_catalogo_acceso_internet:"",
        id_catalogo_internet_casa:"",
        id_catalogo_con_smartphone:"",
        id_catalogo_espacio_exclusivo:"",
        id_catalogo_cursos_en_linea_anteriores:"",
        id_catalogo_ultimo_resultado_en_linea:"",
        id_catalogo_conoce_skill_badges:"",
        id_catalogo_conoce_google_cloud_skill:"",
        id_catalogo_cuenta_con_google_skill:"",
        id_catalogo_probabilidad_empleo:"",
        id_catalogo_probabilidad_ingreso_mayor:""
    });

    let auxMensajesForm = {
    };

    const [listaCatalogos, setListaCatalogos] = useState({
        acceso_internet:[],
        apoyo_gobierno:[],
        computadora:[],
        computadora_esclusiva:[],
        cuentas_con_google_cloud_skill_badges:[],
        cursos_en_linea:[],
        difusion:[],
        espacio_esclusivo:[],
        google_cloud_skill_badges:[],
        ingles:[],
        ingreso_mensual_familia:[],
        internet_casa:[],
        poblacion:[],
        probabilidad_empleo:[],
        probabilidad_ingreso_mayor:[],
        razon_programa:[],
        skill_badges:[],
        telefono_inteligente:[],
        tiempo_a_dedicar_diez_horas:[],
        ultimo_resultado:[],
        vivienda_propia:[],
    });

    const [preClaveCorrecta] = useState(true);
    const [dialogoIdentificacion, setDialogoIdentificacion] = useState(false);
    const [dialogoComprobante, setDialogoComprobante] = useState(false);

    const [formInvalido, setFormInvalido] = useState(false);

    const [checksAviso, setChecksAviso] = useState({
        datosPersonalesOrganizacion:false,
        informacionProporcionada:false,
        cumplimientoTiempos:false,
        tratamientoDatos:false,
        avisoPrivacidad:false
    });

    const [checksCompletos, setChecksCompletos] = useState(false);
    const [modalConfirmacion, setModalConfirmacion] = useState(false);

    const [nombreArchivos, setNombreArchivos] = useState({
        nombreIdenticacion:"",
        nombreComprobante:""
    });

    const regexEntero = /^[0-9]+$/;
    const regexOnlyLetters = /^[a-zA-Z\s]+$/;

    const mensajeRequerido = "Campo requerido";
    const mesajeDatoEntero = "El dato debe de ser númerico";
    const mesajeOnlyLetters = "No se admiten caracteres especiales";
    const mensajeMax = "El valor debe ser menor o igual a 100";
    const messageDiffId = "El archivo debe ser distinto a la constancia de estudios";
    const messageDiffEstudios = "El archivo debe ser distinto a la identificación";

    const metaDataForm = {
        id_catalogo_nivel_ingeles:{required:true, messageRequired:mensajeRequerido, regex:regexEntero, messageRegex:mesajeDatoEntero, maxValue:100, messageMaxVal:mensajeMax},
        id_catalogo_grupo_poblacional:{required:true, messageRequired:mensajeRequerido, limpiaA:['otra_poblacion']},
        otra_poblacion:{required:formData.id_catalogo_grupo_poblacional === 1, messageRequired:mensajeRequerido, regex:regexOnlyLetters, messageRegex:mesajeOnlyLetters},
        id_catalogo_razon_para_tomar_programa:{required:true, messageRequired:mensajeRequerido, limpiaA:['otra_razon_para_tomar_programa']},
        otra_razon_para_tomar_programa: {required:formData.id_catalogo_razon_para_tomar_programa === 1, messageRequired:mensajeRequerido, regex:regexOnlyLetters, messageRegex:mesajeOnlyLetters},
        id_catalogo_tiempo_a_dedicar_diez_horas:{required:true, messageRequired:mensajeRequerido},
        id_catalogo_ingreso_mensual_familia:{required:true, messageRequired:mensajeRequerido},
        id_catalogo_ingreso_familiar_gobierno:{required:true, messageRequired:mensajeRequerido},
        id_catalogo_hogar_propio:{required:true, messageRequired:mensajeRequerido},
        id_catalogo_difusion:{required:true, messageRequired:mensajeRequerido, limpiaA:['otra_difusion']},
        otra_difusion:{required:formData.id_catalogo_difusion === 1, messageRequired:mensajeRequerido, regex:regexOnlyLetters, messageRegex:mesajeOnlyLetters},
        id_catalogo_acceso_computadora:{required:true, messageRequired:mensajeRequerido, limpiaA:['id_catalogo_computadora_exclusiva']},
        id_catalogo_computadora_exclusiva:{required:formData.id_catalogo_acceso_computadora === 1, messageRequired:mensajeRequerido},
        id_catalogo_acceso_internet:{required:true, messageRequired:mensajeRequerido},
        id_catalogo_internet_casa:{required:true, messageRequired:mensajeRequerido},
        id_catalogo_con_smartphone:{required:true, messageRequired:mensajeRequerido},
        id_catalogo_espacio_exclusivo:{required:true, messageRequired:mensajeRequerido},
        id_catalogo_cursos_en_linea_anteriores:{required:true, messageRequired:mensajeRequerido, limpiaA:['id_catalogo_ultimo_resultado_en_linea']},
        id_catalogo_ultimo_resultado_en_linea:{required:formData.id_catalogo_cursos_en_linea_anteriores === 1, messageRequired:mensajeRequerido},
        id_catalogo_conoce_skill_badges:{required:true, messageRequired:mensajeRequerido},
        id_catalogo_conoce_google_cloud_skill:{required:true, messageRequired:mensajeRequerido},
        id_catalogo_cuenta_con_google_skill:{required:true, messageRequired:mensajeRequerido},
        id_catalogo_probabilidad_empleo:{required:true, messageRequired:mensajeRequerido},
        id_catalogo_probabilidad_ingreso_mayor:{required:true, messageRequired:mensajeRequerido}
    };

    useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND_ENDPOINT+'/api/v1/catalogos_formulario_read/2',
        { headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}}
        )
        .then((response) => {
            setListaCatalogos({...response.data});
        })
        .catch((error) => {
            console.error(error);
        });
    }, []);

    const handleChangue = (value,nombreParametro) => {
        let valueAux;
        if(nombreParametro === "id_catalogo_nivel_ingeles" || nombreParametro === "id_catalogo_probabilidad_empleo" || nombreParametro === "id_catalogo_probabilidad_ingreso_mayor"){
            valueAux = value
        }else{
            valueAux = metaDataForm[nombreParametro].upperCase ? value.toUpperCase():value;
            validaDato(value,nombreParametro);
            setMensajesForm(auxMensajesForm);
        }
        
        let formDataAux = validaLimpiezaDatos(nombreParametro);
        setFormData({...formDataAux, [nombreParametro]:valueAux});

    }

    const validaDato = (value,nombreParametro) => {
        //Se valida si es requerido
        if(metaDataForm[nombreParametro].required && (value === null || value === "" || value === undefined)) {
            console.log("Valio_1",nombreParametro,value)
            auxMensajesForm = {...auxMensajesForm, [nombreParametro]:metaDataForm[nombreParametro].messageRequired};
            return false;
        }

        //Se valida si tiene una expresion regular
        if(metaDataForm[nombreParametro].regex &&!metaDataForm[nombreParametro].regex.test(value)&&value) {
            console.log("Valio_2",nombreParametro,value)
            auxMensajesForm = {...auxMensajesForm, [nombreParametro]:metaDataForm[nombreParametro].messageRegex};
            return false;
        }

        //Se valida si tiene un minimo de caracteres
        if(metaDataForm[nombreParametro].minLength &&metaDataForm[nombreParametro].minLength > value.length) {
            console.log("Valio_3",nombreParametro,value)
            auxMensajesForm = {...auxMensajesForm, [nombreParametro]:metaDataForm[nombreParametro].messageMinLength};
            return false;
        }

        //Se valida si tiene un maximo value
        if(metaDataForm[nombreParametro].maxValue &&  parseInt(value) > metaDataForm[nombreParametro].maxValue) {
            console.log("Valio_4",nombreParametro,value)
            auxMensajesForm = {...auxMensajesForm, [nombreParametro]:metaDataForm[nombreParametro].messageMaxVal};
            return false;
        }

        if(metaDataForm[nombreParametro].diff) {
            let diffThan = metaDataForm[nombreParametro].diffThan;
            
            if(formData[diffThan]){
                if((formData[diffThan].name === formData[nombreParametro].name )&& (formData[diffThan].size === formData[nombreParametro].size )){
                    console.log("Valio_5",nombreParametro,value)
                    auxMensajesForm = {...auxMensajesForm, [nombreParametro]:metaDataForm[nombreParametro].messageDiff};
                    return false;    
                }
            }
        }
        return true;
    }

    const validaLimpiezaDatos = (nombreParametro) => {
        let formAux = formData;

        if(metaDataForm[nombreParametro].limpiaA) {
            metaDataForm[nombreParametro].limpiaA.forEach(value => {
                formAux = {...formAux,[value]:null}
            });
        }

        return formAux;
    }


    const handleChangueCheck = (event,nombreParametro) => {
        const auxChecks = {...checksAviso,[nombreParametro]:event.target.checked}
        let countChecks = 0;

        for(const nameCheck in auxChecks) {
            if(auxChecks[nameCheck]) {
                countChecks++;
            }
        }
        setChecksAviso(auxChecks);
        setChecksCompletos((countChecks===5)||(!preClaveCorrecta&&countChecks===4));
    };

    const validaForm = () => {
        for(const elmentForm in formData) {
            validaDato(formData[elmentForm],elmentForm);
        }
        setMensajesForm(auxMensajesForm);     

        let formInvalido = false;
        for (var attr in auxMensajesForm) {
            if(auxMensajesForm[attr]){
                formInvalido = true;
            }
        }
        setFormInvalido(formInvalido);

        if(!formInvalido && checksCompletos){
            setModalConfirmacion(true);
        }
    };

    const enviarForm = () => {
        //Si todo esta correcto se raliza el envio de la información
        const formDataAux = new FormData();
        for(const elmentForm in formData) {
            formDataAux.append(elmentForm,formData[elmentForm]);
        }

        setModalConfirmacion(false);
            //window.scrollTo(0, 0);
            //updateEstatusModulo(4);
            //sessionStorage.setItem('estatusModulo','4');

         axios.post(process.env.REACT_APP_BACKEND_ENDPOINT+'/api/v1/form/form_datos_usuario_write_2',formDataAux,
         {
             headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}
         })
        .then(res => {
             setModalConfirmacion(false);
             window.scrollTo(0, 0);
             updateEstatusModulo(4);
             sessionStorage.setItem('estatusModulo','4');
         })
         .catch(error => {
             setModalConfirmacion(false);
             console.error(error);
         });
    }
    
    return(
        <div className={styles.container +' container-margin'}>
            <Grid container rowSpacing={4} columnSpacing={8}>
                <Grid className={styles.label} item xs={12} md={6}>
                    <span>Indica el nivel de inglés que consideras que tienes, siendo 0 conocimiento nulo, y 100 conocimiento avanzado o nativo:</span>
                </Grid>
                <Grid item xs={12} md={6}>
                <Slider 
                    defaultValue={50}
                    id="id_catalogo_nivel_ingeles"
                    //value={formData.id_catalogo_nivel_ingeles}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                    onChange={(event)=>{handleChangue(event.target.value,'id_catalogo_nivel_ingeles');}}
                />

                </Grid>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>¿Perteneces a alguno de los siguientes grupos poblacionales?</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    {console.log(listaCatalogos.poblacion)}
                    <FormControl error={mensajesForm.id_catalogo_grupo_poblacional} fullWidth>
                            <Select id="id_catalogo_grupo_poblacional"
                                value={formData.id_catalogo_grupo_poblacional}
                                error={mensajesForm.id_catalogo_grupo_poblacional}
                                onChange={(event)=>{handleChangue(event.target.value,'id_catalogo_grupo_poblacional');}}
                            >
                                { 
                                    listaCatalogos.poblacion.map(data => (
                                        <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                    ))
                                }
                            </Select> 
                            <FormHelperText>{mensajesForm.id_catalogo_grupo_poblacional}</FormHelperText>
                    </FormControl>
                </Grid>
                { formData.id_catalogo_grupo_poblacional === 1 &&
                <>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>¿Cuál?</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <TextField 
                    margin="dense"
                    id="otra_poblacion"
                    value={formData.otra_poblacion}
                    onChange={(event)=>{handleChangue(event.target.value,'otra_poblacion');}}
                    onKeyDown={(event) => { if (!regexOnlyLetters.test(event.key)) { event.preventDefault();  }  }}
                    error={mensajesForm.otra_poblacion}
                    helperText={mensajesForm.otra_poblacion}
                    type="text"
                    fullWidth
                    inputProps={{
                        maxLength: 40
                    }}
                    />
                </Grid>
                </>
                }
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>¿Por qué deseas tomar este programa de capacitación?</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <FormControl error={mensajesForm.id_catalogo_razon_para_tomar_programa} fullWidth>
                            <Select id="id_catalogo_razon_para_tomar_programa"
                                value={formData.id_catalogo_razon_para_tomar_programa}
                                error={mensajesForm.id_catalogo_razon_para_tomar_programa}
                                onChange={(event)=>{handleChangue(event.target.value,'id_catalogo_razon_para_tomar_programa');}}
                            >
                                { 
                                    listaCatalogos.razon_programa.map(data => (
                                        <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                    ))
                                }
                            </Select> 
                            <FormHelperText>{mensajesForm.id_catalogo_razon_para_tomar_programa}</FormHelperText>
                    </FormControl>
                </Grid>
                { formData.id_catalogo_razon_para_tomar_programa === 1 &&
                <>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>¿Cuál?</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <TextField 
                    margin="dense"
                    id="otra_razon_para_tomar_programa"
                    value={formData.otra_razon_para_tomar_programa}
                    onChange={(event)=>{handleChangue(event.target.value,'otra_razon_para_tomar_programa');}}
                    onKeyDown={(event) => { if (!regexOnlyLetters.test(event.key)) { event.preventDefault();  }  }}
                    error={mensajesForm.otra_razon_para_tomar_programa}
                    helperText={mensajesForm.otra_razon_para_tomar_programa}
                    type="text"
                    fullWidth
                    inputProps={{
                        maxLength: 40
                    }}
                    />
                </Grid>
                </>
                }
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>¿Le puedes dedicar a la capacitación aproximadamente 10 horas de autoestudio a la semana?</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <FormControl error={mensajesForm.id_catalogo_tiempo_a_dedicar_diez_horas} fullWidth>
                            <Select id="id_catalogo_tiempo_a_dedicar_diez_horas"
                                value={formData.id_catalogo_tiempo_a_dedicar_diez_horas}
                                error={mensajesForm.id_catalogo_tiempo_a_dedicar_diez_horas}
                                onChange={(event)=>{handleChangue(event.target.value,'id_catalogo_tiempo_a_dedicar_diez_horas');}}
                            >
                                { 
                                    listaCatalogos.tiempo_a_dedicar_diez_horas.map(data => (
                                        <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                    ))
                                }
                            </Select> 
                            <FormHelperText>{mensajesForm.id_catalogo_tiempo_a_dedicar_diez_horas}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>¿Cuál es el ingreso mensual de tu familia?</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <FormControl error={mensajesForm.id_catalogo_ingreso_mensual_familia} fullWidth>
                            <Select id="id_catalogo_ingreso_mensual_familia"
                                value={formData.id_catalogo_ingreso_mensual_familia}
                                error={mensajesForm.id_catalogo_ingreso_mensual_familia}
                                onChange={(event)=>{handleChangue(event.target.value,'id_catalogo_ingreso_mensual_familia');}}
                            >
                                { 
                                    listaCatalogos.ingreso_mensual_familia.map(data => (
                                        <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                    ))
                                }
                            </Select> 
                            <FormHelperText>{mensajesForm.id_catalogo_ingreso_mensual_familia}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>¿En los últimos 12 meses algún miembro de su hogar ha recibido ayuda de algún programa del gobierno?</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <FormControl error={mensajesForm.id_catalogo_ingreso_familiar_gobierno} fullWidth>
                            <Select id="id_catalogo_ingreso_familiar_gobierno"
                                value={formData.id_catalogo_ingreso_familiar_gobierno}
                                error={mensajesForm.id_catalogo_ingreso_familiar_gobierno}
                                onChange={(event)=>{handleChangue(event.target.value,'id_catalogo_ingreso_familiar_gobierno');}}
                            >
                                { 
                                    listaCatalogos.apoyo_gobierno.map(data => (
                                        <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                    ))
                                }
                            </Select> 
                            <FormHelperText>{mensajesForm.id_catalogo_ingreso_familiar_gobierno}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>¿El lugar donde vives es propio?</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <FormControl error={mensajesForm.id_catalogo_hogar_propio} fullWidth>
                            <Select id="id_catalogo_hogar_propio"
                                value={formData.id_catalogo_hogar_propio}
                                error={mensajesForm.id_catalogo_hogar_propio}
                                onChange={(event)=>{handleChangue(event.target.value,'id_catalogo_hogar_propio');}}
                            >
                                { 
                                    listaCatalogos.vivienda_propia.map(data => (
                                        <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                    ))
                                }
                            </Select> 
                            <FormHelperText>{mensajesForm.id_catalogo_hogar_propio}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>¿Cómo te enteraste de este programa?</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <FormControl error={mensajesForm.id_catalogo_difusion} fullWidth>
                            <Select id="id_catalogo_difusion"
                                value={formData.id_catalogo_difusion}
                                error={mensajesForm.id_catalogo_difusion}
                                onChange={(event)=>{handleChangue(event.target.value,'id_catalogo_difusion');}}
                            >
                                { 
                                    listaCatalogos.difusion.map(data => (
                                        <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                    ))
                                }
                            </Select> 
                            <FormHelperText>{mensajesForm.id_catalogo_difusion}</FormHelperText>
                    </FormControl>
                </Grid>
                { formData.id_catalogo_difusion === 1 &&
                <>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>¿Cuál?</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <TextField 
                    margin="dense"
                    id="otra_difusion"
                    value={formData.otra_difusion}
                    onChange={(event)=>{handleChangue(event.target.value,'otra_difusion');}}
                    onKeyDown={(event) => { if (!regexOnlyLetters.test(event.key)) { event.preventDefault();  }  }}
                    error={mensajesForm.otra_difusion}
                    helperText={mensajesForm.otra_difusion}
                    type="text"
                    fullWidth
                    inputProps={{
                        maxLength: 40
                    }}
                    />
                </Grid>
                </>
                }
                <Grid className={styles.label} item xs={12} md={6}>
                    <span>El curso debe de realizarse por medio de Laptop, Computadora o PC ¿Tienes acceso a alguno de estos dispositivos?</span>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl error={mensajesForm.id_catalogo_acceso_computadora} fullWidth>
                            <Select id="id_catalogo_acceso_computadora"
                                value={formData.id_catalogo_acceso_computadora}
                                error={mensajesForm.id_catalogo_acceso_computadora}
                                onChange={(event)=>{handleChangue(event.target.value,'id_catalogo_acceso_computadora');}}
                            >
                                { 
                                    listaCatalogos.computadora.map(data => (
                                        <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                    ))
                                }
                            </Select> 
                            <FormHelperText>{mensajesForm.id_catalogo_acceso_computadora}</FormHelperText>
                    </FormControl>
                </Grid>
                { formData.id_catalogo_acceso_computadora === 1 &&
                <>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>¿Esta laptop, computadora o PC es de tu uso exclusivo (sólo para ti)?</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <FormControl error={mensajesForm.id_catalogo_computadora_exclusiva} fullWidth>
                            <Select id="id_catalogo_computadora_exclusiva"
                                value={formData.id_catalogo_computadora_exclusiva}
                                error={mensajesForm.id_catalogo_computadora_exclusiva}
                                onChange={(event)=>{handleChangue(event.target.value,'id_catalogo_computadora_exclusiva');}}
                            >
                                { 
                                    listaCatalogos.computadora_esclusiva.map(data => (
                                        <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                    ))
                                }
                            </Select> 
                            <FormHelperText>{mensajesForm.id_catalogo_computadora_exclusiva}</FormHelperText>
                    </FormControl>
                </Grid>
                </>
                }
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>¿Tienes acceso a internet?</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <FormControl error={mensajesForm.id_catalogo_acceso_internet} fullWidth>
                            <Select id="id_catalogo_acceso_internet"
                                value={formData.id_catalogo_acceso_internet}
                                error={mensajesForm.id_catalogo_acceso_internet}
                                onChange={(event)=>{handleChangue(event.target.value,'id_catalogo_acceso_internet');}}
                            >
                                { 
                                    listaCatalogos.acceso_internet.map(data => (
                                        <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                    ))
                                }
                            </Select> 
                            <FormHelperText>{mensajesForm.id_catalogo_acceso_internet}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>¿Cuentas con internet en casa?</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <FormControl error={mensajesForm.id_catalogo_internet_casa} fullWidth>
                            <Select id="id_catalogo_internet_casa"
                                value={formData.id_catalogo_internet_casa}
                                error={mensajesForm.id_catalogo_internet_casa}
                                onChange={(event)=>{handleChangue(event.target.value,'id_catalogo_internet_casa');}}
                            >
                                { 
                                    listaCatalogos.internet_casa.map(data => (
                                        <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                    ))
                                }
                            </Select> 
                            <FormHelperText>{mensajesForm.id_catalogo_internet_casa}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>¿Cuentas con un teléfono móvil o celular inteligente (smartphone)?</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <FormControl error={mensajesForm.id_catalogo_con_smartphone} fullWidth>
                            <Select id="id_catalogo_con_smartphone"
                                value={formData.id_catalogo_con_smartphone}
                                error={mensajesForm.id_catalogo_con_smartphone}
                                onChange={(event)=>{handleChangue(event.target.value,'id_catalogo_con_smartphone');}}
                            >
                                { 
                                    listaCatalogos.telefono_inteligente.map(data => (
                                        <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                    ))
                                }
                            </Select> 
                            <FormHelperText>{mensajesForm.id_catalogo_con_smartphone}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>¿Cuentas con un espacio exclusivo para estudiar o trabajar en tu casa?</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <FormControl error={mensajesForm.id_catalogo_espacio_exclusivo} fullWidth>
                            <Select id="id_catalogo_espacio_exclusivo"
                                value={formData.id_catalogo_espacio_exclusivo}
                                error={mensajesForm.id_catalogo_espacio_exclusivo}
                                onChange={(event)=>{handleChangue(event.target.value,'id_catalogo_espacio_exclusivo');}}
                            >
                                { 
                                    listaCatalogos.espacio_esclusivo.map(data => (
                                        <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                    ))
                                }
                            </Select> 
                            <FormHelperText>{mensajesForm.id_catalogo_espacio_exclusivo}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>¿Has tomado cursos en línea anteriormente?</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <FormControl error={mensajesForm.id_catalogo_cursos_en_linea_anteriores} fullWidth>
                            <Select id="id_catalogo_cursos_en_linea_anteriores"
                                value={formData.id_catalogo_cursos_en_linea_anteriores}
                                error={mensajesForm.id_catalogo_cursos_en_linea_anteriores}
                                onChange={(event)=>{handleChangue(event.target.value,'id_catalogo_cursos_en_linea_anteriores');}}
                            >
                                { 
                                    listaCatalogos.cursos_en_linea.map(data => (
                                        <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                    ))
                                }
                            </Select> 
                            <FormHelperText>{mensajesForm.id_catalogo_cursos_en_linea_anteriores}</FormHelperText>
                    </FormControl>
                    
                </Grid>
                { formData.id_catalogo_cursos_en_linea_anteriores === 1 &&
                <>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>¿Qué resultado obtuviste en el último curso en línea que tomaste?</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <FormControl error={mensajesForm.id_catalogo_ultimo_resultado_en_linea} fullWidth>
                            <Select id="id_catalogo_ultimo_resultado_en_linea"
                                value={formData.id_catalogo_ultimo_resultado_en_linea}
                                error={mensajesForm.id_catalogo_ultimo_resultado_en_linea}
                                onChange={(event)=>{handleChangue(event.target.value,'id_catalogo_ultimo_resultado_en_linea');}}
                            >
                                { 
                                    listaCatalogos.ultimo_resultado.map(data => (
                                        <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                    ))
                                }
                            </Select> 
                            <FormHelperText>{mensajesForm.id_catalogo_ultimo_resultado_en_linea}</FormHelperText>
                    </FormControl>
                </Grid>
                </>
                }
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>¿Sabes qué es un skill badges (insignia de habilidades)?</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <FormControl error={mensajesForm.id_catalogo_grupo_poblacional} fullWidth>
                            <Select id="id_catalogo_conoce_skill_badges"
                                value={formData.id_catalogo_conoce_skill_badges}
                                error={mensajesForm.id_catalogo_conoce_skill_badges}
                                onChange={(event)=>{handleChangue(event.target.value,'id_catalogo_conoce_skill_badges');}}
                            >
                                { 
                                    listaCatalogos.skill_badges.map(data => (
                                        <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                    ))
                                }
                            </Select> 
                            <FormHelperText>{mensajesForm.id_catalogo_conoce_skill_badges}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>¿Conoces los Google Cloud skill badges (insignias de habilidades)?</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <FormControl error={mensajesForm.id_catalogo_conoce_google_cloud_skill} fullWidth>
                            <Select id="id_catalogo_conoce_google_cloud_skill"
                                value={formData.id_catalogo_conoce_google_cloud_skill}
                                error={mensajesForm.id_catalogo_conoce_google_cloud_skill}
                                onChange={(event)=>{handleChangue(event.target.value,'id_catalogo_conoce_google_cloud_skill');}}
                            >
                                { 
                                    listaCatalogos.google_cloud_skill_badges.map(data => (
                                        <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                    ))
                                }
                            </Select> 
                            <FormHelperText>{mensajesForm.id_catalogo_conoce_google_cloud_skill}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>¿Actualmente cuentas con algún Google Cloud skill badges (insignias de habilidades de Google Cloud)?</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <FormControl error={mensajesForm.id_catalogo_cuenta_con_google_skill} fullWidth>
                            <Select id="id_catalogo_cuenta_con_google_skill"
                                value={formData.id_catalogo_cuenta_con_google_skill}
                                error={mensajesForm.id_catalogo_cuenta_con_google_skill}
                                onChange={(event)=>{handleChangue(event.target.value,'id_catalogo_cuenta_con_google_skill');}}
                            >
                                { 
                                    listaCatalogos.cuentas_con_google_cloud_skill_badges.map(data => (
                                        <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                    ))
                                }
                            </Select> 
                            <FormHelperText>{mensajesForm.id_catalogo_cuenta_con_google_skill}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid className={styles.label} item xs={12} md={7}>
                    <span>De aquí a 12 meses, ¿Cuán probable crees que será tener un empleo gracias a haber conseguido un Google Cloud skill badges (insignias de habilidades de Google Cloud)? “0” indica “nada probable” y 100 indica “con total seguridad”.:</span>
                </Grid>
                <Grid item xs={12} md={5}>
                    <FormControl error={mensajesForm.id_catalogo_probabilidad_empleo} fullWidth>
                        <Slider 
                            defaultValue={50}
                            id="id_catalogo_probabilidad_empleo"
                            aria-label="Default"
                            valueLabelDisplay="auto"
                            onChange={(event)=>{handleChangue(event.target.value,'id_catalogo_probabilidad_empleo');}}
                        />
                    </FormControl>
                </Grid>
                <Grid className={styles.label} item xs={12} md={7}>
                    <span>De aquí a 12 meses, ¿Cuán probable crees que será tener un ingreso mayor al actual gracias a haber conseguido un Google Cloud skill badges (insignias de habilidades de Google Cloud)? “0” indica “nada probable” y 100 indica “con total seguridad”.</span>
                </Grid>
                <Grid item xs={12} md={5}>
                    <FormControl error={mensajesForm.id_catalogo_probabilidad_ingreso_mayor} fullWidth>

                        <Slider
                            defaultValue={50}
                            id="id_catalogo_probabilidad_ingreso_mayor"
                            aria-label="Default"
                            valueLabelDisplay="auto"
                            onChange={(event)=>{handleChangue(event.target.value,'id_catalogo_probabilidad_ingreso_mayor');}}
                        />

                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                    <FormGroup className='form-cheks'>
                        { preClaveCorrecta && <FormControlLabel control={<Checkbox />} checked={checksAviso.datosPersonalesOrganizacion} onChange={(event)=>handleChangueCheck(event,"datosPersonalesOrganizacion")} label="Acepto que mis datos personales y de avances sean compartidos con la Organizacion Aliada que me recomendó, y sean tratados conforme a lo señalado en el Aviso de Privacidad presente."/>}
                        <FormControlLabel control={<Checkbox />} checked={checksAviso.informacionProporcionada} onChange={(event)=>handleChangueCheck(event,"informacionProporcionada")} label="Declaro que toda la información proporcionada es verdadera, completa,  y correcta.  En caso de ser necesario, INROADS podrá solicitar la documentación comprobatoria."/>
                        <FormControlLabel control={<Checkbox />} checked={checksAviso.cumplimientoTiempos} onChange={(event)=>handleChangueCheck(event,"cumplimientoTiempos")} label="Me comprometo a concluir en tiempo y  forma de acuerdo a lo establecido, en caso de ser seleccionad@ para una beca del programa de Certificados de Carrera de Google."/>
                        <FormControlLabel control={<Checkbox />} checked={checksAviso.tratamientoDatos} onChange={(event)=>handleChangueCheck(event,"tratamientoDatos")} label="Acepto que mis datos personales de identificación, de contacto, académicos y financieros, sean tratados conforme a lo señalado en el Aviso de Privacidad presente."/>
                        <FormControlLabel control={<Checkbox />} checked={checksAviso.avisoPrivacidad} onChange={(event)=>handleChangueCheck(event,"avisoPrivacidad")} label={<a href='https://inroads.org.mx/aviso-de-privacidad/'>Aviso de privacidad</a>} />
                    </FormGroup>
                </Grid>
                {formInvalido &&
                <Grid item xs={12} md={12}>
                    <Alert severity="warning">Favor de revisar el formulario, existen campos vacios o invalidos.</Alert>
                </Grid>
                }
                {!checksCompletos &&
                <Grid item xs={12} md={12}>
                    <Alert severity="warning">Recuerda validar todos los checks.</Alert>
                </Grid>
                }
                <Grid className={styles['text-right']} item xs={12} md={12}>
                    <p className={styles['a_button']} onClick={validaForm}> <i>2/3 <br /> <u><b>Siguiente</b></u></i></p>
                </Grid>
            </Grid>
            <Modal 
                open={modalConfirmacion}
                onClose={()=>{setModalConfirmacion(false);}} 
                id="modal-form"
            >
                <div className='form-modal'>
                    <div className='form-modal_titulo'>
                        <p>¿Seguro que deseas pasar al siguiente formulario?</p>
                    </div>
                    <div className='form-modal_texto'>
                        <p>
                            Recuerda que una vez avanzado al siguiente formulario las respuestas de este formulario se guardarán y no se podrá modificar
                        </p>
                    </div>
                    <div className='form-modal_button'>
                        <Button onClick={enviarForm} backGroundColor={'#011d60'}>Aceptar</Button>
                    </div>
                </div>
            </Modal> 
        </div>
    );
};
