import React from 'react';
import styles from './ProgressFaceGroup.module.css';
import Arrow from '../../img/arrows/flecha.svg';
import FaceIco from '../../img/logos/face.png';
import CorreoIco from '../../img/logos/correo.png';
import TelIco from '../../img/logos/telefono.png';

export default function ProgressFaceGroup({ligaFace}) {
    return(
        <div className={styles.container + ' container-margin'}>
            <div className={styles.title}>
                <p><img src={Arrow} alt="arrow" width={30}/> Es importante que te unas al grupo de Facebook del programa aquí:</p>
            </div>
            <div>
                <p className={styles.sangria}> <img src={FaceIco} alt="face" width={40} />{ligaFace}</p>
                <p>
                    Guardar nuestro número como contacto en tu celular y evita que nuestros correos se vayan a spam, <br />
                    para poder recibir información relevante del programa:
                </p>
                <p className={styles.sangria}>
                    <img src={CorreoIco} alt="correo" width={40}/>subete.nube.google@inroads.org.mx
                </p>
                <p className={styles.sangria}>
                    <img src={TelIco} alt="tel" width={40}/>81 3471 5109 
                </p>
                <p>
                    Antes de contactar a nuestro equipo de "Soporte y Ayuda", te recomendamos nuestra sección de "Preguntas <br /> 
                    frecuentes".Allí encontrarás respuestas a las preguntas más comunes y podrás solucionar tu problema de manera más rápida y <br />
                    eficiente.
                </p>
            </div>
        </div>
    );
};
