import React from 'react';
import style from './Testimonials.module.css';
import CardGrey from '../../components/CardGrey/CardGrey';
import useMediaQuery from '@mui/material/useMediaQuery';
import T1 from '../../img/photo_testimonial/testimonio_ej1.png';
import T2 from '../../img/photo_testimonial/testimonio_ej2.png';
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";


export default function Testimonials() {

    const containerMargin = useMediaQuery('screen and (min-width: 860px)') ? 'container-margin':'';

    // const cuatroElementos = useMediaQuery('screen and (min-width: 1748px)') ? 4:null;
    // const tresElementos = useMediaQuery('screen and (min-width: 1393px)') ? 3:null;
    // const dosElementos = useMediaQuery('screen and (min-width: 1014px)') ? 2:null;

    // const slidesToShow = cuatroElementos ? cuatroElementos : tresElementos ? tresElementos: dosElementos? dosElementos:1;

    // const settings = {
    //     dots: false,
    //     infinite: false,
    //     slidesToShow:2,
    //     slidesToScroll:1,
    //     autoplay: false,
    //     speed: 300,
    //     autoplaySpeed: 0,
    //     swipe: true,
    //     cssEase: "linear"
    // };

    return(
        <div className={containerMargin+' margin-top-md'}>
            <div className={style.title}>
                Testimonios de éxito.
            </div>
            <div className={style['container-cards']}>
                <CardGrey img={T1}
                    name={'Luis Bernardo Moreno Magoyan'}
                >
                    "Agradecer a INROADS por que este programa me amplió mi panorama en el mundo de la tecnología y me permitió darles un plus a mis habilidades formativas."
                </CardGrey>
                <CardGrey img={T2} 
                    name={'Grecia Méndez Guerrero'}
                >
                    "Gracias al programa Skill Boots de Google tuve la oportunidad de subirme a la nube y relacionarme con la tecnología, entender desde una perspectiva amigable temas como Machine Learning y recibir retroalimentación: compañeros y maestros creamos comunidad y ese es uno de los valores más importantes que fomentamos juntos como equipo."
                </CardGrey>
            </div>
        </div>
    );
};
