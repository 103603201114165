import React from 'react';
import style from './Button.module.css';

export default function Button({children,backGroundColor,textColor, onClick, disabled}) {
    const styleExtra = {
        color:textColor,
        backgroundColor:backGroundColor
    }

    return(
        <button className={style.button} style={styleExtra} onClick={onClick} disabled={disabled}>{children}</button>
    );
};
