import React from 'react';
import '../../css/accordion.css'
import style from './AskFreq.module.css';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TreeView from '@mui/lab/TreeView';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      marginTop:'15px',
    },
    heading: {
      fontSize: theme.typography.pxToRem(17),
      fontWeight: theme.typography.fontWeightMedium,
    },
}));

export default function AskFreq() {
  const classes = useStyles();

  return(
      <div className='margin-top-md'>
        <div className={style.title + ' container-margin'}>
          Preguntas Frecuentes
        </div>
        <div className={classes.root+' acordion-root'}>
          <Accordion className='acordion-container'>
            <AccordionSummary
              className='acordion-item-ask'
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
                <Typography className={classes.heading}>
                  ¿Qué tengo que realizar?
                </Typography>
            </AccordionSummary>
            <AccordionDetails className='acordion-item-detail'>
              <Typography>
              <TreeView className="seccion_preguntas"
                aria-label="file system navigator" defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />} sx={{ flexGrow: 1 }}>
                  <TreeItem nodeId="1" label='¿Para qué hacer la insignias/laboratorios opcional de "Google Cloud Essentials"?'>
                    <p>
                    En dicho laboratorio adquirirás experiencia práctica en las herramientas y los servicios fundamentales de Google Cloud
                    Platform (GCP). Google Cloud Essentials es la primera Quest de nivel básico que te recomendamos cursar de Google Cloud. 
                    Ingresarás con poco o ningún conocimiento previo sobre la nube y culminarás con experiencia práctica que podrás aplicar a 
                    tu primer proyecto de GCP. 
                    </p>
                  </TreeItem>
                  <TreeItem nodeId="2" label='¿Qué insignias/laboratorios son las que debo de obtener?'>
                    <p>
                    Las insignias/laboratorioss que debes de obtener son: <br />
                    - Google Cloud Computing Foundations: Cloud Computing Fundamentals <br />
                    - Google Cloud Computing Foundations: Infrastructure in Google Cloud <br />
                    - Google Cloud Computing Foundations: Networking & Security in Google Cloud <br />
                    - Google Cloud Computing Foundations: Data, ML, and AI in Google Cloud <br /> <br />

                    Recuerda que como laboratorio opcional tenemos:  <br />
                    - Google Cloud Essentials
                    </p>
                  </TreeItem>
                  <TreeItem nodeId="3" label='¿Cuánto tiempo tengo para completar las insignias/laboratorios?'>
                    <p>
                    Deberás obtener una insignia por semana. Podrás visualizar, dentro de la plataforma, la fecha límite para realizar cada una de 
                    ellas debajo de su imagen respectiva.
                    </p>
                  </TreeItem>
                  <TreeItem nodeId="4" label='¿Es necesario visualizar el material dentro de la plataforma?'>
                    <p>
                    Sí, dicho material está disponible para que lo puedas consultar cuando lo prefieras y así resolver tus dudas lo que te permitirá 
                    continuar avanzado con tus insignias. 
                    </p>
                  </TreeItem>
              </TreeView>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className='acordion-container'>
            <AccordionSummary
              className='acordion-item-ask'
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
                <Typography className={classes.heading}>
                  Recomendaciones para realizar las insignias/laboratorios
                </Typography>
            </AccordionSummary>
            <AccordionDetails className='acordion-item-detail'>
              <Typography>
              <TreeView className="seccion_preguntas"
                aria-label="file system navigator" defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />} sx={{ flexGrow: 1 }}>
                  <TreeItem nodeId="1" style={{color:'#000'}} label="¿Cuál es la recomendación para realizar los insignias/laboratorios?">
                    <p>
                      Es <b>muy importante</b> realizar los laboratorios en Modo Incógnito para tener una experiencia sin inconvenientes.
                    </p>
                  </TreeItem>
                  <TreeItem nodeId="2" label="¿Es necesario realizar los laboratorios en Modo Incógnito?">
                    <p>
                      Sí, es importante que realices tus laboratorios en una ventana de Modo Incógnito. Esto permitirá que las cookies e historial de 
                      navegación se borren una vez que finalices la sesión. 
                    </p>
                  </TreeItem>
                  <TreeItem nodeId="3" label="¿Las insignias que he completado se reflejan en la plataforma?">
                    <p>
                      Sí, cada insignia que vayas completando se reflejará dentro de la plataforma, así como en tu perfil público.
                    </p>
                  </TreeItem>
                  <TreeItem nodeId="4" label="Si tengo algún problema al momento de estar realizando mi laboratorio, ¿qué hago?">
                    <p>
                      Puedes mandar un mensaje en el ícono (?) de "Ayuda", este se encuentra localizado en la parte superior derecha, 
                      en Google Cloud Platform. 
                    </p>
                  </TreeItem>
                  <TreeItem nodeId="5" label="¿Se tiene que mandar alguna evidencia de que completé las insignias?">
                    <p>
                      No, no es necesario, ya que tu avance se verá reflejado dentro de tu perfil público y en la plataforma.
                    </p>
                  </TreeItem>
              </TreeView>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className='acordion-container'>
            <AccordionSummary
              className='acordion-item-ask'
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
                <Typography className={classes.heading}>
                  ¿Cuáles son las fechas de entrega?
                </Typography>
            </AccordionSummary>
            <AccordionDetails className='acordion-item-detail'>
              <Typography>
              <TreeView className="seccion_preguntas"
              aria-label="file system navigator" defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />} sx={{ flexGrow: 1 }}>
                <TreeItem nodeId="1" label="¿Dónde puedo consultar las fechas y los links de las clases que habrá durante la capacitación?">
                    <p>
                      Dentro de la plataforma dirigirte al apartado llamado "Calendario de clases". Allí encontrarás los enlaces directos a las clases 
                      y las fechas correspondientes. Recuerda ingresar por la plataforma, ya que será la forma en la que mediremos tu progreso. 
                    </p>
                </TreeItem>
                <TreeItem nodeId="2" label="¿No sé refleja mi progreso en la plataforma? ¿Qué hago?">
                    <p>
                      La plataforma se actualiza cada 6 horas, por lo que tus insignias se verán reflejadas al término de este lapso de tiempo. 
                    </p>
                </TreeItem>
              </TreeView>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className='acordion-container'>
            <AccordionSummary
              className='acordion-item-ask'
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
                <Typography className={classes.heading}>
                  Canjeo de créditos
                </Typography>
            </AccordionSummary>
            <AccordionDetails className='acordion-item-detail'>
              <Typography>
              <TreeView className="seccion_preguntas"
              aria-label="file system navigator" defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />} sx={{ flexGrow: 1 }}>
                <TreeItem nodeId="1" label="¿Cómo puedo canjear los créditos para realizar las actividades en Google Cloud Platform?">
                    <p>
                    Te compartimos el siguiente link (https://www.youtube.com/watch?v=KViNvqWYkQ0), 
                    en el que se te explica cómo canjearlos. <b>RECUERDA</b> que es muy importante realizarlo en <b>modo incógnito</b>.   
                    </p>
                </TreeItem>
              </TreeView>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className='acordion-container'>
            <AccordionSummary
              className='acordion-item-ask'
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
                <Typography className={classes.heading}>
                  Beneficios por concluir el programa
                </Typography>
            </AccordionSummary>
            <AccordionDetails className='acordion-item-detail'>
              <Typography>
              <TreeView className="seccion_preguntas"
              aria-label="file system navigator" defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />} sx={{ flexGrow: 1 }}>
                <TreeItem nodeId="1" label="¿Cuáles son los beneficios de completar el programa en tiempo y forma?">
                    <p>
                      Los participantes que terminen en tiempo y forma el programa obtendrán los siguientes beneficios: <br />
                      - Un diploma de finalización  <br />
                      - Podrán aspirar a realizar el Associate Cloud Engineer Learning Path (con una beca de acceso de 6 meses y posibilidad de aplicar
                       a un voucher de descuento al examen de certificación del 50%) <br />
                      - La posibilidad de aplicar al Pool de Talento de INROADS <br />
                      - 4 Insignias con valor curricular
                    </p>
                </TreeItem>
              </TreeView>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
  )
};