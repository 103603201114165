import React from 'react';
import style from './ApplyHere.module.css';
import DailogRegister from '../../components/DialogRegister/DailogRegister';

export default function ApplyHere() {
    return(
        <div className={style.container + ' margin-top-sm'}>
            <div className={style['text-bold']}>
                ¡Regístrate ahora y aumenta tus opciones profesionales! 
            </div>
            <div className={style['container-button']}>
                <DailogRegister/>
            </div>
            <div className={style.nota}>
                *Cupo limitado, INROADS se reserva el derecho de admisión
            </div>
        </div>
    );
};
