import React,{useState,useEffect} from 'react';
import axios from 'axios';
import styles from './SeccionModInsign.module.css';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment/moment';

export default function SeccionModInsign() {
    // Para control de mensajes de exito y error
    const [mensaje, setMensaje] = useState({
        mostrarMensaje: false,
        textoMensaje:"Se actualizo de manera correcta",
        tipoMensaje:"success"
    });
    // Fin control de mensajes

    const [refrescar, setRefrescar] = useState(true);

    const [listaInsignias, setListaInsignias] = useState([]);

    const [listaOleada, setListaOleada] = useState([]);

    const [formData, setFormData] = useState({
        id_badge:null,
        id_oleada:null,
        fecha_limite:"",
    });

    const mensajeRequerido = "Campo requerido";

    const metaDataForm = {
        id_badge:{required:true,messageRequired:mensajeRequerido},
        id_oleada:{required:true,messageRequired:mensajeRequerido},
        fecha_limite:{required:true,messageRequired:mensajeRequerido},
    };

    const [mensajesForm, setMensajesForm] = useState({
        id_badge:"",
        id_oleada:"",
        fecha_limite:"",
    });

    useEffect(() => {
        limpiarDatos();
        
        //Se obtiene lista de insigneas
        axios.get(process.env.REACT_APP_BACKEND_ENDPOINT+'/api/v1/felicidades/get_badges',
        { headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}})
        .then((response) => {
            setListaInsignias(response.data.badges);
        })
        .catch((error) => {
            console.error(error);
        });

        //Se obtiene lista de oleadas
        axios.get(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/admin/oleadas_read/',
        { headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}})
        .then((response) => {
            setListaOleada(response.data.oleadas);
        })
        .catch((error) => {
            console.error(error);
        });
    }, [refrescar]);

    const handleChangue = (value,nombreParametro) => {

        const valueAux = metaDataForm[nombreParametro].upperCase ? value.toUpperCase():value;

        validaDato(valueAux,nombreParametro);

        setFormData({...formData, [nombreParametro]:valueAux});

    }

    const validaDato = (value,nombreParametro) => {
        //Se valida si es requerido
        if(metaDataForm[nombreParametro].required && !value) {
            setMensajesForm({...mensajesForm, [nombreParametro]:metaDataForm[nombreParametro].messageRequired});
            return false;
        }

        //Se valida si tiene una expresion regular
        if(metaDataForm[nombreParametro].regex &&!metaDataForm[nombreParametro].regex.test(value)&&value) {
            setMensajesForm({...mensajesForm, [nombreParametro]:metaDataForm[nombreParametro].messageRegex});
            return false;
        }

        //Se valida si tiene un minimo de caracteres
        if(metaDataForm[nombreParametro].minLength &&metaDataForm[nombreParametro].minLength > value.length) {
            setMensajesForm({...mensajesForm, [nombreParametro]:metaDataForm[nombreParametro].messageMinLength});
            return false;
        }

        //Se limpia mensaje
        setMensajesForm({...mensajesForm, [nombreParametro]:""});
        return true;
    };

    const limpiarDatos = () => {
        setFormData({
            id_badge:null,
            id_oleada:null,
            fecha_limite:"",
        });
    }

    const obtenerFecha = (idOleada,idBadge) => {

        const formParams = new FormData();
        formParams.append('id_badge',idBadge);
        formParams.append('id_oleada',idOleada);
         //Se obtiene la fecha de la insignea relacionada al a oleada
         axios.post(process.env.REACT_APP_BACKEND_ENDPOINT+'/api/v1/admin/oleadas_tienen_badges_read/',formParams,
         { headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}})
         .then((response) => {
            const fechaMaxima = response.data.oleadas_tienen_badges[0]?response.data.oleadas_tienen_badges[0].fecha_maxima:null;
            setFormData({id_oleada:idOleada, id_badge:idBadge,fecha_limite:fechaMaxima?moment(fechaMaxima, "DD-MM-yyyy hh:mm:ss").format("yyyy-MM-DDTHH:mm"):''})
         })
         .catch((error) => {
             console.error(error);
         });
    }

    const limpiarOleada = (idBadge) => {
        setFormData({
            id_badge:idBadge,
            id_oleada:null,
            fecha_limite:"",
        });
    }

    const guardarInformacion = () => {
        if(validaForm()) {
            const params = {
                id_badge:formData.id_badge,
                id_oleada:formData.id_oleada,
                fecha_maxima:moment(formData.fecha_limite,"yyyy-MM-DDTHH:mm").format("yyyy-MM-DD HH:mm:ss")
            };

            axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/admin/oleadas_tienen_badges_write/',params,
            { headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}})
            .then((response) => {
                if(response.data.estatus ===1) {
                    setRefrescar(!refrescar);
                    setMensaje({
                        mostrarMensaje:true,
                        textoMensaje:"Se guardo correctamente la información.",
                        tipoMensaje:"success"
                    });
                } else {
                    setMensaje({
                        mostrarMensaje:true,
                        textoMensaje:"Se produjo un error al guardar la información: "+ response.data.mensaje,
                        tipoMensaje:"error"
                    });
                }
            })
            .catch((error) => {
                setMensaje({
                    mostrarMensaje:true,
                    textoMensaje:"Se produjo un error al guardar la información, favor de volver a intentar",
                    tipoMensaje:"error"
                });
            });
        }
     }

    const validaForm = () => {
        for (const nombreCampo in formData) {
            if(!validaDato(formData[nombreCampo],nombreCampo)) {
                return false;
            }
        }
        return true;
    }

    return(
    <>
        <h2>Modificar fecha limites de insigneas</h2> <br />
        <Grid container rowSpacing={4} columnSpacing={8}>
            <Grid item lg={6} md={6} xs={6} sm={12} justifyContent="center">
                <span className='nota'> Selecciona Insignea:</span>
                <Select
                    label="Selecciona Insignea:"
                    id="idBadge"
                    fullWidth
                    onChange={(event)=>{handleChangue(event.target.value,'id_badge'); limpiarOleada(event.target.value)}}
                    value={formData.id_badge}
                >
                    {   
                        listaInsignias.map(badge => (
                            <MenuItem key={badge.id_badge} value={badge.id_badge}>{badge.nombre_badge}</MenuItem>
                        ))
                    }
                </Select>
                { mensajesForm.id_badge&&
                    <p className={styles['nota_mensaje_error']}>{mensajesForm.id_badge}</p>
                }
            </Grid>
            <Grid item lg={6} md={6} xs={6} sm={12} justifyContent="center">
                    <span className='nota'> Selecciona oleada:</span>
                    <Select
                        label="Selecciona oleada:"
                        id="idOleada"
                        fullWidth
                        disabled={!formData.id_badge}
                        onChange={(event)=>{handleChangue(event.target.value,'id_oleada'); obtenerFecha(event.target.value,formData.id_badge);}}
                        value={formData.id_oleada}
                    >
                        {   
                            listaOleada.map(oleada => (
                                <MenuItem key={oleada.id_oleada} value={oleada.id_oleada}>{oleada.nombre}</MenuItem>
                            ))
                        }
                    </Select>
                    { mensajesForm.id_oleada&&
                        <p className={styles['nota_mensaje_error']}>{mensajesForm.id_oleada}</p>
                    }
                </Grid>
            <Grid item lg={6} md={6} xs={6} sm={12} justifyContent="center">
                <TextField 
                        margin="dense"
                        variant="standard"
                        id="fecha_limite"
                        label="Fecha limite"
                        value={formData.fecha_limite}
                        onChange={(event)=>{handleChangue(event.target.value,'fecha_limite');}}
                        error={mensajesForm.fecha_limite}
                        helperText={mensajesForm.fecha_limite}
                        type="datetime-local"
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                            
                        }}
                    />
            </Grid>
        </Grid>
        <div className='form-button form-button-background-azul'>
            <Button variant="contained" 
            size="small" 
            onClick={guardarInformacion}
            >Registrar</Button>
        </div>
        <Snackbar
            open={mensaje.mostrarMensaje}
            autoHideDuration={3000}
            onClose={()=>setMensaje({...mensaje,mostrarMensaje:false})}
        >
            <MuiAlert elevation={6} variant="filled" severity={mensaje.tipoMensaje}> {mensaje.textoMensaje} </MuiAlert>
        </Snackbar>
    </>
    )
};
