import React, {useState} from 'react';
import axios from 'axios';
import style from './ModalSupport.module.css';
import Button from '../Button/Button';
import { Modal } from 'react-responsive-modal';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from "@material-ui/core/TextField";
import Alert from '@mui/material/Alert';

export default function ModalSupport({openModal, onClose}) {

    const [campos, setCampos] = useState({
        nombre:'',
        correo:'',
        mensajeProblema:''
    });

    const [mensajeCampos, setMensajeCampos] = useState({
        nombre:'',
        correo:'',
        mensajeProblema:''
    });

    const [errorEnvio, setErrorEnvio] = useState(false);

    const regexCorreo = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const regexTexto = /^[0-9a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-_]+$/u;
    
    const mensajeRequerido = "Campo requerido";
    const mensajeDatoCadena = "La cadena no debe de tener carácteres especiales";
    const mensajeCorreo = "Correo invalido";

    const metaCampos = {
        nombre:{regex:regexTexto, messageRegex:mensajeDatoCadena, required:true, messageRequired:mensajeRequerido},
        correo:{regex:regexCorreo, messageRegex:mensajeCorreo, required:true, messageRequired:mensajeRequerido},
        mensajeProblema:{regex:regexTexto, messageRegex:mensajeDatoCadena, required:true, messageRequired:mensajeRequerido}
    };

    const handleChangue = (value,nombreParametro) => {
        validaDato(value,nombreParametro);
        setCampos({...campos,[nombreParametro]:value});
    };

    const validaDato = (value,nombreParametro) => {
        //Depende del tipo de validación que tenga es el que se va a realizar
        //Si es requerido
        if(metaCampos[nombreParametro].required&&!value) {
            setMensajeCampos({...mensajeCampos, [nombreParametro]:metaCampos[nombreParametro].messageRequired});
            return false;
        }

        //Si tiene un regex para validar tipo de estructura de dato
        if(metaCampos[nombreParametro].regex&&!metaCampos[nombreParametro].regex.test(value)) {
            setMensajeCampos({...mensajeCampos, [nombreParametro]:metaCampos[nombreParametro].messageRegex});
            return false;
        }

        //Se limpia mensaje
        setMensajeCampos({...mensajeCampos, [nombreParametro]:""});
        return true;
    };

    const limpiarCampos = () => {
        setCampos({
            nombre:'',
            correo:'',
            mensajeProblema:''
        });

        setMensajeCampos({
            nombre:'',
            correo:'',
            mensajeProblema:''
        });

        setErrorEnvio(false);
    }

    const enviarFomrmulario = () => {
        
        for(const elmentForm in campos) {
            if(!validaDato(campos[elmentForm],elmentForm)) {
               return false;
            }
        }

        const params = {
            email: campos.correo,
            nombre: campos.nombre,
            mensaje: campos.mensajeProblema
        }
        
        axios.post(process.env.REACT_APP_BACKEND_ENDPOINT+'/api/v1/support_help/contact_by_email/',params)
        .then(res => {
            limpiarCampos();
            onClose();
        })
        .catch(error => {
            console.error(error);
            limpiarCampos();
            setErrorEnvio(true);
        });

    }


    return(
        <Modal open={openModal} onClose={onClose} >
              <DialogContent className={style.container}>
                  <DialogContentText style={{fontSize:'25px'}} className="font-landing-herencia">
                      Soporte y Ayuda
                  </DialogContentText>

                    <TextField
                        helperText={mensajeCampos.nombre}
                        error={mensajeCampos.nombre !== ""}
                        autoFocus
                        margin="dense"
                        id="usuario"
                        label="Nombre completo"
                        type="text"
                        value={campos.nombre}
                        fullWidth variant="standard"
                        onChange={(event)=>handleChangue(event.target.value,'nombre') } />

                    <TextField
                        helperText={mensajeCampos.correo}
                        error={mensajeCampos.correo !== ""}
                        margin="dense"
                        id="email"
                        label="Tu correo"
                        type="email"
                        value={campos.correo}
                        fullWidth variant="standard" 
                        onChange={(event)=>handleChangue(event.target.value,'correo')}/>

                    <TextField
                        helperText={mensajeCampos.mensajeProblema}
                        error={mensajeCampos.mensajeProblema !== ""}
                        multiline
                        margin="dense"
                        id="mensaje"
                        label="Mensaje"
                        fullWidth
                        variant="standard"
                        value={campos.mensajeProblema}
                        rows={4}
                        onChange={(event)=>handleChangue(event.target.value,'mensajeProblema')}/>

                    {errorEnvio && <Alert severity="error">Favor de intentar más tarde</Alert> }

                    <div className={style['container-button']}>
                        <Button backGroundColor={'#011d60'} onClick={enviarFomrmulario}>
                            Enviar
                        </Button>
                    </div>
              </DialogContent>
              
          </Modal>
    )
};
