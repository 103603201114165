import React, { Component} from 'react';
import axios from 'axios';
import style from './Signin.module.css';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from "@material-ui/core/TextField";
import FormGroup from '@mui/material/FormGroup';
import ResponsiveDialog from '../DialogRegister/DailogRegister';
import { withRouter } from 'react-router';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import ReCAPTCHA from "react-google-recaptcha";
import SessionContex from '../../provider/SessionContex';
import LoadingButton from '@mui/lab/LoadingButton';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ButtonInicio from '../Button/Button';
import {Buffer} from 'buffer';

class SignUp extends Component {

    constructor() {
        super();

        this.captchaRef = React.createRef();

        this.state = {
            user: '',
            pass: '',
            user_valid: true,
            pass_valid: true,
            isSignedup: false,
            setOpen:false,
            open:false,
            openModal: false,
            openModalEmail: false,
            confirmado: 0,
            found:0,
            showPassword: false,
            emailAgain: '',
            messageDismiss: '',
            certificado1: 0,
            certificado2: 0,
            certificado3: 0,
            certificado4: 0,
            captcha_valid: true,
            status_envio_msg: '',
            loading: false
        }
      
    }

    

    componentDidMount = () => {
        console.log(this.state.status_envio_msg);
        let updateValueAdmin = this.props.context.updateValueAdmin;

        //se actualiza a true solo cuando es admin
        updateValueAdmin(false);

        if(window.location.href.indexOf("data") > -1) {
            const data = new URLSearchParams(this.props.location.search).get("data");
            const dataJson = JSON.parse(Buffer.from(data, 'base64').toString('utf8'));
            this.setState({ user: dataJson.user });
            console.log("state: ");
            console.log(this.state);
            console.log(dataJson);
            this.handleLoginData(dataJson, updateValueAdmin);
        }

    }

    
    handleLoginData = (data, updateValueAdmin) => {
        /*
        registro_formulario_1 = 1
        administracion = 2
        registro_formulario_2 = 3
        registro_formulario_3 = 4
        descanso = 5
        */
        
        let modulo = data.modulo;

        if (data.rol === 'Administrador' && modulo){

            sessionStorage.setItem('isSigned', true);
            sessionStorage.setItem('email', data.user);
            sessionStorage.setItem('estatusModulo', data.estatusModulo);
            sessionStorage.setItem('oleada', data.oleada);

            sessionStorage.setItem('token', data.token)

            sessionStorage.setItem('Admin',true)
            updateValueAdmin(true);

            if(modulo === "administracion"){
                this.props.history.push('/asignacion');
            }

            
        }

        if (data.rol === 'alumno' && modulo){

            sessionStorage.setItem('isSigned', true);
            sessionStorage.setItem('email', data.user);
            sessionStorage.setItem('estatusModulo', data.estatusModulo);
            sessionStorage.setItem('oleada', data.oleada);

            sessionStorage.setItem('token', data.token)

            this.props.history.push('/homeIns');
        }
        
        if (data.status === 400){
            this.setState({ found: 1 })
        }

        if (data.confirmed === 'no confirmado' ){
            this.setState({ confirmado: 1 })            
        }
    }

  handleClickShowPassword = event => {
    this.setState({ showPassword: !this.state.showPassword })
  };

  handleClickOpen = event => {
      event.preventDefault()
      this.setState({ setOpen:true})
  }

  handleClose = event => {
      this.setState({ open:false})
  }

  handleUser = event => {
      this.setState({ user: event.target.value })
  }

  handlePass = event => {
      this.setState({ pass: event.target.value })
  }

  Checklogin = (updateValueAdmin) => {
    
    if(!this.captchaRef.current.getValue()){
        this.setState( {captcha_valid:false});
        return false;
    } 
    

    this.setState( {captcha_valid:true});
    
    const user = {
        email: this.state.user,
        password: this.state.pass,
        email_valid: this.state.user_valid,
        pass_valid: this.state.pass_valid
    };

    if(user.email === "")
        user.email_valid =  false
    
    if(user.password === "")
        user.pass_valid =  false

    if (!user.pass_valid || !user.email_valid){
        this.setState({user_valid:user.email_valid,pass_valid:user.pass_valid})
        return false;
    }


    const headersPost = {
        'content-type': 'text/json'
      }

      const data = user
      const headers_post = JSON.stringify(headersPost)

      this.setState({ found: 0, confirmado: 0, messageDismiss:''});

      this.setState({loading : true});
      axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/login/', data, headers_post)
      .then(res => {
        if (res.request.status === 200){
            if(res.data.activo === 0) {
                this.setState({messageDismiss:res.data.message});
            } else {
                this.handleLoginData(res.data, updateValueAdmin);
            }
        }
      }).catch((error) => {
        this.setState({ found: 1 });
        console.error(error);
    }).finally(()=>this.setState({loading : false}));
  }

  launchModal = e => {
      e.preventDefault()
      this.setState({ found: 0, confirmado: 0, messageDismiss:''});
      this.setState({ openModal: true})
  }

  launchModalEmail = e => {
    e.preventDefault()
    this.setState({ openModalEmail: true,
                    status_envio_msg: '',
                    status_envio: 0})
  }

  closeModal = e => {
      this.setState({ openModal: false,user_valid: true, pass_valid: true, messageDismiss:''})
  }

  closeModalEmail = e => {
    this.setState({ openModalEmail: false})
  }

   sendEmailValidation = event => {
    const user = {
        email:this.state.emailAgain
    }

    const headersPost = {
        'content-type': 'text/json'
    }

    const headers_post = JSON.stringify(headersPost)

    const data = user

    axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/login/send_again_email/', data, headers_post)
    .then(res => {
        this.setState({ status_envio: res.request.status});
        this.setState({ status_envio_msg: res.data.message});
      }).catch(res =>{
        this.setState({ status_envio_msg: res.response.data.message || res.response.data.error});
        this.setState({status_envio: res.request.status})
      })
}


handleEmailAgain = event => {
    this.setState({ emailAgain: event.target.value })
}

handleCambioContrasenia = () => {
    this.props.history.push('/cambioContra')
}

onChangueCaptcha = () => {
    if(this.captchaRef.current.getValue()) {
        this.setState({captcha_valid:true});
    }
}

  render(){
      return (
        <>
        <SessionContex.Consumer>
        { (context) => (
        <>
        <ButtonInicio onClick={this.launchModal} backGroundColor={'#011d60'}>
            Inicia sesión <ArrowForwardIosIcon fontSize="small"/>
        </ButtonInicio>
          <Modal open={this.state.openModal} onClose={this.closeModal} >
              <DialogContent>
                  <DialogContentText style={{fontSize:'25px'}} className="font-landing-herencia">
                      Iniciar sesión
                  </DialogContentText>

                  <TextField 
                    autoFocus
                    error={!this.state.user_valid}
                    margin="dense"
                    id="usuario"
                    label="Usuario"
                    type="email"
                    fullWidth
                    variant="standard"
                    onChange={this.handleUser}/>
                  
                    <FormControl variant="standard" style={{width: "100%"}} >
                    <InputLabel htmlFor="standard-adornment-password">Contraseña</InputLabel>
                    <Input
                        error={!this.state.pass_valid}
                        margin="dense"
                        id="contraseña"
                        label="Contraseña"
                        type={this.state.showPassword ? 'text' : 'password'}
                        fullWidth variant="standard" 
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword}
                            >
                            {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        }
                        onChange={this.handlePass}
                        />
                    </FormControl>
                    <div className={style.captcha}>
                        <ReCAPTCHA
                            ref={this.captchaRef}
                            onChange={this.onChangueCaptcha}
                            sitekey="6LdXadglAAAAAD2hjX9kNtyDYWf1rLdEfNkZBCXS"
                        />
                        {!this.state.captcha_valid &&
                            <p>Por favor acepta el captcha</p>
                        }
                    </div>
                  <FormGroup>
                      { /*
                      <FormControlLabel control={<Checkbox />} label="No soy un robot" />
                    */ }
                    <LoadingButton
                        className={style['button_ovalado']}
                        style={{width:'60%', textTransform: 'None',margin: "auto", paddingTop:'5px' }}
                        onClick={()=>this.Checklogin(context.updateValueAdmin)}
                        loading={this.state.loading}
                        loadingPosition="end"
                        variant="contained">
                            Ingresar
                        </LoadingButton>
                      <button className={style['link-button']} onClick={this.handleCambioContrasenia}>¿Olvidaste tu contraseña?</button>
                      <p 
                        className={style['font-landing']}
                        style={{textAlign:'center'}}>
                            <span>¿Eres nuevo? </span>
                            <ResponsiveDialog liga="true" />
                        </p>
                  </FormGroup>
                    <br></br>
                    {this.state.confirmado ? <Alert severity="warning">Recuerda que debes de validar tu correo antes de iniciar sesión</Alert> : null}
                    {this.state.found ? <Alert severity="warning">Correo y/o contraseña incorrectos</Alert> : null}
                    {this.state.messageDismiss ? <Alert severity="warning">{this.state.messageDismiss}</Alert> : null}
              </DialogContent>
              {/* <Button 
                className={style['button-link']+' '+style['link']}
                style={{textTransform: 'None', textAlign:'center'}}
                onClick={this.launchModalEmail}>
                No has recibido tu correo de confirmación, haz click aquí
              </Button> */ }
                <Modal open={this.state.openModalEmail} onClose={this.closeModalEmail}>
                    <DialogContent>
                        <TextField 
                        autoFocus 
                        margin="dense" 
                        id="usuario" 
                        label="Correo" 
                        type="email" 
                        fullWidth variant="standard" 
                        onChange={this.handleEmailAgain}/>
                        <FormGroup>
                        
                        { this.state.status_envio === 200 && this.state.status_envio_msg === 'send email successfully' ? <Alert severity="info">Se envio tu correo de validacion exitosamente</Alert> : null}
                        { this.state.status_envio >= 400 && this.state.status_envio_msg === 'user are confirmed' ? <Alert severity="error">El usuario ya ha sido confirmado</Alert> : null}
                        { this.state.status_envio >= 400 && this.state.status_envio_msg === 'user not found' ? <Alert severity="error">El no se ha encontrado</Alert> : null}
                        { this.state.status_envio >= 400 && this.state.status_envio_msg.indexOf("does not match") > 0 ? <Alert severity="error">Formato de correo incorrecto</Alert> : null}
                        { this.state.status_envio >= 500 ? <Alert severity="error">Favor de intentar más tarde</Alert> : null}
                        <Button 
                        className={style['button_ovalado']}
                        style={{width:'60%', textTransform: 'None',margin: "auto",marginTop: "15%"}}
                        variant="contained" onClick={this.sendEmailValidation}>Enviar</Button>
                        </FormGroup>
                    </DialogContent>
                </Modal>
              
          </Modal>
          </>
          )
          }
          </SessionContex.Consumer>
          </>
      )
  }
}


export default withRouter(SignUp);