import React from 'react';
import style from './Associations.module.css';
import Inroads from '../../img/logos/inroads_logo.svg';
import Google from '../../img/logos/google_logo.svg';
import BID from '../../img/logos/BID_logo.svg';


export default function Associations() {
    return(
        <div className={style.container + ' margin-top-md'}>
            <div className={style['container-logos']}>
                <div>
                    <img src={Inroads} alt="Inroads" width={150} height={'auto'}/>
                </div>
                <div>
                    <img src={Google} alt="google cloud" width={180} height={'auto'} />
                </div>
                <div>
                    <img src={BID} alt="BID" width={120} height={'auto'}/>
                </div>
            </div>
        </div>
    );
};
