import React, { useState, useEffect } from 'react';
import styles from './FormIOne.module.css';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import TextField from "@mui/material/TextField";
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { Modal } from 'react-responsive-modal';
import moment from 'moment/moment';
import Button from '../../components/Button/Button';

export default function FormOne({updateEstatusModulo}) {

    const [formData, setFormData] = useState({
        clave_pre_registro:"",
        primer_nombre_oficial:"",
        segundo_nombre_oficial:"",
        primer_apellido_oficial:"",
        segundo_apellido_oficial:"",
        curp:"",
        nombre_apellido_cert:"",
        id_catalogo_genero:null,
        fecha_nacimiento:"",
        id_catalogo_nacionalidad:null,
        id_catalogo_estado:null,
        codigo_postal:"",
        telefono_celular:"",
        telefono_celular_confirm:"",
        telefono_fijo:"",
        telfono_fijo_confirm:"",
        contacto_uno_nombre_completo:"",
        contacto_uno_parentesco:"",
        contacto_uno_telefono:"",
        contacto_uno_correo:"",
        contacto_dos_nombre_completo:"",
        contacto_dos_parentesco:"",
        contacto_dos_telefono:"",
        contacto_dos_correo:"",
        id_catalogo_estado_civil:null,
        id_catalogo_numero_ninios:null,
        id_catologo_numero_personas_adultas:null,
        id_catologo_discapacidad:null,
        id_catologo_persona_hogar_discapacidad:null,
        id_catalogo_trabajo_con_ingreso:null,
        id_catalogo_edad_primer_trabajo:null,
        id_catalogo_anios_trabajo:null,
        id_catalogo_meses_trabajo:null,
        id_catalogo_situcion_laboral_actual:null,
        id_catalogo_cuenta_con_imss:null,
        id_catalogo_nivel_educativo_mas_alto:null,
        id_catalogo_estatus_educacion:null,
        id_catalogo_nivel_estudia_actualmente:null,
        id_catalogo_grado_que_asiste:null,
        id_catalogo_escuela:null,
        id_catalogo_carrera:null,
        fecha_estimada_graduacion:"",
        id_catologo_promedio_general:null
    });
    

    const [mensajesForm, setMensajesForm] = useState({
        clave_pre_registro:"",
        primer_nombre_oficial:"",
        segundo_nombre_oficial:"",
        primer_apellido_oficial:"",
        segundo_apellido_oficial:"",
        curp:"",
        nombre_apellido_cert:"",
        id_catalogo_genero:"",
        fecha_nacimiento:"",
        id_catalogo_nacionalidad:"",
        id_catalogo_estado:"",
        codigo_postal:"",
        telefono_celular:"",
        telefono_celular_confirm:"",
        telefono_fijo:"",
        telfono_fijo_confirm:"",
        contacto_uno_nombre_completo:"",
        contacto_uno_parentesco:"",
        contacto_uno_telefono:"",
        contacto_uno_correo:"",
        contacto_dos_nombre_completo:"",
        contacto_dos_parentesco:"",
        contacto_dos_telefono:"",
        contacto_dos_correo:"",
        id_catalogo_estado_civil:"",
        id_catalogo_numero_ninios:"",
        id_catologo_numero_personas_adultas:"",
        id_catologo_discapacidad:"",
        id_catologo_persona_hogar_discapacidad:"",
        id_catalogo_trabajo_con_ingreso:"",
        id_catalogo_edad_primer_trabajo:"",
        id_catalogo_anios_trabajo:"",
        id_catalogo_meses_trabajo:"",
        id_catalogo_situcion_laboral_actual:"",
        id_catalogo_cuenta_con_imss:"",
        id_catalogo_nivel_educativo_mas_alto:"",
        id_catalogo_estatus_educacion:"",
        id_catalogo_nivel_estudia_actualmente:"",
        id_catalogo_grado_que_asiste:"",
        id_catalogo_escuela:"",
        id_catalogo_carrera:"",
        fecha_estimada_graduacion:"",
        id_catologo_promedio_general:""
    });

    const [listaCatalogos, setListaCatalogos] = useState({
        anios_trabajo:[],
        carreras:[],
        cuenta_con_imss:[],
        discapacidad:[],
        edad_primer_trabajo:[],
        escuelas:[],
        estado_civil:[],
        estados:[],
        generos:[],
        grado_que_asiste:[],
        meses_trabajo:[],
        nacionalidad:[],
        nivel_educativo:[],
        estatus_educacion:[],
        nivel_estudia_actualmente:[],
        numero_ninios:[],
        numero_personas_adultas:[],
        persona_hogar_discapacidad:[],
        promedios_generales:[],
        situacion_laboral:[],
        trabajo_con_ingreso:[],
        respAux:[]
    });

    const [formInvalido, setFormInvalido] = useState(false);

    //const [setPreClaveCorrecta] = useState(false);

    const [modalConfirmacion, setModalConfirmacion] = useState(false);

    const regexCurp = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
    const regexTexto = /^[0-9a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-_]+$/u;
    const regexEntero = /^[0-9]+$/;
    const regexCorreo = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const regexOnlyLetters = /^[a-zA-Z\s]+$/;

    const mensajeRequerido = "Campo requerido";
    const mensajeDatoCadena = "La cadena no debe de tener carácteres especiales";
    const mesajeDatoEntero = "El dato debe de ser númerico"
    const mensajeCurpInvalido = "Por favor ingresa un CURP valido";
    const mensajeDatoMin = "El número de teléfonico debe tener minimo 10 digitos";
    const mensajeCorreo = "Por favor ingresa un correo valido";

    //Esta variable se ocupa para parametrizar los atributos de cada campo
    const metaDataForm = {
        tienes_clave_registro:{required:true, messageRequired:mensajeRequerido, limpiaA:['clave_pre_registro']},
        clave_pre_registro:{required:formData.tienes_clave_registro === 1, regex:regexTexto, messageRegex:mensajeDatoCadena,messageRequired:mensajeRequerido},
        primer_nombre_oficial:{required:true, messageRequired:mensajeRequerido, regex:regexTexto, messageRegex:mensajeDatoCadena, upperCase:true},
        segundo_nombre_oficial:{required:false, messageRequired:mensajeRequerido, regex:regexTexto, messageRegex:mensajeDatoCadena, upperCase:true},
        primer_apellido_oficial:{required:true, messageRequired:mensajeRequerido, regex:regexTexto, messageRegex:mensajeDatoCadena, upperCase:true},
        segundo_apellido_oficial:{required:false, messageRequired:mensajeRequerido, regex:regexTexto, messageRegex:mensajeDatoCadena, upperCase:true},
        curp:{required:true, messageRequired:mensajeRequerido, regex:regexCurp, messageRegex:mensajeCurpInvalido, upperCase:true },
        nombre_apellido_cert:{required:true, messageRequired:mensajeRequerido, regex:regexTexto, messageRegex:mensajeDatoCadena, upperCase:true},
        id_catalogo_genero:{required:true, messageRequired:mensajeRequerido},
        fecha_nacimiento:{required:true, messageRequired:mensajeRequerido},
        id_catalogo_nacionalidad:{required:true, messageRequired:mensajeRequerido},
        id_catalogo_estado:{required:true, messageRequired:mensajeRequerido},
        codigo_postal:{required:true, messageRequired:mensajeRequerido, regex:regexEntero, messageRegex:mesajeDatoEntero},
        telefono_celular:{required:true, messageRequired:mensajeRequerido, regex:regexEntero, messageRegex:mesajeDatoEntero, minLength:10, messageMinLength:mensajeDatoMin},
        telefono_celular_confirm:{required:true, messageRequired:mensajeRequerido, regex:regexEntero, messageRegex:mesajeDatoEntero, minLength:10, messageMinLength:mensajeDatoMin},
        telefono_fijo:{required:true, messageRequired:mensajeRequerido, regex:regexEntero, messageRegex:mesajeDatoEntero, minLength:10, messageMinLength:mensajeDatoMin},
        telfono_fijo_confirm:{required:true, messageRequired:mensajeRequerido, regex:regexEntero, messageRegex:mesajeDatoEntero, minLength:10, messageMinLength:mensajeDatoMin},
        contacto_uno_nombre_completo:{required:true, messageRequired:mensajeRequerido, regex:regexTexto, messageRegex:mensajeDatoCadena, upperCase:true},
        contacto_uno_parentesco:{required:true, messageRequired:mensajeRequerido, regex:regexTexto, messageRegex:mensajeDatoCadena},
        contacto_uno_telefono:{required:true, messageRequired:mensajeRequerido, regex:regexEntero, messageRegex:mesajeDatoEntero},
        contacto_uno_correo:{required:true, messageRequired:mensajeRequerido, regex:regexCorreo, messageRegex:mensajeCorreo},
        contacto_dos_nombre_completo:{required:true, messageRequired:mensajeRequerido, regex:regexTexto, messageRegex:mensajeDatoCadena, upperCase:true},
        contacto_dos_parentesco:{required:true, messageRequired:mensajeRequerido, regex:regexTexto, messageRegex:mensajeDatoCadena},
        contacto_dos_telefono:{required:true, messageRequired:mensajeRequerido, regex:regexEntero, messageRegex:mesajeDatoEntero},
        contacto_dos_correo:{required:true, messageRequired:mensajeRequerido, regex:regexCorreo, messageRegex:mensajeCorreo},
        id_catalogo_estado_civil:{required:true, messageRequired:mensajeRequerido},
        id_catalogo_numero_ninios:{required:true, messageRequired:mensajeRequerido, regex:regexEntero, messageRegex:mesajeDatoEntero},
        id_catologo_numero_personas_adultas:{required:true, messageRequired:mensajeRequerido, regex:regexEntero, messageRegex:mesajeDatoEntero},
        id_catologo_discapacidad:{required:true, messageRequired:mensajeRequerido},
        id_catologo_persona_hogar_discapacidad:{required:true, messageRequired:mensajeRequerido},
        id_catalogo_trabajo_con_ingreso:{required:true, messageRequired:mensajeRequerido},
        id_catalogo_edad_primer_trabajo:{required:true, messageRequired:mensajeRequerido, regex:regexEntero, messageRegex:mesajeDatoEntero},
        id_catalogo_anios_trabajo:{required:true, messageRequired:mensajeRequerido, regex:regexEntero, messageRegex:mesajeDatoEntero},
        id_catalogo_meses_trabajo:{required:true, messageRequired:mensajeRequerido, regex:regexEntero, messageRegex:mesajeDatoEntero},
        id_catalogo_situcion_laboral_actual:{required:true, messageRequired:mensajeRequerido},
        id_catalogo_cuenta_con_imss:{required:true, messageRequired:mensajeRequerido},
        id_catalogo_nivel_educativo_mas_alto:{required:true, messageRequired:mensajeRequerido},
        id_catalogo_estatus_educacion:{required:true, messageRequired:mensajeRequerido},
        id_catalogo_nivel_estudia_actualmente:{required:true, messageRequired:mensajeRequerido},
        id_catalogo_grado_que_asiste:{required:true, messageRequired:mensajeRequerido, regex:regexEntero, messageRegex:mesajeDatoEntero},
        id_catalogo_escuela:{required:true, messageRequired:mensajeRequerido},
        id_catalogo_carrera:{required:true, messageRequired:mensajeRequerido},
        fecha_estimada_graduacion:{required:true, messageRequired:mensajeRequerido},
        id_catologo_promedio_general:{required:true, messageRequired:mensajeRequerido}
    }

    useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND_ENDPOINT+'/api/v1/catalogos_formulario_read/1',
        { headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}})
        .then((response) => {
            const respAux = [{id:1, etiqueta:'Sí'},{id:2, etiqueta:'No'}];
            setListaCatalogos({...response.data,respAux});
        })
        .catch((error) => {
            console.error(error);
        });
    }, []);

    const handleChangue = (value,nombreParametro) => {
        const valueAux = metaDataForm[nombreParametro].upperCase ? value.toUpperCase():value;

        validaDato(value,nombreParametro);

        let formDataAux = validaLimpiezaDatos(nombreParametro);

        setFormData({...formDataAux, [nombreParametro]:valueAux});

    }

    const validaDato = (value,nombreParametro) => {

        //Se valida si es requerido
        if(metaDataForm[nombreParametro].required && !value) {
            setMensajesForm({...mensajesForm, [nombreParametro]:metaDataForm[nombreParametro].messageRequired});
            setFormInvalido(true);
            return false;
        }

        //Se valida si tiene una expresion regular
        if(metaDataForm[nombreParametro].regex &&!metaDataForm[nombreParametro].regex.test(value)&&value) {
            setMensajesForm({...mensajesForm, [nombreParametro]:metaDataForm[nombreParametro].messageRegex});
            setFormInvalido(true);
            return false;
        }

        //Se valida si tiene un minimo de caracteres
        if(metaDataForm[nombreParametro].minLength &&metaDataForm[nombreParametro].minLength > value.length) {
            setMensajesForm({...mensajesForm, [nombreParametro]:metaDataForm[nombreParametro].messageMinLength});
            setFormInvalido(true);
            return false;
        }

        //Se limpia mensaje
        setMensajesForm({...mensajesForm, [nombreParametro]:""});
        setFormInvalido(false);
        return true;
    };

    const validaLimpiezaDatos = (nombreParametro) => {
        let formAux = formData;

        if(metaDataForm[nombreParametro].limpiaA) {
            metaDataForm[nombreParametro].limpiaA.forEach(value => {
                formAux = {...formAux,[value]:null}
            });
        }

        return formAux;
    }

    const validaClavePreRegistro = (event) => {

        const value = event.target.value
        //setPreClaveCorrecta(false);
        setFormData({...formData,
            clave_pre_registro:null,
        });

        if(!value||!validaDato(value,"clave_pre_registro")) {
            return false; 
        }

        const formDataAux = new FormData();
        formDataAux.append("clave_pre_registro",value);

        axios.post(process.env.REACT_APP_BACKEND_ENDPOINT+'/api/v1/form_check_clave_pre_registro',formDataAux,
        { headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}})
        .then(res => {
            setFormData({...formData,
                clave_pre_registro:value,
            })
            //setPreClaveCorrecta(true);
        })
        .catch(error => {
            setFormData({...formData,clave_pre_registro:value});
            setMensajesForm({...mensajesForm,clave_pre_registro:"La clave de pre registro no es valida"});
            console.error(error);
        });
    };

    const validaCurp = (event) => {
        const value = event.target.value;
        
        if(!validaDato(value,"curp")) {
           return false; 
        }

        const formDataAux = new FormData();
        formDataAux.append("curp",value);

        axios.post(process.env.REACT_APP_BACKEND_ENDPOINT+'/api/v1/form_check_no_duplicated_curp',formDataAux,
        { headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}})
        .then(res => {
            if(res.data.respuesta !== 'ok') {
                setMensajesForm({...mensajesForm,curp:"La CURP ya se encuentra registrada para este periodo"});
            } 
        })
        .catch(error => {
            setMensajesForm({...mensajesForm,curp:"No se pudo validar la CURP"});
            console.error(error)
        });
    };

    const validaCelulares = (event,otroTel) => {
        const value = event.target.value;
        const mensaje = value !== otroTel ? "Los telefonos no coinciden":"";
        
        let mensajesAux = {...mensajesForm,telefono_celular:'',telefono_celular_confirm:'',telefono_fijo:'',telfono_fijo_confirm:''}
        setMensajesForm({...mensajesAux,[event.target.id]:mensaje});
    };

    const validaForm = () => {
        for(const elmentForm in formData) {
            if(!validaDato(formData[elmentForm],elmentForm)) {
                return false;
            }
        }

        setModalConfirmacion(true);
    };

    const enviarForm = () => {
        //Si todo esta correcto se raliza el envio de la información
        const formDataAux = new FormData();
        for(const elmentForm in formData) {
            formDataAux.append(elmentForm,formData[elmentForm]);
        }

        //Para el caso de la fecha se reemplaza para cambiar el formato
        if(formData.fecha_estimada_graduacion) {
            formDataAux.delete("fecha_estimada_graduacion");
            const fechaFormateada = moment(formData.fecha_estimada_graduacion, "yyyy-MM-DD").format("yyyy-MM-DD");
            formDataAux.append("fecha_estimada_graduacion",fechaFormateada);
        } else {
            formDataAux.delete("fecha_estimada_graduacion");
        }

        if(formData.fecha_nacimiento) {
            formDataAux.delete("fecha_nacimiento");
            const fechaFormateada = moment(formData.fecha_nacimiento, "yyyy-MM-DD").format("yyyy-MM-DD");
            formDataAux.append("fecha_nacimiento",fechaFormateada);
        } else {
            formDataAux.delete("fecha_nacimiento");
        }
        
        axios.post(process.env.REACT_APP_BACKEND_ENDPOINT+'/api/v1/form/form_datos_usuario_write_1',formDataAux,
        {
            headers: {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}
        })
        .then(res => {
            setModalConfirmacion(false);
            window.scrollTo(0, 0);
            updateEstatusModulo(3);
            sessionStorage.setItem('estatusModulo','3');
        })
        .catch(error => {
            setModalConfirmacion(false);
            console.error(error);
        });
    }

    return(
        <div className={styles.container +' container-margin'}>
            <Grid container rowSpacing={4} columnSpacing={8}>
            <Grid item xs={12} md={12}>
                <p>
                    <b>
                        ¡Hola! Nos encanta saber que te interesa ser parte de los participantes del programa Súbete a la Nube 
                        con Google.
                    </b>
                </p>
                <p>
                    A continuación, te pediremos que contestes a unas preguntas para poder verificar que cumples
                    con los requisitos para postularte al programa y para poder caracterizar a los postulantes al mismo.
                </p>
                </Grid>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>¿Tu institución te brindó clave de preregistro? </span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <FormControl error={mensajesForm.tienes_clave_registro} fullWidth>
                            <Select id="tienes_clave_registro"
                                value={formData.tienes_clave_registro}
                                error={mensajesForm.tienes_clave_registro}
                                onChange={(event)=>{handleChangue(event.target.value,'tienes_clave_registro');}}
                            >
                                { 
                                    listaCatalogos.respAux.map(data => (
                                        <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                    ))
                                }
                            </Select> 
                            { mensajesForm.tienes_clave_registro&&
                                <p className={styles['nota_mensaje_error']}>{mensajesForm.tienes_clave_registro}</p>
                            }
                    </FormControl>
                    <span style={{fontWeight:"lighter"}}>
                        Nota: Si NO TIENES clave de Preregistro, no te preocupes, contesta "NO" y avanza con el formulario.
                    </span>
                </Grid>
                {formData.tienes_clave_registro === 1 &&
                <>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>Clave de Pre-Registro</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <FormControl error={mensajesForm.clave_pre_registro} fullWidth>
                        <TextField 
                            margin="dense"
                            id="clave_pre_registro"
                            value={formData.clave_pre_registro}
                            onChange={(event)=>handleChangue(event.target.value,'clave_pre_registro',true)}
                            error={mensajesForm.clave_pre_registro}
                            helperText={mensajesForm.clave_pre_registro}
                            type="text"
                            fullWidth
                            inputProps={{ 
                                maxLength: 22,
                                minLength: 22,
                                style: { textTransform: "uppercase" },
                                onBlur:validaClavePreRegistro
                            }}
                        />
                    </FormControl>
                </Grid>
                </>
                }
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>Primer nombre oficial:</span>
                </Grid>
                <Grid item xs={12} md={7}>
                        <TextField 
                        margin="dense"
                        id="primer_nombre_oficial"
                        value={formData.primer_nombre_oficial}
                        onChange={(event)=>{handleChangue(event.target.value,'primer_nombre_oficial');}}
                        onKeyDown={(event) => { if (!regexOnlyLetters.test(event.key)) { event.preventDefault();  }  }}
                        error={mensajesForm.primer_nombre_oficial}
                        helperText={mensajesForm.primer_nombre_oficial}
                        type="text"
                        fullWidth
                        inputProps={{ 
                            maxLength: 40,
                            style: { textTransform: "uppercase" }
                        }}
                        />
                </Grid>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>Segundo nombre oficial:</span>
                </Grid>
                <Grid item xs={12} md={7}>
                        <TextField 
                        margin="dense"
                        id="segundo_nombre_oficial"
                        value={formData.segundo_nombre_oficial}
                        onChange={(event)=>{handleChangue(event.target.value,'segundo_nombre_oficial');}}
                        onKeyDown={(event) => { if (!regexOnlyLetters.test(event.key)) { event.preventDefault();  }  }}
                        error={mensajesForm.segundo_nombre_oficial}
                        helperText={mensajesForm.segundo_nombre_oficial}
                        type="text"
                        fullWidth
                        inputProps={{ 
                            maxLength: 40,
                            style: { textTransform: "uppercase" }
                        }}
                        />
                </Grid>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>Primer apellido oficial:</span>
                </Grid>
                <Grid item xs={12} md={7}>
                        <TextField 
                        margin="dense"
                        id="primer_apellido_oficial"
                        value={formData.primer_apellido_oficial}
                        onChange={(event)=>{handleChangue(event.target.value,'primer_apellido_oficial');}}
                        onKeyDown={(event) => { if (!regexOnlyLetters.test(event.key)) { event.preventDefault();  }  }}
                        error={mensajesForm.primer_apellido_oficial}
                        helperText={mensajesForm.primer_apellido_oficial}
                        type="text"
                        fullWidth
                        inputProps={{ 
                            maxLength: 40,
                            style: { textTransform: "uppercase" }
                        }}
                        />
                </Grid>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>Segundo apellido oficial:</span>
                </Grid>
                <Grid item xs={12} md={7}>
                        <TextField 
                        margin="dense"
                        id="segundo_apellido_oficial"
                        value={formData.segundo_apellido_oficial}
                        onChange={(event)=>{handleChangue(event.target.value,'segundo_apellido_oficial');}}
                        onKeyDown={(event) => { if (!regexOnlyLetters.test(event.key)) { event.preventDefault();  }  }}
                        error={mensajesForm.segundo_apellido_oficial}
                        helperText={mensajesForm.segundo_apellido_oficial}
                        type="text"
                        fullWidth
                        inputProps={{ 
                            maxLength: 40,
                            style: { textTransform: "uppercase" }
                        }}
                        />
                </Grid>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>CURP:</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <TextField 
                    margin="dense"
                    id="curp"
                    value={formData.curp}
                    onChange={(event)=>{handleChangue(event.target.value,'curp');}}
                    error={mensajesForm.curp}
                    helperText={mensajesForm.curp}
                    type="text"
                    fullWidth
                    inputProps={{ 
                        maxLength: 18,
                        minLength: 18,
                        style: { textTransform: "uppercase" },
                        onBlur:validaCurp
                    }}
                    />
                    <div className={styles['text-right']}>
                        <span><i>Si no sabes cual es tu CURP, puedes consultarlo </i>
                            <a href="https://www.gob.mx/curp" target="_blank" rel="noreferrer">aquí</a> </span>
                    </div>
                </Grid>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>
                        Nombre y Apellido para el diploma
                        de finalización: <br />
                        <i>(En caso de ser selecionado)</i>
                    </span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <TextField 
                    margin="dense"
                    id="nombre_apellido_cert"
                    value={formData.nombre_apellido_cert}
                    onChange={(event)=>{handleChangue(event.target.value,'nombre_apellido_cert');}}
                    onKeyDown={(event) => { if (!regexOnlyLetters.test(event.key)) { event.preventDefault();  }  }}
                    error={mensajesForm.nombre_apellido_cert}
                    helperText={mensajesForm.nombre_apellido_cert}
                    type="text"
                    fullWidth
                    inputProps={{ 
                        maxLength: 30,
                        style: { textTransform: "uppercase" }
                    }}
                    />
                    <div className={styles['text-right']}>
                        <span><i>Tienes como límite 30 carácteres para llenar</i></span>
                    </div>
                </Grid>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>Género:</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <FormControl error={mensajesForm.id_catalogo_genero} fullWidth>
                            <Select id="id_catalogo_genero"
                                value={formData.id_catalogo_genero}
                                error={mensajesForm.id_catalogo_genero}
                                onChange={(event)=>{handleChangue(event.target.value,'id_catalogo_genero');}}
                            >
                                { 
                                    listaCatalogos.generos.map(data => (
                                        <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                    ))
                                }
                            </Select> 
                            { mensajesForm.id_catalogo_genero&&
                                <p className={styles['nota_mensaje_error']}>{mensajesForm.id_catalogo_genero}</p>
                            }
                    </FormControl>
                </Grid>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>Fecha de nacimiento:</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <TextField 
                        margin="dense"
                        id="fecha_nacimiento"
                        value={formData.fecha_nacimiento}
                        onChange={(event)=>{handleChangue(event.target.value,'fecha_nacimiento');}}
                        error={mensajesForm.fecha_nacimiento}
                        helperText={mensajesForm.fecha_nacimiento}
                        type="date"
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>Nacionalidad:</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <FormControl error={mensajesForm.id_catalogo_nacionalidad} fullWidth>
                            <Select id="id_catalogo_nacionalidad"
                                value={formData.id_catalogo_nacionalidad}
                                error={mensajesForm.id_catalogo_nacionalidad}
                                onChange={(event)=>{handleChangue(event.target.value,'id_catalogo_nacionalidad');}}
                            >
                                { 
                                    listaCatalogos.nacionalidad.map(data => (
                                        <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                    ))
                                }
                            </Select>
                            { mensajesForm.id_catalogo_nacionalidad&&
                                <p className={styles['nota_mensaje_error']}>{mensajesForm.id_catalogo_nacionalidad}</p>
                            }
                    </FormControl>
                </Grid>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>Estado de Residencia:</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <FormControl error={mensajesForm.id_catalogo_estado} fullWidth>
                        <Select id="id_catalogo_estado"
                            value={formData.id_catalogo_estado}
                            error={mensajesForm.id_catalogo_estado}
                            onChange={(event)=>{handleChangue(event.target.value,'id_catalogo_estado');}}
                        >
                            { 
                                listaCatalogos.estados.map(data => (
                                    <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                ))
                            }
                        </Select>
                        { mensajesForm.id_catalogo_estado&&
                            <p className={styles['nota_mensaje_error']}>{mensajesForm.id_catalogo_estado}</p>
                        }
                    </FormControl>
                </Grid>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>Código Postal:</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <TextField 
                    margin="dense"
                    id="codigo_postal"
                    value={formData.codigo_postal}
                    onChange={(event)=>{handleChangue(event.target.value,'codigo_postal');}}
                    error={mensajesForm.codigo_postal}
                    helperText={mensajesForm.codigo_postal}
                    type="numeric"
                    fullWidth
                    inputProps={{
                        maxLength: 5
                    }}
                    />
                </Grid>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>Teléfono celular:</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <TextField 
                    margin="dense"
                    id="telefono_celular"
                    value={formData.telefono_celular}
                    onChange={(event)=>{handleChangue(event.target.value,'telefono_celular');}}
                    error={mensajesForm.telefono_celular}
                    helperText={mensajesForm.telefono_celular}
                    type="numeric"
                    fullWidth
                    inputProps={{
                        minLength: 10,
                        maxLength:10,
                        onBlur:(event)=>validaCelulares(event,formData.telefono_celular_confirm)
                    }}
                    />
                </Grid>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>Confirmar teléfono celular:</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <TextField 
                    margin="dense"
                    id="telefono_celular_confirm"
                    value={formData.telefono_celular_confirm}
                    onChange={(event)=>{handleChangue(event.target.value,'telefono_celular_confirm');}}
                    error={mensajesForm.telefono_celular_confirm}
                    helperText={mensajesForm.telefono_celular_confirm}
                    type="numeric"
                    fullWidth
                    inputProps={{ 
                        minLength: 10,
                        maxLength:10,
                        onBlur:(event)=>validaCelulares(event,formData.telefono_celular)
                    }}
                    />
                </Grid>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>Teléfono fijo:</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <TextField 
                    margin="dense"
                    id="telefono_fijo"
                    value={formData.telefono_fijo}
                    onChange={(event)=>{handleChangue(event.target.value,'telefono_fijo');}}
                    error={mensajesForm.telefono_fijo}
                    helperText={mensajesForm.telefono_fijo}
                    type="numeric"
                    fullWidth
                    inputProps={{ 
                        minLength: 10,
                        maxLength:10,
                        onBlur:(event)=>validaCelulares(event,formData.telfono_fijo_confirm)
                    }}
                    />
                </Grid>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>Confirmar teléfono fijo:</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <TextField 
                    margin="dense"
                    id="telfono_fijo_confirm"
                    value={formData.telfono_fijo_confirm}
                    onChange={(event)=>{handleChangue(event.target.value,'telfono_fijo_confirm');}}
                    error={mensajesForm.telfono_fijo_confirm}
                    helperText={mensajesForm.telfono_fijo_confirm}
                    type="numeric"
                    fullWidth
                    inputProps={{ 
                        minLength: 10,
                        maxLength:10,
                        onBlur:(event)=>validaCelulares(event,formData.telefono_fijo)
                    }}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <p><i>En caso de no ubicarlo, nos gustaría contar con el contacto de 2 personas cercanas (familiares o amigos)</i></p>
                </Grid>
                <Grid className={styles['label']} item xs={12} md={5}>
                    <span className={styles['label-right']}>Contacto 1: nombre completo</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <TextField 
                        margin="dense"
                        id="contacto_uno_nombre_completo"
                        value={formData.contacto_uno_nombre_completo}
                        onChange={(event)=>{handleChangue(event.target.value,'contacto_uno_nombre_completo');}}
                        onKeyDown={(event) => { if (!regexOnlyLetters.test(event.key)) { event.preventDefault();  }  }}
                        error={mensajesForm.contacto_uno_nombre_completo}
                        helperText={mensajesForm.contacto_uno_nombre_completo}
                        type="text"
                        fullWidth
                        inputProps={{ 
                            maxLength: 40,
                            style: { textTransform: "uppercase" }
                        }}
                        />
                </Grid>
                <Grid className={styles['label']} item xs={12} md={5}>
                    <span className={styles['label-right']}>Contacto 1: parentesco/relación</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <TextField 
                    margin="dense"
                    id="contacto_uno_parentesco"
                    value={formData.contacto_uno_parentesco}
                    onChange={(event)=>{handleChangue(event.target.value,'contacto_uno_parentesco');}}
                    onKeyDown={(event) => { if (!regexOnlyLetters.test(event.key)) { event.preventDefault();  }  }}
                    error={mensajesForm.contacto_uno_parentesco}
                    helperText={mensajesForm.contacto_uno_parentesco}
                    type="text"
                    fullWidth
                    inputProps={{ 
                        maxLength: 40
                    }}
                    />
                </Grid>
                <Grid className={styles['label']} item xs={12} md={5}>
                    <span className={styles['label-right']}>Contacto 1: número de teléfono</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <TextField 
                    margin="dense"
                    id="contacto_uno_telefono"
                    value={formData.contacto_uno_telefono}
                    onChange={(event)=>{handleChangue(event.target.value,'contacto_uno_telefono');}}
                    error={mensajesForm.contacto_uno_telefono}
                    helperText={mensajesForm.contacto_uno_telefono}
                    type="numeric"
                    fullWidth
                    inputProps={{ 
                        maxLength: 10
                    }}
                    />
                </Grid>
                <Grid className={styles['label']} item xs={12} md={5}>
                    <span className={styles['label-right']}>Contacto 1: correo electrónico</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <TextField 
                    margin="dense"
                    id="contacto_uno_correo"
                    value={formData.contacto_uno_correo}
                    onChange={(event)=>{handleChangue(event.target.value,'contacto_uno_correo');}}
                    error={mensajesForm.contacto_uno_correo}
                    helperText={mensajesForm.contacto_uno_correo}
                    type="text"
                    fullWidth
                    inputProps={{ 
                        maxLength: 40
                    }}
                    />
                </Grid>
                <Grid className={styles['label']} item xs={12} md={5}>
                    <span className={styles['label-right']}>Contacto 2: nombre completo</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <TextField 
                    margin="dense"
                    id="contacto_dos_nombre_completo"
                    value={formData.contacto_dos_nombre_completo}
                    onChange={(event)=>{handleChangue(event.target.value,'contacto_dos_nombre_completo');}}
                    onKeyDown={(event) => { if (!regexOnlyLetters.test(event.key)) { event.preventDefault();  }  }}
                    error={mensajesForm.contacto_dos_nombre_completo}
                    helperText={mensajesForm.contacto_dos_nombre_completo}
                    type="text"
                    fullWidth
                    inputProps={{ 
                        maxLength: 40,
                        style: { textTransform: "uppercase" }
                    }}
                    />
                </Grid>
                <Grid className={styles['label']} item xs={12} md={5}>
                    <span className={styles['label-right']}>Contacto 2: parentesco/relación</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <TextField 
                    margin="dense"
                    id="contacto_dos_parentesco"
                    value={formData.contacto_dos_parentesco}
                    onChange={(event)=>{handleChangue(event.target.value,'contacto_dos_parentesco');}}
                    onKeyDown={(event) => { if (!regexOnlyLetters.test(event.key)) { event.preventDefault();  }  }}
                    error={mensajesForm.contacto_dos_parentesco}
                    helperText={mensajesForm.contacto_dos_parentesco}
                    type="text"
                    fullWidth
                    inputProps={{ 
                        maxLength: 40
                    }}
                    />
                </Grid>
                <Grid className={styles['label']} item xs={12} md={5}>
                    <span className={styles['label-right']}>Contacto 2: número de teléfono</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <TextField 
                    margin="dense"
                    id="contacto_dos_telefono"
                    value={formData.contacto_dos_telefono}
                    onChange={(event)=>{handleChangue(event.target.value,'contacto_dos_telefono');}}
                    error={mensajesForm.contacto_dos_telefono}
                    helperText={mensajesForm.contacto_dos_telefono}
                    type="numeric"
                    fullWidth
                    inputProps={{ 
                        maxLength: 10
                    }}
                    />
                </Grid>
                <Grid className={styles['label']} item xs={12} md={5}>
                    <span className={styles['label-right']}>Contacto 2: correo electrónico</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <TextField 
                    margin="dense"
                    id="contacto_dos_correo"
                    value={formData.contacto_dos_correo}
                    onChange={(event)=>{handleChangue(event.target.value,'contacto_dos_correo');}}
                    error={mensajesForm.contacto_dos_correo}
                    helperText={mensajesForm.contacto_dos_correo}
                    type="text"
                    fullWidth
                    inputProps={{ 
                        maxLength: 40
                    }}
                    />
                </Grid>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>¿Cuál es tu estado civil?</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <FormControl error={mensajesForm.id_catalogo_estado_civil} fullWidth>
                        <Select id="id_catalogo_estado_civil"
                            value={formData.id_catalogo_estado_civil}
                            error={mensajesForm.id_catalogo_estado_civil}
                            onChange={(event)=>{handleChangue(event.target.value,'id_catalogo_estado_civil');}}
                        >
                            { 
                                listaCatalogos.estado_civil.map(data => (
                                    <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                ))
                            }
                        </Select>
                        { mensajesForm.id_catalogo_estado_civil&&
                            <p className={styles['nota_mensaje_error']}>{mensajesForm.id_catalogo_estado_civil}</p>
                        }
                    </FormControl>
                </Grid>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>¿Cuántos niños y niñas de 0 a 5 años
                        viven contigo?</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <FormControl error={mensajesForm.id_catalogo_numero_ninios} fullWidth>
                        <Select id="id_catalogo_numero_ninios"
                            value={formData.id_catalogo_numero_ninios}
                            error={mensajesForm.id_catalogo_numero_ninios}
                            onChange={(event)=>{handleChangue(event.target.value,'id_catalogo_numero_ninios');}}
                        >
                            { 
                                listaCatalogos.numero_ninios.map(data => (
                                    <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                ))
                            }
                        </Select>
                        { mensajesForm.id_catalogo_numero_ninios&&
                            <p className={styles['nota_mensaje_error']}>{mensajesForm.id_catalogo_numero_ninios}</p>
                        }
                    </FormControl>
                </Grid>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>¿Cuántos adultos mayores de 64 años
                        viven contigo?</span>
                </Grid>
                <Grid item xs={12} md={7}>
                <FormControl error={mensajesForm.id_catologo_numero_personas_adultas} fullWidth>
                        <Select id="id_catologo_numero_personas_adultas"
                            value={formData.id_catologo_numero_personas_adultas}
                            error={mensajesForm.id_catologo_numero_personas_adultas}
                            onChange={(event)=>{handleChangue(event.target.value,'id_catologo_numero_personas_adultas');}}
                        >
                            { 
                                listaCatalogos.numero_personas_adultas.map(data => (
                                    <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                ))
                            }
                        </Select>
                        { mensajesForm.id_catologo_numero_personas_adultas&&
                            <p className={styles['nota_mensaje_error']}>{mensajesForm.id_catologo_numero_personas_adultas}</p>
                        }
                    </FormControl>
                  </Grid>
                <Grid className={styles.label} item xs={12} md={7}>
                    <span>¿Tienes alguna discapacidad física o mental que te impida
                        o restrinja realizar tus actividades diarias con
                        autonomía e independencia?</span>
                </Grid>
                <Grid item xs={12} md={5}>
                    <FormControl error={mensajesForm.id_catologo_discapacidad} fullWidth>
                        <Select id="id_catologo_discapacidad"
                            value={formData.id_catologo_discapacidad}
                            error={mensajesForm.id_catologo_discapacidad}
                            onChange={(event)=>{handleChangue(event.target.value,'id_catologo_discapacidad');}}
                        >
                            { 
                                listaCatalogos.discapacidad.map(data => (
                                    <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                ))
                            }
                        </Select> 
                        { mensajesForm.id_catologo_discapacidad&&
                            <p className={styles['nota_mensaje_error']}>{mensajesForm.id_catologo_discapacidad}</p>
                        }
                    </FormControl>
                </Grid>
                <Grid className={styles.label} item xs={12} md={7}>
                    <span>¿Alguna persona de tu hogar tiene alguna discapacidad
                        física o mental que le impida o restrinja realizar
                        sus actividades diarias con autonomía?</span>
                </Grid>
                <Grid item xs={12} md={5}>
                    <FormControl error={mensajesForm.id_catologo_persona_hogar_discapacidad} fullWidth>
                        <Select id="id_catologo_persona_hogar_discapacidad"
                            value={formData.id_catologo_persona_hogar_discapacidad}
                            error={mensajesForm.id_catologo_persona_hogar_discapacidad}
                            onChange={(event)=>{handleChangue(event.target.value,'id_catologo_persona_hogar_discapacidad');}}
                        >
                            { 
                                listaCatalogos.persona_hogar_discapacidad.map(data => (
                                    <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                ))
                            }
                        </Select> 
                        { mensajesForm.id_catologo_persona_hogar_discapacidad&&
                            <p className={styles['nota_mensaje_error']}>{mensajesForm.id_catologo_persona_hogar_discapacidad}</p>
                        }
                    </FormControl>
                </Grid>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>¿Has trabajado alguna vez en alguna
                        actividad que te proporcionó ingresos?</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <FormControl error={mensajesForm.id_catalogo_trabajo_con_ingreso} fullWidth>
                        <Select id="id_catalogo_trabajo_con_ingreso"
                            value={formData.id_catalogo_trabajo_con_ingreso}
                            error={mensajesForm.id_catalogo_trabajo_con_ingreso}
                            onChange={(event)=>{handleChangue(event.target.value,'id_catalogo_trabajo_con_ingreso');}}
                        >
                            { 
                                listaCatalogos.trabajo_con_ingreso.map(data => (
                                    <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                ))
                            }
                        </Select> 
                        { mensajesForm.id_catalogo_trabajo_con_ingreso&&
                            <p className={styles['nota_mensaje_error']}>{mensajesForm.id_catalogo_trabajo_con_ingreso}</p>
                        }
                    </FormControl>
                </Grid>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>¿A qué edad tuviste tu primer empleo?</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    
                    <FormControl error={mensajesForm.id_catalogo_edad_primer_trabajo} fullWidth>
                        <Select id="id_catalogo_edad_primer_trabajo"
                            value={formData.id_catalogo_edad_primer_trabajo}
                            error={mensajesForm.id_catalogo_edad_primer_trabajo}
                            onChange={(event)=>{handleChangue(event.target.value,'id_catalogo_edad_primer_trabajo');}}
                        >
                            { 
                                listaCatalogos.edad_primer_trabajo.map(data => (
                                    <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                ))
                            }
                        </Select>
                        { mensajesForm.id_catalogo_edad_primer_trabajo&&
                            <p className={styles['nota_mensaje_error']}>{mensajesForm.id_catalogo_edad_primer_trabajo}</p>
                        }
                    </FormControl>

                 </Grid>
                <Grid className={styles.label} item xs={12} md={6}>
                    <span>Durante toda tu vida, ¿Cuántos años has
                        trabajado en total? Por ejemplo, 1 año y
                        3 meses; 0 años, 2 meses?</span>
                </Grid>
                <Grid item xs={12} md={3}>

                <FormControl error={mensajesForm.id_catalogo_anios_trabajo} fullWidth>
                        <Select id="id_catalogo_anios_trabajo"
                            value={formData.id_catalogo_anios_trabajo}
                            error={mensajesForm.id_catalogo_anios_trabajo}
                            onChange={(event)=>{handleChangue(event.target.value,'id_catalogo_anios_trabajo');}}
                        >
                            { 
                                listaCatalogos.anios_trabajo.map(data => (
                                    <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                ))
                            }
                        </Select>
                        { mensajesForm.id_catalogo_anios_trabajo&&
                            <p className={styles['nota_mensaje_error']}>{mensajesForm.id_catalogo_anios_trabajo}</p>
                        }
                    </FormControl>
                   
                </Grid>
                <Grid item xs={12} md={3}>

                    <FormControl error={mensajesForm.id_catalogo_meses_trabajo} fullWidth>
                        <Select id="id_catalogo_meses_trabajo"
                            value={formData.id_catalogo_meses_trabajo}
                            error={mensajesForm.id_catalogo_meses_trabajo}
                            onChange={(event)=>{handleChangue(event.target.value,'id_catalogo_meses_trabajo');}}
                        >
                            { 
                                listaCatalogos.meses_trabajo.map(data => (
                                    <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                ))
                            }
                        </Select>
                        { mensajesForm.id_catalogo_meses_trabajo&&
                            <p className={styles['nota_mensaje_error']}>{mensajesForm.id_catalogo_meses_trabajo}</p>
                        }
                    </FormControl>

                </Grid>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>¿Cuál de los siguientes estados refleja 
                        mejor tu situación laboral actual?</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <FormControl error={mensajesForm.id_catalogo_situcion_laboral_actual} fullWidth>
                        <Select id="id_catalogo_situcion_laboral_actual"
                            value={formData.id_catalogo_situcion_laboral_actual}
                            error={mensajesForm.id_catalogo_situcion_laboral_actual}
                            onChange={(event)=>{handleChangue(event.target.value,'id_catalogo_situcion_laboral_actual');}}
                        >
                             { 
                                listaCatalogos.situacion_laboral.map(data => (
                                    <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                ))
                            }
                        </Select> 
                        { mensajesForm.id_catalogo_situcion_laboral_actual&&
                            <p className={styles['nota_mensaje_error']}>{mensajesForm.id_catalogo_situcion_laboral_actual}</p>
                        }
                    </FormControl>
                </Grid>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>En tu trabajo actual ¿Cuentas con Seguro Social (IMSS)?</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <FormControl error={mensajesForm.id_catalogo_cuenta_con_imss} fullWidth>
                        <Select id="id_catalogo_cuenta_con_imss"
                            value={formData.id_catalogo_cuenta_con_imss}
                            error={mensajesForm.id_catalogo_cuenta_con_imss}
                            onChange={(event)=>{handleChangue(event.target.value,'id_catalogo_cuenta_con_imss');}}
                        >
                            { 
                                listaCatalogos.cuenta_con_imss.map(data => (
                                    <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                ))
                            }
                        </Select> 
                        { mensajesForm.id_catalogo_cuenta_con_imss&&
                            <p className={styles['nota_mensaje_error']}>{mensajesForm.id_catalogo_cuenta_con_imss}</p>
                        }
                    </FormControl>
                </Grid>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>¿Cuál es el nivel educativo más alto
                        que has alcanzado?</span>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormControl error={mensajesForm.id_catalogo_nivel_educativo_mas_alto} fullWidth>
                        <Select id="id_catalogo_nivel_educativo_mas_alto"
                            value={formData.id_catalogo_nivel_educativo_mas_alto}
                            error={mensajesForm.id_catalogo_nivel_educativo_mas_alto}
                            onChange={(event)=>{handleChangue(event.target.value,'id_catalogo_nivel_educativo_mas_alto');}}
                        >
                            { 
                                listaCatalogos.nivel_educativo.map(data => (
                                    <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                ))
                            }
                        </Select> 
                        { mensajesForm.id_catalogo_nivel_educativo_mas_alto&&
                            <p className={styles['nota_mensaje_error']}>{mensajesForm.id_catalogo_nivel_educativo_mas_alto}</p>
                        }
                    </FormControl>
                </Grid>
                { formData.id_catalogo_nivel_educativo_mas_alto !== 8 &&
                <Grid item xs={12} md={3}>
                    <FormControl error={mensajesForm.id_catalogo_estatus_educacion} fullWidth>
                        <Select id="id_catalogo_estatus_educacion"
                            value={formData.id_catalogo_estatus_educacion}
                            error={mensajesForm.id_catalogo_estatus_educacion}
                            onChange={(event)=>{handleChangue(event.target.value,'id_catalogo_estatus_educacion');}}
                        >
                            { 
                                listaCatalogos.estatus_educacion.map(data => (
                                    <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                ))
                            }
                        </Select> 
                        { mensajesForm.id_catalogo_nivel_educativo_mas_alto&&
                            <p className={styles['nota_mensaje_error']}>{mensajesForm.id_catalogo_nivel_educativo_mas_alto}</p>
                        }
                    </FormControl>
                </Grid>
                }
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>¿Estudias actualmente?</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <FormControl error={mensajesForm.id_catalogo_nivel_estudia_actualmente} fullWidth>
                        <Select id="id_catalogo_nivel_estudia_actualmente"
                            value={formData.id_catalogo_nivel_estudia_actualmente}
                            error={mensajesForm.id_catalogo_nivel_estudia_actualmente}
                            onChange={(event)=>{handleChangue(event.target.value,'id_catalogo_nivel_estudia_actualmente');}}
                        >
                            { 
                                listaCatalogos.nivel_estudia_actualmente.map(data => (
                                    <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                ))
                            }
                        </Select> 
                        { mensajesForm.id_catalogo_nivel_estudia_actualmente&&
                            <p className={styles['nota_mensaje_error']}>{mensajesForm.id_catalogo_nivel_estudia_actualmente}</p>
                        }
                    </FormControl>
                </Grid>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>¿Cuál es el año o grado al que asistes?</span>
                </Grid>
                <Grid item xs={12} md={7}>

                    <FormControl error={mensajesForm.id_catalogo_grado_que_asiste} fullWidth>
                        <Select id="id_catalogo_grado_que_asiste"
                            value={formData.id_catalogo_grado_que_asiste}
                            error={mensajesForm.id_catalogo_grado_que_asiste}
                            onChange={(event)=>{handleChangue(event.target.value,'id_catalogo_grado_que_asiste');}}
                        >
                            { 
                                listaCatalogos.grado_que_asiste.map(data => (
                                    <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                ))
                            }
                        </Select> 
                        { mensajesForm.id_catalogo_grado_que_asiste&&
                            <p className={styles['nota_mensaje_error']}>{mensajesForm.id_catalogo_grado_que_asiste}</p>
                        }
                    </FormControl>

                </Grid>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>Universidad/Bachillerato:</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <FormControl fullWidth >
                        <Autocomplete
                        disablePortal
                        id="auto_complete"
                        options={ listaCatalogos.escuelas.map(data => ( {
                            label:data.etiqueta, id:data.id
                            }
                        ))}
                        onChange={(event,value)=>{handleChangue(value.id,'id_catalogo_escuela')}}
                        getOptionSelected = {()=>{}}
                        renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                        { mensajesForm.id_catalogo_escuela&&
                            <p className={styles['nota_mensaje_error']}>{mensajesForm.id_catalogo_escuela}</p>
                        }
                    </FormControl>
                </Grid>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>¿De qué área es tu carrera?</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <FormControl error={mensajesForm.id_catalogo_carrera} fullWidth>
                        <Select id="id_catalogo_carrera"
                            value={formData.id_catalogo_carrera}
                            error={mensajesForm.id_catalogo_carrera}
                            onChange={(event)=>{handleChangue(event.target.value,'id_catalogo_carrera');}}
                        >
                            { 
                                listaCatalogos.carreras.map(data => (
                                    <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                ))
                            }
                        </Select> 
                        { mensajesForm.id_catalogo_carrera&&
                            <p className={styles['nota_mensaje_error']}>{mensajesForm.id_catalogo_carrera}</p>
                        }
                    </FormControl>
                </Grid>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>Fecha estimada de graduación
                        Universidad/Bachillerato:
                    </span>
                </Grid>
                <Grid className={styles['calendar']} item xs={12} md={7}>
                    <TextField 
                        margin="dense"
                        id="fecha_estimada_graduacion"
                        value={formData.fecha_estimada_graduacion}
                        onChange={(event)=>{handleChangue(event.target.value,'fecha_estimada_graduacion');}}
                        error={mensajesForm.fecha_estimada_graduacion}
                        helperText={mensajesForm.fecha_estimada_graduacion}
                        type="date"
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    {/* <Grid item xs={11} md={11}>
                        <TextField 
                            margin="dense"
                            id="fecha_estimada_graduacion"
                            value={formData.fecha_estimada_graduacion}
                            onChange={(event)=>{handleChangue(event.target.value,'fecha_estimada_graduacion');}}
                            error={mensajesForm.fecha_estimada_graduacion}
                            helperText={mensajesForm.fecha_estimada_graduacion}
                            type="date"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid className={styles['calendar-icon']} item xs={1} md={1}>
                        <DateRangeIcon fontSize="large"/>
                    </Grid> */}
                </Grid>
                <Grid iten xs={12} md={12}>
                    <div className={styles['text-right']}>
                        <span><i>*Fecha en que concluyes tus materias/créditos de la carrera, carrera técnica o bachillerato.</i></span>
                    </div>
                </Grid>
                <Grid className={styles.label} item xs={12} md={5}>
                    <span>Promedio general de tu último nivel de estudios</span>
                </Grid>
                <Grid item xs={12} md={7}>
                    <FormControl error={mensajesForm.id_catologo_promedio_general} fullWidth>
                        <Select id="id_catologo_promedio_general"
                            value={formData.id_catologo_promedio_general}
                            error={mensajesForm.id_catologo_promedio_general}
                            onChange={(event)=>{handleChangue(event.target.value,'id_catologo_promedio_general');}}
                        >
                             { 
                                listaCatalogos.promedios_generales.map(data => (
                                    <MenuItem key={data.id} value={data.id}>{data.etiqueta}</MenuItem>
                                ))
                            }
                        </Select> 
                        { mensajesForm.id_catologo_promedio_general&&
                            <p className={styles['nota_mensaje_error']}>{mensajesForm.id_catologo_promedio_general}</p>
                        }
                    </FormControl>
                </Grid>
                {formInvalido &&
                <Grid item xs={12} md={12}>
                    <Alert severity="warning">Favor de revisar el formulario, existen campos vacios o invalidos.</Alert>
                </Grid>
                }
                <Grid className={styles['text-right']} item xs={12} md={12}>
                    <p className={styles['a_button']} onClick={validaForm}> <i>1/3 <br /> <u><b>Siguiente</b></u></i></p>
                </Grid>
            </Grid>
            <Modal 
                open={modalConfirmacion}
                onClose={()=>{setModalConfirmacion(false);}} 
                id="modal-form"
            >
                <div className='form-modal'>
                    <div className='form-modal_titulo'>
                        <p>¿Seguro que deseas pasar al siguiente formulario?</p>
                    </div>
                    <div className='form-modal_texto'>
                        <p>
                            Recuerda que una vez avanzado al siguiente formulario las respuestas de este formulario se guardarán y no se podrá modificar
                        </p>
                    </div>
                    <div className='form-modal_button'>
                        <Button onClick={enviarForm} backGroundColor={'#011d60'}>Aceptar</Button>
                    </div>
                </div>
            </Modal> 
        </div>
    );
};
