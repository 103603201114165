import React, { useState, useEffect } from 'react';
import SessionContex from '../../provider/SessionContex';

import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route
} from "react-router-dom";

import Home from '../Home/Home'
import HomeIns from '../HomeIns/HomeIns';
import Admin from '../Admin/Admin'
import ClavesPR from '../ClavesPR/ClavesPR'
import AssignBeca from '../AssignBeca/AssignBeca';
import CambioContrasenia from '../../components/CambioContra/CambioContrasenia'
import RegClassOleadas from '../RegClassOleadas/RegClassOleadas';
import HomeFinish from '../HomeFinish/HomeFinish'


export default function Main() {
  const [admin, setAdmin] = useState(false);

  useEffect(() => {
    setAdmin(sessionStorage.getItem('Admin'))
  },[]);

  const updateValueAdmin = (value) => {
    setAdmin(value);
  }

  function PrivateRoute({ children, adminOnly, ...rest }) {
    const isSigned = sessionStorage.getItem('isSigned');
    let adminSession = sessionStorage.getItem('Admin');
    return (
      <Route
        {...rest}
        render={({ location }) =>
        ((isSigned && adminOnly && adminSession) || (isSigned && !adminOnly)) ? 
        (children) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: location }
              }}
            />
          )
        }
      />
    );
  }

  return (
   <SessionContex.Provider value={{admin,updateValueAdmin}}>
     {
      
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/homeIns">
            <HomeIns />
          </Route>
          <Route exact path="/homeFinish">
            <HomeFinish />
          </Route>
          <PrivateRoute path="/admin" adminOnly>
            <Admin />
          </PrivateRoute>
          <PrivateRoute exact path="/asignacion" adminOnly>
            <AssignBeca />
          </PrivateRoute>
          <PrivateRoute exact path="/claves-pr" adminOnly>
            <ClavesPR />
          </PrivateRoute>
          <PrivateRoute exact path="/oleadasClases" adminOnly>
            <RegClassOleadas/>
          </PrivateRoute>
          <Route path="/cambioContra">
            <CambioContrasenia />
          </Route>
        </Switch>
      </Router>
      }
    </SessionContex.Provider>
  );
}