import React from 'react';
import HeaderImg from '../HeaderImg/HeaderImg';
import Testimonials from '../Testimonials/Testimonials';
import AskFreq from '../AskFreq/AskFreq';
import Associations from '../Associations/Associations';
import StepFuntion from '../StepFunction/StepFuntion';
import ProgresFinish from '../ProgresFinish/ProgresFinish';

export default function HomeMain() {
    return(
        <>
            <HeaderImg headerEstatus={3}/>
            <ProgresFinish/>
            <StepFuntion main={true}/>
            <Testimonials/>
            <AskFreq/>
            <Associations/>
        </>
    );
};
